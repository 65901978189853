import {
    Incident,
    Simulation,
    SimulationResults,
} from '../../../../services/dst-api'
import { Chip } from '@mui/material'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import React from 'react'
import { styled } from '@mui/material/styles'
import SimulationQuickInfoTooltip from '../SimulationQuickInfoTooltip/SimulationQuickInfoTooltip'
import IncidentQuickInfoTooltip from '../IncidentQuickInfoTooltip/IncidentQuickInfoTooltip'
import SimulationResultsQuickInfoTooltip from '../SimulationResultsQuickInfoTooltip/SimulationResultsQuickInfoTooltip'

export type QuickInfoChipProps =
    | {
          type: 'SIMULATION'
          simulation: Simulation
      }
    | {
          type: 'INCIDENT'
          incident: Incident
      }
    | {
          type: 'SIMULATION_RESULTS'
          simulation: Simulation
          results: SimulationResults
      }

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.primary.main,
        maxWidth: 500,
        border: '1px solid #dadde9',
        padding: theme.spacing(2),
    },
}))

function QuickInfoChip(props: QuickInfoChipProps) {
    const getTooltipContent = () => {
        switch (props.type) {
            case 'SIMULATION':
                return (
                    <SimulationQuickInfoTooltip simulation={props.simulation} />
                )
            case 'INCIDENT':
                return <IncidentQuickInfoTooltip />
            case 'SIMULATION_RESULTS':
                return <SimulationResultsQuickInfoTooltip />
        }
    }

    function getChipColour() {
        switch (props.type) {
            case 'SIMULATION':
                return 'primary'
            case 'INCIDENT':
                return 'primary'
            case 'SIMULATION_RESULTS':
                return 'secondary'
        }
    }

    function getChipText() {
        switch (props.type) {
            case 'SIMULATION':
                return 'Simulation'
            case 'INCIDENT':
                return 'Inc Chip'
            case 'SIMULATION_RESULTS':
                return 'Res Chip'
        }
    }

    const contentsOnHover = getTooltipContent()
    const chipColor = getChipColour()
    const chipText = getChipText()

    return (
        <StyledTooltip title={contentsOnHover}>
            <Chip size="small" color={chipColor} label={chipText} />
        </StyledTooltip>
    )
}

export default QuickInfoChip

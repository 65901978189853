import { LocationVisit } from '../../../services/dst-api'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import useJourneyOperation from './useJourneyOperation'

export type JourneyOperationMode = 'CANCEL' | 'RUNFAST'
export type JourneyOperationDirection = 'FROM' | 'TO'

export type UseTrainJourneyOperationResult = {
    isEnabled: (v: LocationVisit) => boolean
    isSupported: (visit: LocationVisit) => boolean
    getDescription: (visit: LocationVisit) => string
    submitWillEnable: (
        visit: LocationVisit,
        activeVisit: LocationVisit | null
    ) => boolean
    submitWillDisable: (
        visit: LocationVisit,
        activeVisit: LocationVisit | null
    ) => boolean
    icon: IconDefinition
}

const useTrainJourneyOperation = (
    journey: LocationVisit[],
    mode: JourneyOperationMode,
    direction: JourneyOperationDirection
): UseTrainJourneyOperationResult => {
    const operation = useJourneyOperation(mode, direction)

    const isSupported = (visit: LocationVisit) => {
        return operation.isSupported(journey, visit)
    }

    const submitWillEnable = (
        visit: LocationVisit,
        activeVisit: LocationVisit | null
    ) => {
        return operation.submitWillEnable(journey, visit, activeVisit)
    }

    const submitWillDisable = (
        visit: LocationVisit,
        activeVisit: LocationVisit | null
    ) => {
        return operation.submitWillDisable(journey, visit, activeVisit)
    }

    return {
        isEnabled: operation.isEnabled,
        isSupported,
        getDescription: operation.getDescription,
        submitWillEnable,
        submitWillDisable,
        icon: operation.icon,
    }
}

export default useTrainJourneyOperation

import React from 'react'
import TextField, { TextFieldProps } from './base/TextField'

type IncidentTitleFieldProps = Omit<TextFieldProps, 'label'>

const IncidentTitleField = (props: IncidentTitleFieldProps) => {
    return <TextField label="Title" {...props} />
}

export default IncidentTitleField

import React, { useState } from 'react'
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    TimeScale,
    Title,
    Tooltip,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import TrainGraphToolbar from '../TrainGraphToolbar/TrainGraphToolbar'
import { Button, Dialog, Stack, Typography } from '@mui/material'
import {
    Simulation,
    useGetSimulatedJourneys,
} from '../../../../services/dst-api'
import TrainCharts from '../TrainCharts/TrainCharts'
import LoadingBox from '../../../../components/InformationBox/LoadingBox'
import ErrorBox from '../../../../components/InformationBox/ErrorBox'
import useTrainGraphSettings from '../../hooks/useTrainGraphSettings'
import DraggableTimeAdjuster from '../TimeAdjuster/DraggableTimeAdjuster'
import { useHotkeys } from 'react-hotkeys-hook'

ChartJS.register(
    CategoryScale,
    LinearScale,
    TimeScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
)

export type HoverMode = 'dataset' | 'y' | 'point'

type TrainGraphProps = {
    simulation: Simulation
}

const TrainGraph = ({ simulation }: TrainGraphProps) => {
    const [fullscreen, setFullscreen] = useState<boolean>(false)
    useHotkeys('right', () => trainGraphSettings.time.increment())
    useHotkeys('left', () => trainGraphSettings.time.decrement())
    const {
        isLoading: isJourneysLoading,
        isError: isJourneysError,
        data: journeys,
    } = useGetSimulatedJourneys(simulation.id!!, {
        query: {
            refetchOnWindowFocus: false,
        },
    })

    const trainGraphSettings = useTrainGraphSettings()

    if (isJourneysLoading) return <LoadingBox />

    if (isJourneysError)
        return (
            <ErrorBox message={'Simulated journeys could not be retrieved'} />
        )

    const chart = (
        <Stack
            sx={{
                backgroundColor: trainGraphSettings.colourScheme.background,
            }}
        >
            <TrainCharts settings={trainGraphSettings} journeys={journeys} />
        </Stack>
    )
    const handleCloseFullscreen = () => {
        setFullscreen(false)
    }

    return (
        <>
            <Dialog
                fullScreen
                open={fullscreen}
                onClose={() => setFullscreen(false)}
                // TransitionComponent={Transition}
            >
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    padding={2}
                >
                    <Typography
                        sx={{ ml: 2, flex: 1 }}
                        variant="h4"
                        component="div"
                    >
                        Train Graph
                    </Typography>
                    <Button onClick={handleCloseFullscreen}>Close</Button>
                </Stack>
                {chart}
            </Dialog>

            <Stack gap={1}>
                <TrainGraphToolbar
                    onFullscreen={() => setFullscreen(true)}
                    settings={trainGraphSettings}
                />
                <DraggableTimeAdjuster
                    onChange={trainGraphSettings.time.setStartDateTime}
                    time={trainGraphSettings.time.startTime}
                    period={trainGraphSettings.period.value}
                    border={1}
                >
                    {chart}
                </DraggableTimeAdjuster>
            </Stack>
        </>
    )
}

export default TrainGraph

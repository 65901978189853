import React, { useState } from 'react'
import { Grid } from '../../../../../../services/dst-api'
import {
    Box,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from '@mui/material'
import ResultSectionTitle from '../../../components/ResultSectionTitle/ResultSectionTitle'
import AnalysisChart from '../AnalysisChart/AnalysisChart'
import AnalysisGrid from '../AnalysisGrid/AnalysisGrid'

type AnalysisDisplayMode = 'table' | 'barchart'

export const AnalysisPanel = ({ data }: { data: Grid }) => {
    const [mode, setMode] = useState<AnalysisDisplayMode>('table')

    function getAvailableModes() {
        const supportedModes: AnalysisDisplayMode[] = ['table']
        const integerColumns = data.columns.filter(
            (columnDefinition) => columnDefinition.dataType === 'INTEGER'
        )
        if (integerColumns.length > 0) supportedModes.push('barchart')
        return supportedModes
    }

    return (
        <React.Fragment>
            <Stack spacing={2}>
                <AnalysisPanelHeader
                    title={data.title}
                    description={data.description}
                    mode={mode}
                    availableModes={getAvailableModes()}
                    updateMode={setMode}
                />
                <AnalysisPanelVisualisation mode={mode} data={data} />
            </Stack>
        </React.Fragment>
    )
}

const AnalysisPanelVisualisation = ({
    data,
    mode,
}: {
    data: Grid
    mode: AnalysisDisplayMode
}) => {
    switch (mode) {
        case 'table':
            return <AnalysisGrid data={data} />
        case 'barchart':
            return <AnalysisChart data={data} />
    }
}

const AnalysisPanelHeader = ({
    title,
    description,
    mode,
    availableModes,
    updateMode,
}: {
    title: string
    description: string
    mode: AnalysisDisplayMode
    availableModes: AnalysisDisplayMode[]
    updateMode: (value: AnalysisDisplayMode) => void
}) => {
    return (
        <Stack direction={'row'} spacing={4}>
            <Box sx={{ flexGrow: 1 }}>
                <Stack>
                    <ResultSectionTitle>{title}</ResultSectionTitle>
                    <Typography>{description}</Typography>
                </Stack>
            </Box>

            <ModesToggle
                mode={mode}
                updateMode={updateMode}
                availableModes={availableModes}
            />
        </Stack>
    )
}

const ModesToggle = ({
    mode,
    availableModes,
    updateMode,
}: {
    mode: AnalysisDisplayMode
    availableModes: AnalysisDisplayMode[]
    updateMode: (value: AnalysisDisplayMode) => void
}) => {
    if (availableModes.length < 2) return <></>

    const getDisplayName = (mode: AnalysisDisplayMode) => {
        switch (mode) {
            case 'table':
                return 'Table'
            case 'barchart':
                return 'Chart'
        }
    }

    return (
        <ToggleButtonGroup
            color="primary"
            value={mode}
            exclusive
            onChange={(event, value) => {
                if (value !== null) updateMode(value)
            }}
            aria-label="Mode"
            size={'small'}
        >
            {availableModes.map((availableMode) => {
                return (
                    <ToggleButton key={availableMode} value={availableMode}>
                        {getDisplayName(availableMode)}
                    </ToggleButton>
                )
            })}
        </ToggleButtonGroup>
    )
}

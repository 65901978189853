/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Decision Support Tool API
 * Decision Support Tool Application
 * OpenAPI spec version: v0.0.1
 */

/**
 * execution status of the simulation
 */
export type SimulationStatus = typeof SimulationStatus[keyof typeof SimulationStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SimulationStatus = {
  INITIALISING: 'INITIALISING',
  AWAITING_EXECUTION: 'AWAITING_EXECUTION',
  QUEUED: 'QUEUED',
  RUNNING: 'RUNNING',
  BREAKPOINT: 'BREAKPOINT',
  COMPLETE: 'COMPLETE',
  FAILED: 'FAILED',
} as const;

import React from 'react'
import {
    SwitchField,
    SwitchFieldProps,
} from '../../../../../components/fields/fields/base/SwitchField'

type ShowMinorLocationsFieldProps = Pick<
    SwitchFieldProps,
    'id' | 'value' | 'onChange'
>

const ShowMinorLocationsField = ({
    id,
    value,
    onChange,
}: ShowMinorLocationsFieldProps) => {
    return (
        <SwitchField
            id={id}
            titledFieldProps={{
                title: 'Show Minor Locations',
                subtitle:
                    'Enabling this will display all locations on the chart but may cause some labels to overlap',
            }}
            onChange={onChange}
            value={value}
        />
    )
}

export default ShowMinorLocationsField

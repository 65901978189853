import useTrainGraphData, {
    GraphableJourney,
} from '../../hooks/useTrainGraphData'
import { Line } from 'react-chartjs-2'
import {
    ChartDataset,
    CoreScaleOptions,
    Point,
    Scale,
    TooltipLabelStyle,
} from 'chart.js'
import React from 'react'
import { TrainGraphLocation } from '../../data/trainGraphSections'
import 'chartjs-adapter-date-fns'
import { SimulatedJourney } from '../../../../services/dst-api'
import { AllTrainGraphSettings } from '../../hooks/useTrainGraphSettings'
import { JourneyType } from '../TrainGraphSettings/TrainGraphSettingsFields/JourneyTypeField'
import dayjs from 'dayjs'

interface TrainChartProps {
    route: Array<TrainGraphLocation>
    displayXAxis: boolean
    settings: AllTrainGraphSettings
    journeys: SimulatedJourney[]
}

const TrainChart = ({
    route,
    displayXAxis,
    settings: allSettings,
    journeys,
}: TrainChartProps) => {
    const { generateChartData } = useTrainGraphData(route, journeys)

    const startTime = allSettings.time.startTime
    const endTime = allSettings.time.endTime
    const settings = allSettings.settings

    const colourScheme = allSettings.colourScheme
    let duration = endTime.diff(startTime, 'minutes')

    const minY = route[0].distance
    const maxY = route[route.length - 1].distance + (displayXAxis ? 1500 : 0)

    const createDataset = ({
        defaultColor,
        options = {},
        journeyType,
    }: {
        defaultColor: string | ((journey: GraphableJourney) => string)
        options?: Partial<Omit<ChartDataset<'line', Point[]>, 'data'>>
        journeyType: JourneyType['id']
    }): ChartDataset<'line', Point[]>[] => {
        return generateChartData(startTime, endTime, journeyType).map(
            (journey) => {
                const color =
                    typeof defaultColor === 'string'
                        ? defaultColor
                        : defaultColor(journey)
                return {
                    label: journey.headcode,
                    data: journey.points,
                    borderColor: color,
                    pointBorderColor: color,
                    pointHoverBackgroundColor: color,
                    pointHoverBorderColor: color,
                    ...(options ?? {}),
                }
            }
        )
    }
    const primaryDataset = createDataset({
        defaultColor: allSettings.getJourneyColour,
        options: {
            borderWidth: 2,
        },
        journeyType: settings.primaryJourneyType,
    })
    const comparatorDataset = createDataset({
        defaultColor: colourScheme.comparator.line.color,
        options: {
            borderWidth: 1,
            borderDash: [10, 5],
        },
        journeyType: settings.comparatorJourneyType,
    })

    const getYLabel = (value: number): string => {
        const location = route.find((l) => l.distance === value)!!
        if (location === null || location === undefined) {
            return 'BLANK!!!'
        }
        return location.tiploc
    }

    const commonXConfig = {
        border: {
            width: 0,
        },
        stepSize: duration / 6,
        maxTicksLimit: 10,
        min: startTime.valueOf(),
        max: endTime.valueOf(),
        padding: 0,
    }

    return (
        <Line
            options={{
                maintainAspectRatio: false,
                plugins: {
                    datalabels: {
                        display: function (context): boolean {
                            return (
                                settings.showJourneyLabels &&
                                context.dataIndex === 0
                            ) // display labels with an odd index
                        },
                        formatter: function (value, context) {
                            return context.dataset.label
                        },
                        backgroundColor: colourScheme.label.background,
                        color: colourScheme.label.color,
                        align: 'right',
                    },
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        callbacks: {
                            title(tooltipItems): string | string[] | void {
                                const location = route.find(
                                    (l) =>
                                        l.distance === tooltipItems[0].parsed.y
                                )!!
                                const time = dayjs(
                                    tooltipItems[0].parsed.x
                                ).format('YYYY-MM-DD hh:mm:ss')
                                return [location.tiploc, time]
                            },
                            label: function (context) {
                                const comparator =
                                    context.dataset.borderColor ===
                                    colourScheme.comparator.line.color
                                        ? ' (Comparator)'
                                        : ''

                                const origin = context.dataIndex === 0
                                const destination =
                                    context.dataIndex ===
                                    context.dataset.data.length - 1
                                const current = context.dataset.data[
                                    context.dataIndex
                                ] as Point
                                const departure = origin
                                    ? true
                                    : (
                                          context.dataset.data[
                                              context.dataIndex - 1
                                          ] as Point
                                      ).y === current.y
                                const arrival = destination
                                    ? true
                                    : (
                                          context.dataset.data[
                                              context.dataIndex + 1
                                          ] as Point
                                      ).y === current.y
                                let originDestLabel = ''
                                if (origin && !destination)
                                    originDestLabel += ' Origin'
                                if (!origin && destination)
                                    originDestLabel += ' Dest'
                                let arrDepLabel: string
                                if (arrival)
                                    arrDepLabel =
                                        origin || destination
                                            ? ' Arr'
                                            : ' Arrival'
                                else if (departure)
                                    arrDepLabel =
                                        origin || destination
                                            ? ' Dep'
                                            : ' Departure'
                                else arrDepLabel = ' Pass'
                                return (
                                    context.dataset.label +
                                    originDestLabel +
                                    arrDepLabel +
                                    ' ' +
                                    comparator
                                )
                            },
                            labelColor(tooltipItem): TooltipLabelStyle | void {
                                const seriesColor: string = tooltipItem.dataset
                                    .borderColor as string
                                return {
                                    backgroundColor: seriesColor,
                                    borderColor: seriesColor,
                                }
                            },
                        },
                    },
                },
                hover: {
                    mode: settings.hoverMode,
                    intersect: true,
                },
                datasets: {
                    // options for all line datasets
                    line: {
                        cubicInterpolationMode: 'default',
                        animation: {
                            duration: 0,
                        },
                        // tension: 0
                        hoverBorderWidth: 3,
                        showLine: true,
                    },
                },
                elements: {
                    // options for all line elements
                    line: {},
                    // options for all point elements
                    point: {
                        borderWidth: 0.8,
                        pointStyle: 'circle',
                        radius: 2,
                        hitRadius: 4,
                        hoverRadius: 3,
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                    },
                },

                scales: {
                    y: {
                        type: 'linear',
                        grid: {
                            display: true,
                            color: colourScheme.grid,
                        },
                        position: 'left',
                        // initially, generate all route ticks
                        ticks: {
                            callback: function (value) {
                                const intValue = parseInt(value.toString())
                                return getYLabel(intValue)
                            },
                            maxTicksLimit: route.length,
                            showLabelBackdrop: false,
                            backdropColor: colourScheme.background,
                            z: 50,
                            mirror: true,
                            padding: 0,
                            color: colourScheme.ticks.text,
                        },
                        border: {
                            display: true,
                            color: colourScheme.background + 'aa',
                            width: 150,
                            z: 50,
                        },
                        // afterward, filter these to choose which to display
                        afterBuildTicks(axis: Scale) {
                            axis.ticks = route
                                .filter(
                                    (l) =>
                                        l.level <
                                        (settings.showMinorLocations ? 3 : 2)
                                )
                                .map((l) => {
                                    return {
                                        value: l.distance,
                                        label: getYLabel(l.distance),
                                        major: false,
                                    }
                                })
                        },
                        min: minY,
                        max: maxY,
                    },

                    bottom: {
                        border: commonXConfig.border,
                        position: 'bottom',
                        grid: {
                            tickColor: colourScheme.grid,
                            tickLength: 10,
                        },
                        type: 'time',
                        ticks: {
                            // distance between ticks
                            stepSize: commonXConfig.stepSize,
                            maxTicksLimit: commonXConfig.maxTicksLimit,

                            // hide text label on bottom axis
                            font: {
                                lineHeight: 0,
                                size: 0,
                            },

                            // no padding at bottom of chart
                            padding: commonXConfig.padding,
                        },
                        min: commonXConfig.min,
                        max: commonXConfig.max,
                    },
                    x: {
                        border: commonXConfig.border,
                        position: 'top',
                        grid: {
                            display: true,
                            color: colourScheme.grid,
                            tickLength: 10,
                        },
                        type: 'time',
                        time: {
                            tooltipFormat: 'HH:mm',
                            unit: 'minute',
                            displayFormats: {
                                minute: 'HH:mm',
                            },
                        },
                        ticks: {
                            // distance between ticks
                            stepSize: commonXConfig.stepSize,
                            maxTicksLimit: commonXConfig.maxTicksLimit,

                            // display labels
                            color: displayXAxis
                                ? colourScheme.ticks.text
                                : '#ff000000',
                            showLabelBackdrop: false,
                            backdropColor: colourScheme.background,
                            backdropPadding: 10,
                            z: 50,

                            mirror: true,

                            // no padding at bottom of chart
                            padding: commonXConfig.padding,
                        },
                        min: commonXConfig.min,
                        max: commonXConfig.max,
                        // change label format of first tick
                        afterTickToLabelConversion: (
                            axis: Scale<CoreScaleOptions>
                        ) => {
                            axis.ticks = [
                                ...axis.ticks.filter((_, index) => index !== 0),
                            ]
                        },
                    },
                },
            }}
            data={{
                datasets: [...primaryDataset, ...comparatorDataset],
            }}
        />
    )
}

export default TrainChart

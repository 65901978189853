import React from 'react'
import Page from '../../../../components/Page/Page'
import CreateSimulationForm from './components/ManageSimulationForm'

function CreateSimulation() {
    return (
        <Page
            maxWidth="lg"
            headerProps={{
                title: 'Create Simulation',
                breadcrumbConfig: {
                    previous: [
                        {
                            text: 'Simulations',
                            to: '/replay',
                        },
                    ],
                    current: {
                        text: 'Create Simulation',
                    },
                },
            }}
        >
            <CreateSimulationForm />
        </Page>
    )
}

export default CreateSimulation

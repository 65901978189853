import {
    Simulation,
    useDeleteSimulation,
    useExecuteLiveSimulation,
} from '../../../services/dst-api'
import useSimulations, {
    SimulationSortField,
} from '../../../hooks/useSimulations'
import { useState } from 'react'
import useSimulation from '../../../hooks/useSimulation'
import { SortDirection } from '../../../components/ListModeSearchBar/GenericSortBox/GenericSortBox'

const useRunningLiveSimulation = () => {
    const [lastKnown, setLastKnown] = useState<number>(-1)

    const { mutate: deleteSimulationMutation } = useDeleteSimulation()
    const isRunning = (simulation: Simulation | undefined) =>
        simulation &&
        (simulation.status === 'QUEUED' || simulation.status === 'RUNNING')

    const { simulations, isLoading, isError } = useSimulations(['LIVE'], {
        field: SimulationSortField.CREATION_DATE,
        direction: SortDirection.DESC,
    })
    const { simulation } = useSimulation(lastKnown)
    const createLiveSimulationQuery = useExecuteLiveSimulation({
        query: {
            enabled: false,
        },
    })

    const runningLiveSimulation = simulations
        .filter((simulation: Simulation) => {
            return isRunning(simulation)
        })
        .at(0)
    if (
        runningLiveSimulation !== undefined &&
        lastKnown !== runningLiveSimulation.id!!
    ) {
        console.log('updating last known to : ' + runningLiveSimulation.id!!)
        setLastKnown(runningLiveSimulation.id!!)
    }

    const createSimulation = () => {
        createLiveSimulationQuery.refetch()
    }

    const cancelSimulation = () => {
        if (runningLiveSimulation !== undefined) {
            deleteSimulationMutation({
                params: {
                    simulationId: runningLiveSimulation.id!!,
                },
            })
        }
    }

    return {
        isLoading: isLoading,
        isError: isError,
        simulation: simulation,
        createSimulation,
        cancelSimulation,
    }
}

export default useRunningLiveSimulation

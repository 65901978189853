import { TrainGraphSection } from '../data/trainGraphSections'
import { useEffect, useState } from 'react'
import useSettingsContext from './useSettingsContext'

const useTrainGraphSections = () => {
    const { trainGraphLayout, sections, setSections } = useSettingsContext()
    const [sectionNames, setSectionNames] = useState(
        sections.map((s) => s.name)
    )

    useEffect(() => {
        const sections = trainGraphLayout.sections.filter((section) =>
            sectionNames.includes(section.name)
        )
        const merged = mergeSections(sections)
        setSections(merged)
    }, [sectionNames, setSections, trainGraphLayout.sections])

    function mergeSections(sectionsToMerge: Array<TrainGraphSection>) {
        const mergedSections: Array<TrainGraphSection> = []
        sectionsToMerge.forEach((section) => {
            if (mergedSections.length === 0) {
                mergedSections.push(section)
                return
            }

            const prevSection = mergedSections.pop()!!
            const prevLocation = prevSection.locations[0]
            const nextLocation = section.locations[section.locations.length - 1]
            if (prevLocation.tiploc === nextLocation.tiploc) {
                //combine sections
                const prevName = prevSection.name.replace(' &', ',')
                mergedSections.push({
                    name: prevName + ' & ' + section.name,
                    description: '',
                    locations: [
                        ...section.locations,
                        ...prevSection.locations.slice(1),
                    ],
                })
            } else {
                mergedSections.push(prevSection, section)
            }
        })
        return mergedSections
    }

    return {
        enabledSections: sections,
        allSections: trainGraphLayout.sections,
        enabledSectionNames: sectionNames,
        setEnabledSections: setSectionNames,
        isEnabled: (sectionName: string) => {
            return sectionNames.indexOf(sectionName) !== -1
        },
    }
}

export default useTrainGraphSections

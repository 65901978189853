import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import React from 'react'
import BaseTitledField, { TitledFieldProps } from './BaseTitledField'

type ToggleButtonGroupOption = {
    id: number
    name: string
}

export interface ToggleButtonGroupFieldProps {
    id: string
    onChange?: (event: React.MouseEvent<HTMLElement>, value: any) => void
    value: ToggleButtonGroupOption['id']
    options: ToggleButtonGroupOption[]
    titledFieldProps: TitledFieldProps
}

const ToggleButtonGroupField = ({
    id,
    value,
    onChange,
    options,
}: ToggleButtonGroupFieldProps) => {
    const buttons = options.map((option) => {
        return (
            <ToggleButton key={option.id} value={option.id}>
                {option.name}
            </ToggleButton>
        )
    })

    return (
        <BaseTitledField
            title={'Simulation Duration'}
            subtitle={'Simulate for the specified duration in the future.'}
        >
            <ToggleButtonGroup
                id={id}
                color="primary"
                value={Number(value)}
                exclusive
                onChange={onChange}
            >
                {buttons}
            </ToggleButtonGroup>
        </BaseTitledField>
    )
}

export default ToggleButtonGroupField

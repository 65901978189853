import React from 'react'
import LoadingBox from '../../../../components/InformationBox/LoadingBox'
import ErrorBox from '../../../../components/InformationBox/ErrorBox'
import useRunningLiveSimulation from '../../hooks/useRunningLiveSimulation'
import InformationBox from '../../../../components/InformationBox/InformationBox'
import { LoadingButton } from '@mui/lab'
import { Navigate } from 'react-router-dom'
import { Button } from '@mui/material'

export const RecommendActionsView = () => {
    const {
        isLoading,
        isError,
        simulation,
        createSimulation,
        cancelSimulation,
    } = useRunningLiveSimulation()

    if (isLoading) {
        return <LoadingBox />
    }

    if (isError) {
        return <ErrorBox message={'Simulations could not be retrieved.'} />
    }

    if (!simulation) {
        return (
            <InformationBox
                title={'Create Simulation'}
                message={
                    'To create and start execution of a new simulation, click the button below.'
                }
            >
                <LoadingButton
                    loading={isLoading}
                    loadingPosition="start"
                    color="primary"
                    variant="contained"
                    fullWidth
                    onClick={createSimulation}
                >
                    Create Live Simulation
                </LoadingButton>
            </InformationBox>
        )
    }

    switch (simulation?.status) {
        case 'RUNNING':
            return (
                <InformationBox
                    title={'Simulation Running'}
                    message={
                        'A simulation is currently running. Please wait for this to complete before starting another simulation.'
                    }
                />
            )
        case 'QUEUED':
            return (
                <InformationBox
                    title={'Simulation Queued'}
                    message={
                        'A simulation is currently queued. Please wait for this to complete before starting another simulation.'
                    }
                >
                    <Button onClick={cancelSimulation}>Cancel</Button>
                </InformationBox>
            )
        case 'FAILED':
            return (
                <ErrorBox
                    message={'The live simulation unexpected failed.'}
                ></ErrorBox>
            )
        case 'COMPLETE':
            return <Navigate to="../previous-recommendations" replace={true} />
        default:
            return (
                <ErrorBox
                    message={
                        'The previous simulation is in an unexpected state.'
                    }
                />
            )
    }
}

import React from 'react'
import { Edit as EditIcon, Trash as TrashIcon } from 'react-feather'
import { IncidentListProps, IncidentsList } from './IncidentsList'

type EditableIncidentsListProps = {
    onIncidentDelete: (incidentId: number) => void
} & Pick<IncidentListProps, 'incidents'>

const EditableIncidentsList = ({
    incidents,
    onIncidentDelete,
}: EditableIncidentsListProps) => {
    return (
        <IncidentsList
            incidents={incidents}
            actions={[
                {
                    type: 'LinkAction',
                    to: (incident) => '../incident/' + incident.id,
                    icon: <EditIcon />,
                },
                {
                    type: 'ClickAction',
                    onClick: (incident) => onIncidentDelete(incident.id),
                    icon: <TrashIcon />,
                },
            ]}
        />
    )
}

export default EditableIncidentsList

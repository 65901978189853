import { styled } from '@mui/material/styles'
import { Box, Container } from '@mui/material'
import PageHeader, {
    PageHeaderProps,
} from '../../../../components/PageHeader/PageHeader'
import React, { ReactNode } from 'react'

const PageContainer = styled(Container)(({ theme }) => ({
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
}))

const ChildrenContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(2),
    flexDirection: 'column',
}))

type AdminPageProps = {
    headerProps: PageHeaderProps
    children: ReactNode
}
const AdminPage = ({ headerProps, children }: AdminPageProps) => {
    return (
        <PageContainer maxWidth="lg">
            <PageHeader {...headerProps} />
            <Box mt={3}>
                <ChildrenContainer>{children}</ChildrenContainer>
            </Box>
        </PageContainer>
    )
}

export default AdminPage

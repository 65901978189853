import React from 'react'
import { styled } from '@mui/material/styles'
import { Divider } from '@mui/material'
import TrainTimetableGrid from '../../TimetableGrid/TrainTimetableGrid'
import TrainListItem from '../components/TrainListItem'
import { EditableTrainsListProps } from '../TrainsList'

const List = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
}))

const EditableTrainList = ({
    trains,
    onTrainDelete,
}: EditableTrainsListProps) => {
    const listItems = trains.map((train) => {
        return (
            <TrainListItem
                key={train.id}
                train={train}
                collapsible={true}
                orientation={'horizontal'}
            >
                <>
                    <Divider
                        orientation="horizontal"
                        variant="middle"
                        flexItem
                    />
                    <TrainTimetableGrid trainId={train.id} />
                </>
            </TrainListItem>
        )
    })

    return <List>{listItems}</List>
}

export default EditableTrainList

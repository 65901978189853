import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import TrainGraphSettingsForm, {
    TrainGraphSettings,
} from './TrainGraphSettingsForm'
import React from 'react'

type TrainGraphSettingsDialogProps = {
    open: boolean
    close: () => void
    settings: TrainGraphSettings
    save: (settings: TrainGraphSettings) => void
}

const TrainGraphSettingsDialog = ({
    open,
    close,
    settings,
    save,
}: TrainGraphSettingsDialogProps) => {
    return (
        <Dialog open={open} onClose={close} scroll={'paper'}>
            <DialogTitle>Train Graph Settings</DialogTitle>
            <DialogContent dividers={true}>
                <TrainGraphSettingsForm settings={settings} save={save} />
            </DialogContent>
        </Dialog>
    )
}

export default TrainGraphSettingsDialog

import { styled } from '@mui/material/styles'
import {
    Button,
    IconButton,
    Paper,
    Stack,
    SvgIcon,
    Tooltip,
    Typography,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2' // Grid version 2
import { Simulation } from '../../../../../../../services/dst-api'
import { Link, Link as RouterLink } from 'react-router-dom'
import SimulationStatusChip from './SimulationStatusChip'
import {
    Edit as EditIcon,
    Repeat as RepeatIcon,
    Trash as TrashIcon,
} from 'react-feather'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import CancelIcon from '@mui/icons-material/Cancel'
import React from 'react'

const SimulationCardContainer = styled(Paper)(() => ({}))

const SimulationCardContent = styled('div')(({ theme }) => ({
    padding: theme.spacing(1),
}))

const SimulationIdentifier = styled('div')(() => ({
    opacity: '0.3',
}))

type NarrowSimulationCardProps = {
    simulation: Simulation
    onSimulationDelete: (simulationId: number) => void
    onSimulationExecute: (simulationId: number) => void
    onSimulationCancel: (simulationId: number) => void
    onSimulationResume: (simulationId: number) => void
    onSimulationRerun: (simulationId: number) => void
}

function NarrowSimulationCard({
    simulation,
    onSimulationDelete,
    onSimulationExecute,
    onSimulationCancel,
    onSimulationResume,
    onSimulationRerun,
}: NarrowSimulationCardProps) {
    const positiveActionIconColor = 'success'
    const negativeActionIconColor = 'error.light'
    const mode = 'REPLAY'

    return (
        <SimulationCardContainer>
            <SimulationCardContent>
                <Grid container spacing={2}>
                    <Grid
                        xs={12}
                        sm={3}
                        md={2}
                        lg={1}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <SimulationStatusChip
                            status={simulation.status}
                            statusMessage={simulation.statusMessage}
                        />
                    </Grid>
                    <Grid xs={12} sm={4} md={6} lg={8}>
                        <Stack direction={'row'} spacing={1}>
                            <Typography gutterBottom variant="h5">
                                {simulation.title}
                            </Typography>
                            <SimulationIdentifier>
                                (#{simulation.id})
                            </SimulationIdentifier>
                        </Stack>
                        <Typography>{simulation.description}</Typography>
                    </Grid>
                    <Grid
                        xs={12}
                        sm={2}
                        lg={1}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Typography>{simulation.date}</Typography>
                    </Grid>
                    <Grid
                        xs={12}
                        sm={1}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        {/* Show delete button for all sim types apart from 'RUNNING' */}
                        {simulation.status !== 'RUNNING' && (
                            <Tooltip title="Delete Simulation">
                                <IconButton
                                    onClick={() =>
                                        onSimulationDelete(simulation.id!!)
                                    }
                                >
                                    <SvgIcon fontSize="small">
                                        <TrashIcon />
                                    </SvgIcon>
                                </IconButton>
                            </Tooltip>
                        )}

                        {/* Show rerun button for 'COMPLETE' and 'ERROR' statuses */}
                        {(simulation.status === 'COMPLETE' ||
                            simulation.status === 'FAILED') && (
                            <Tooltip title="Rerun Simulation">
                                <IconButton
                                    onClick={() =>
                                        onSimulationRerun(simulation.id!!)
                                    }
                                >
                                    <SvgIcon fontSize="small">
                                        <RepeatIcon />
                                    </SvgIcon>
                                </IconButton>
                            </Tooltip>
                        )}

                        {/* Show edit button for sim types 'AWAITING_EXECUTION' and 'BREAKPOINT' */}
                        {(simulation.status === 'AWAITING_EXECUTION' ||
                            simulation.status === 'BREAKPOINT') && (
                            <Tooltip title="Edit Simulation">
                                <IconButton
                                    component={RouterLink}
                                    to={
                                        './simulation/' +
                                        simulation.id +
                                        '/edit/basic'
                                    }
                                >
                                    <SvgIcon fontSize="small">
                                        <EditIcon />
                                    </SvgIcon>
                                </IconButton>
                            </Tooltip>
                        )}
                    </Grid>
                    <Grid
                        xs={12}
                        sm={2}
                        md={1}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        {/* Show execute button for sim type 'AWAITING_EXECUTION' */}
                        {simulation.status === 'AWAITING_EXECUTION' && (
                            <Tooltip
                                title="Get Recommended Actions"
                                placement="left"
                            >
                                <IconButton
                                    onClick={() =>
                                        onSimulationExecute(simulation.id!!)
                                    }
                                >
                                    <PlayArrowIcon
                                        fontSize="large"
                                        sx={{ color: positiveActionIconColor }}
                                    />
                                </IconButton>
                            </Tooltip>
                        )}

                        {/* Show cancel button for sim type 'QUEUED' */}
                        {simulation.status === 'QUEUED' && (
                            <Tooltip title="Cancel Execution" placement="left">
                                <IconButton
                                    onClick={() =>
                                        onSimulationCancel(simulation.id!!)
                                    }
                                >
                                    <CancelIcon
                                        sx={{ color: negativeActionIconColor }}
                                    />
                                </IconButton>
                            </Tooltip>
                        )}

                        {/* Show resume button for sim type 'BREAKPOINT' */}
                        {simulation.status === 'BREAKPOINT' && (
                            <Tooltip title="Resume Execution" placement="left">
                                <IconButton
                                    onClick={() =>
                                        onSimulationResume(simulation.id!!)
                                    }
                                >
                                    <PlayArrowIcon
                                        fontSize="large"
                                        sx={{ color: positiveActionIconColor }}
                                    />
                                </IconButton>
                            </Tooltip>
                        )}

                        {/* Show view results button for sim type 'COMPLETE' */}
                        {simulation.status === 'COMPLETE' && (
                            <Link
                                to={`/simulation/${simulation.id}/overview?mode=${mode}`}
                            >
                                <Button size="small" color="primary">
                                    View Results
                                </Button>
                            </Link>
                        )}
                    </Grid>
                </Grid>
            </SimulationCardContent>
        </SimulationCardContainer>
    )
}
export default NarrowSimulationCard

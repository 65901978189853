import React, { useState } from 'react'
import LoadingBox from '../../../../components/InformationBox/LoadingBox'
import ErrorBox from '../../../../components/InformationBox/ErrorBox'
import Page from '../../../../components/Page/Page'
import useSimulations, {
    SimulationSortField,
} from '../../../../hooks/useSimulations'
import useManageSimulation from '../../hooks/useManageSimulation'
import SimulationsDisplay from './components/SimulationsDisplay/SimulationsDisplay'
import { SimulationsDisplayMode } from '../../../../types/SimulationsDisplayMode'
import GridViewIcon from '@mui/icons-material/GridView'
import ListIcon from '@mui/icons-material/List'
import { ListModeSearchBar } from '../../../../components/ListModeSearchBar/ListModeSearchBar'
import {
    GenericSort,
    SortDirection,
} from '../../../../components/ListModeSearchBar/GenericSortBox/GenericSortBox'

function SimulationsOverview() {
    const [displayMode, setDisplayMode] = useState(SimulationsDisplayMode.LIST)
    const [sort, setSort] = useState<GenericSort<SimulationSortField>>({
        field: SimulationSortField.CREATION_DATE,
        direction: SortDirection.DESC,
    })
    const { isLoading, isError, simulations } = useSimulations(['REPLAY'], sort)
    const simulationManager = useManageSimulation()

    if (isLoading) {
        return <LoadingBox />
    }

    if (isError) {
        return <ErrorBox message={'Simulations could not be retrieved.'} />
    }

    const handleSimulationDeleteClick = (simulationId: number) => {
        simulationManager.deleteSimulation(simulationId)
    }

    const handleSimulationExecuteClick = (simulationId: number) => {
        simulationManager.executeSimulation(simulationId)
    }

    const handleSimulationCancelClick = (simulationId: number) => {
        simulationManager.cancelSimulation(simulationId)
    }

    const handleSimulationResumeClick = (simulationId: number) => {
        simulationManager.resumeSimulation(simulationId)
    }

    const handleSimulationRerunClick = (simulationId: number) => {
        simulationManager.rerunSimulation(simulationId)
    }

    function handleModeChange(newMode: SimulationsDisplayMode) {
        setDisplayMode(newMode)
    }

    return (
        <Page
            maxWidth="lg"
            headerProps={{
                title: 'Simulations',
                breadcrumbConfig: {
                    previous: [
                        {
                            text: 'Home',
                            to: '/',
                        },
                    ],
                    current: {
                        text: 'Replay Overview',
                    },
                },
                actionProps: {
                    type: 'LinkAction',
                    icon: 'add',
                    text: 'Add Simulation',
                    to: '../replay/simulation/new',
                },
            }}
        >
            <ListModeSearchBar
                itemCount={simulations.length}
                modeProps={{
                    mode: displayMode,
                    onModeChange: handleModeChange,
                    contentsByMode: {
                        [SimulationsDisplayMode.GRID]: <GridViewIcon />,
                        [SimulationsDisplayMode.LIST]: <ListIcon />,
                    },
                    descriptionsByMode: {
                        [SimulationsDisplayMode.GRID]: 'Grid',
                        [SimulationsDisplayMode.LIST]: 'List',
                    },
                }}
                entityDescription={'simulations'}
                sortProps={{
                    value: sort,
                    updateSort: setSort,
                    labels: {
                        [SimulationSortField.CREATION_DATE]: 'Creation Date',
                        [SimulationSortField.START_DATE]: 'Start Date',
                        [SimulationSortField.STATUS]: 'Status',
                        [SimulationSortField.TITLE]: 'Title',
                    },
                }}
            />

            <SimulationsDisplay
                mode={displayMode}
                simulations={simulations}
                onSimulationDelete={handleSimulationDeleteClick}
                onSimulationExecute={handleSimulationExecuteClick}
                onSimulationCancel={handleSimulationCancelClick}
                onSimulationResume={handleSimulationResumeClick}
                onSimulationRerun={handleSimulationRerunClick}
            />
        </Page>
    )
}

export default SimulationsOverview

/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Decision Support Tool API
 * Decision Support Tool Application
 * OpenAPI spec version: v0.0.1
 */

/**
 * The action taken at the visit
 */
export type LocationVisitAction = typeof LocationVisitAction[keyof typeof LocationVisitAction];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LocationVisitAction = {
  STOP: 'STOP',
  PASS: 'PASS',
  ORIGIN: 'ORIGIN',
  DESTINATION: 'DESTINATION',
} as const;

import ErrorBox from '../../../../components/InformationBox/ErrorBox'
import React from 'react'
import Page from '../../../../components/Page/Page'
import useSimulationContext from '../../../Simulation/hooks/useSimulationContext'
import TrainGraph from '../../../TrainGraph/components/TrainGraph/TrainGraph'

const SimulationResultsTrainGraphPage = () => {
    const { simulation } = useSimulationContext()
    if (!simulation)
        return (
            <ErrorBox
                message={'Simulation train graph could not be displayed.'}
            />
        )
    return (
        <Page
            headerProps={{
                title: 'Train Graph',
            }}
            maxWidth={false}
        >
            <TrainGraph simulation={simulation} />
        </Page>
    )
}

export default SimulationResultsTrainGraphPage

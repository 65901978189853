import { useState } from 'react'
import { LocationVisit } from '../../../services/dst-api'

export type ActiveVisitManager = {
    activeVisit: LocationVisit | null
    update: (visit: LocationVisit) => void
    clear: () => void
}

const useActiveVisit = (): ActiveVisitManager => {
    const [activeVisit, setActiveVisit] = useState<LocationVisit | null>(null)

    return {
        activeVisit: activeVisit,
        update: setActiveVisit,
        clear: () => setActiveVisit(null),
    }
}

export default useActiveVisit

import React, { MouseEventHandler } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
    AppBar,
    Button,
    Hidden,
    IconButton,
    Toolbar,
    Typography,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import InputIcon from '@mui/icons-material/Input'
import { styled } from '@mui/system'
import { useAuth0 } from '@auth0/auth0-react'

const LogoIcon = styled('img')(() => ({
    verticalAlign: 'middle',
    webkitFilter: 'drop-shadow(3px 3px 2px rgba(0, 0, 0, .7))',
    filter: 'drop-shadow(3px 3px 2px rgba(0, 0, 0, .5))',
}))

const TopbarTitleText = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    display: 'inline-block',
    verticalAlign: 'middle',
    marginLeft: theme.spacing(1),
    textShadow: '1px 1px 2px black, 0 0 25px blue, 0 0 5px darkorange',
}))

const HeaderButton = styled(IconButton)(({ theme }) => ({
    marginLeft: theme.spacing(1),
    color: 'inherit',
}))

const HeaderButtonWithLabel = styled(Button)(({ theme }) => ({
    marginLeft: theme.spacing(1),
    color: 'inherit',
}))

const TopbarTitle = () => {
    return (
        <RouterLink to="/">
            <div>
                <LogoIcon
                    alt="Logo"
                    src="/images/logos/logo_decisionSupport.svg"
                />
                <TopbarTitleText variant="h1">
                    Decision Support Tool
                </TopbarTitleText>
            </div>
        </RouterLink>
    )
}

type HeaderButtonsProps = {
    onSidebarOpen: MouseEventHandler | undefined
    navigationEnabled: boolean
}

const LoginLogoutButton = () => {
    const { isLoading, isAuthenticated, error, loginWithRedirect, logout } =
        useAuth0()

    if (isLoading) {
        return <HeaderButton>Loading</HeaderButton>
    }
    if (error) {
        return <HeaderButton>Auth Error</HeaderButton>
    }

    if (isAuthenticated) {
        return (
            <HeaderButtonWithLabel
                endIcon={<InputIcon />}
                onClick={() =>
                    logout({
                        logoutParams: {
                            returnTo: window.location.origin,
                        },
                    })
                }
            >
                Logout
            </HeaderButtonWithLabel>
        )
    } else {
        return (
            <HeaderButtonWithLabel onClick={() => loginWithRedirect()}>
                Log in
            </HeaderButtonWithLabel>
        )
    }
}

const HeaderButtons = ({
    navigationEnabled,
    onSidebarOpen,
}: HeaderButtonsProps) => {
    return (
        <>
            <Hidden mdDown>
                <LoginLogoutButton />
            </Hidden>

            {navigationEnabled && (
                <Hidden lgUp>
                    <HeaderButton onClick={onSidebarOpen}>
                        <MenuIcon />
                    </HeaderButton>
                </Hidden>
            )}
        </>
    )
}

type HeaderProps = {
    onSidebarOpen?: MouseEventHandler | undefined
    navigationEnabled?: boolean
}

export const Header = ({
    navigationEnabled = false,
    onSidebarOpen = () => {},
}: HeaderProps) => {
    return (
        <AppBar>
            <Toolbar>
                <TopbarTitle />

                <div style={{ flexGrow: 1 }} />

                <HeaderButtons
                    navigationEnabled={navigationEnabled}
                    onSidebarOpen={onSidebarOpen}
                />
            </Toolbar>
        </AppBar>
    )
}

import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material'
import React, { JSX } from 'react'
import { SimulationsDisplayMode } from '../../../types/SimulationsDisplayMode'
import { TrainListDisplayMode } from '../../../types/TrainListDisplayMode'

export type DisplayButtonGenericType =
    | TrainListDisplayMode
    | SimulationsDisplayMode

export type DisplayButtonProps<T extends DisplayButtonGenericType> = {
    mode: T
    onModeChange: (value: T) => void
    contentsByMode: Record<T, JSX.Element>
    descriptionsByMode: Record<T, string>
}

export function DisplayButtonsView<T extends DisplayButtonGenericType>({
    mode,
    onModeChange,
    contentsByMode,
    descriptionsByMode,
}: DisplayButtonProps<T>) {
    const handleChange = (event: React.MouseEvent<HTMLElement>, newMode: T) => {
        if (newMode != null && mode !== newMode) onModeChange(newMode)
    }

    return (
        <ToggleButtonGroup
            value={mode}
            exclusive
            onChange={handleChange}
            aria-label="text alignment"
        >
            {(Object.keys(contentsByMode) as Array<T>).map((mode) => {
                const contents = contentsByMode[mode]
                const tooltip = `${descriptionsByMode[mode]} Layout`
                return (
                    <Tooltip title={tooltip}>
                        <ToggleButton size="small" value={mode}>
                            {contents}
                        </ToggleButton>
                    </Tooltip>
                )
            })}
        </ToggleButtonGroup>
    )
}

import { Train } from '../../../../services/dst-api'
import React, { JSX } from 'react'
import { Stack } from '@mui/material'
import TrainLineup from './TrainLineup/TrainLineup'
import EditableTrainList from './EditableTrainList/EditableTrainList'
import { TrainListDisplayMode } from '../../../../types/TrainListDisplayMode'

export type TrainsListProps = {
    trains: Train[]
}

export type EditableTrainsListProps = {
    onTrainDelete: (id: number) => void
    editor?: {
        onOpenEditor: (train: Train) => void
        onCloseEditor: () => void
        editing: boolean
    }
} & TrainsListProps

type EditableTrainsListFactoryProps = {
    mode: TrainListDisplayMode
} & EditableTrainsListProps

// const TrainsPaper = styled(Paper)(({ theme }) => ({
//     adding: theme.spacing(2),
//     display: 'flex',
//     overflow: 'auto',
//     flexDirection: 'column',
// }))

function TrainsListDisplay({ mode, ...rest }: EditableTrainsListFactoryProps) {
    const byDisplayType: Record<
        TrainListDisplayMode,
        (props: EditableTrainsListProps) => JSX.Element
    > = {
        // [TrainListDisplayMode.GRID]: (props) => {
        //     return (
        //         <TrainsPaper>
        //             <EditableTrainGrid {...props} />
        //         </TrainsPaper>
        //     )
        // },
        [TrainListDisplayMode.LIST]: (props) => {
            return <EditableTrainList {...props} />
        },
        [TrainListDisplayMode.CARD]: (props) => {
            return (
                <TrainLineup
                    {...props}
                    pageSize={3}
                    mode={'card'}
                    editable={true}
                />
            )
        },
        [TrainListDisplayMode.LINEUP]: (props) => {
            return (
                <TrainLineup
                    {...props}
                    pageSize={8}
                    mode={'grid'}
                    editable={true}
                />
            )
        },
    }

    return byDisplayType[mode](rest)
}

function TrainsList(props: EditableTrainsListFactoryProps) {
    return (
        <Stack gap={1}>
            <TrainsListDisplay {...props} />
        </Stack>
    )
}

export default TrainsList

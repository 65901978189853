import { Button } from '@mui/material'
import React, { forwardRef } from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import { blueGrey } from '@mui/material/colors'
import { styled } from '@mui/material/styles'

const StyledButton = styled(Button)(({ theme }) => ({
    color: blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
    '&:hover': {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
    },
    '&.active': {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
    },
}))

const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref} style={{ flexGrow: 1 }}>
        <RouterLink {...props} />
    </div>
))

export const NavButton = (props) => {
    return (
        <StyledButton
            component={CustomRouterLink}
            to={props.href}
            disabled={props.disabled}
        >
            {props.children}
        </StyledButton>
    )
}

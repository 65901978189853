import React, { ReactNode } from 'react'
import Card from '@mui/material/Card'
import { CardContent, Skeleton, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { LoadingButton } from '@mui/lab'

const FormTitle = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(1),
}))

const CardContentStyled = styled(CardContent)(() => ({
    display: 'flex',
    flexDirection: 'column',
    rowGap: '2em',
}))

type CommonFormLayoutProps = {
    title?: string
    children: ReactNode
}

export const CommonFormLayout = ({
    title,
    children,
}: CommonFormLayoutProps) => {
    return (
        <Card>
            <CardContentStyled>
                {title && (
                    <FormTitle variant={'h3'} color="text.secondary">
                        {title}
                    </FormTitle>
                )}

                {children}
            </CardContentStyled>
        </Card>
    )
}

const SkeletonStyled = styled(Skeleton)(({ theme }) => ({
    marginBottom: theme.spacing(3),
    height: '2em',
}))

type CommonSkeletonFormProps = {
    title?: string
}

export const CommonSkeletonForm = ({ title }: CommonSkeletonFormProps) => {
    return (
        <CommonFormLayout title={title}>
            <SkeletonStyled variant="rectangular" />
            <SkeletonStyled variant="rectangular" />
            <SkeletonStyled variant="rectangular" />
            <LoadingButton loading variant="contained">
                Submit
            </LoadingButton>
        </CommonFormLayout>
    )
}

import React from 'react'
import { styled } from '@mui/material/styles'

type DisabledProps = {
    disabled: boolean
    children: React.ReactNode
}

const DisabledContainer = styled('div')(() => ({
    opacity: 0.5,
    pointerEvents: 'none',
}))

const DisabledContainerWithAlert = styled('div')(() => ({
    display: 'block',
}))

export const Disabled = ({ disabled = true, children }: DisabledProps) => {
    if (disabled) {
        return (
            <DisabledContainerWithAlert>
                <DisabledContainer>{children}</DisabledContainer>
            </DisabledContainerWithAlert>
        )
    }

    return <React.Fragment>{children}</React.Fragment>
}

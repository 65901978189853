type RouteLocation = {
    tiploc: string
}

export type Route = {
    id: number
    name: string
    locations: RouteLocation[]
}

const routes: Route[] = [
    {
        id: 1,
        name: 'Route X',
        locations: [
            { tiploc: 'SHENFMS' },
            { tiploc: 'SHENFUL' },
            { tiploc: 'SHENFLD' },
            { tiploc: 'SHENLEJ' },
            { tiploc: 'BRTWOOD' },
            { tiploc: 'HRLDWOD' },
            { tiploc: 'GIDEPKJ' },
            { tiploc: 'GIDEPKM' },
            { tiploc: 'GIDECES' },
            { tiploc: 'GIDEPKS' },
            { tiploc: 'GIDEAPK' },
            { tiploc: 'ROMFORD' },
            { tiploc: 'ROMF107' },
            { tiploc: 'ROMFSDG' },
            { tiploc: 'CHDWHTT' },
            { tiploc: 'CHDWLHT' },
            { tiploc: 'GODMAYS' },
            { tiploc: 'SVNKNGS' },
            { tiploc: 'ILFEMUD' },
            { tiploc: 'ILFELEJ' },
            { tiploc: 'ILFORD' },
            { tiploc: 'ILFE341' },
            { tiploc: 'ALDBUCS' },
            { tiploc: 'ILFELWJ' },
            { tiploc: 'ALDB337' },
            { tiploc: 'MANRPKJ' },
            { tiploc: 'MANRPK' },
            { tiploc: 'ILFE336' },
            { tiploc: 'FRSTGTJ' },
            { tiploc: 'FRSTGT' },
            { tiploc: 'MRYLAND' },
            { tiploc: 'ABWDXRS' },
            { tiploc: 'ABWDXR' },
            { tiploc: 'PLMSEJ' },
            { tiploc: 'PLMSXCR' },
            { tiploc: 'PLMSRS' },
            { tiploc: 'WOLWXR' },
            { tiploc: 'CUSTEBT' },
            { tiploc: 'CUSTMHS' },
            { tiploc: 'CUSTWBT' },
            { tiploc: 'CANWHRF' },
            { tiploc: 'TMPLMEJ' },
            { tiploc: 'STFD295' },
            { tiploc: 'STFD' },
            { tiploc: 'STFD261' },
            { tiploc: 'CARPSJN' },
            { tiploc: 'ZZZPUDG' },
            { tiploc: 'PUDGMLL' },
            { tiploc: 'SPNY205' },
            { tiploc: 'SPNYGNJ' },
            { tiploc: 'WCHAPXR' },
            { tiploc: 'WCHAVRD' },
            { tiploc: 'STFD251' },
            { tiploc: 'BOWJ' },
            { tiploc: 'GASFCTJ' },
            { tiploc: 'GASFLP' },
            { tiploc: 'BTHNLGR' },
            { tiploc: 'WHELSTJ' },
            { tiploc: 'LIVST' },
            { tiploc: 'LIVSTLL' },
            { tiploc: 'FRNDXR' },
            { tiploc: 'FRNDFST' },
            { tiploc: 'TOTCFST' },
            { tiploc: 'TOTCTRD' },
            { tiploc: 'BONDST' },
            { tiploc: 'PADTLL' },
            { tiploc: 'PADTON' },
            { tiploc: 'WBRNPKS' },
            { tiploc: 'ROYAOJN' },
            { tiploc: 'PRTOBJP' },
            { tiploc: 'LDBRKJ' },
            { tiploc: 'OLDOXRS' },
            { tiploc: 'OLDOENG' },
            { tiploc: 'OLDOXBL' },
            { tiploc: 'OLDOKCE' },
            { tiploc: 'OLDOXAR' },
            { tiploc: 'OLDOXDP' },
            { tiploc: 'OLDOKCW' },
            { tiploc: 'FRIARSJ' },
            { tiploc: 'ZZZACTE' },
            { tiploc: 'ACTONML' },
            { tiploc: 'ACTONW' },
            { tiploc: 'EALINGB' },
            { tiploc: 'WEALING' },
            { tiploc: 'WEALJN' },
            { tiploc: 'ZZZHAN' },
            { tiploc: 'HANWELL' },
            { tiploc: 'ZZZHBJN' },
            { tiploc: 'HANWBLP' },
            { tiploc: 'STHALEJ' },
            { tiploc: 'STHALL' },
            { tiploc: 'STHALWJ' },
            { tiploc: 'HAYEHLP' },
            { tiploc: 'HAYESAH' },
            { tiploc: 'HTRWAJN' },
            { tiploc: 'DWLEYLP' },
            { tiploc: 'STKYJN' },
            { tiploc: 'HTRWTJN' },
            { tiploc: 'HTRWAPT' },
            { tiploc: 'HTRWTM4' },
            { tiploc: 'HTRWTM5' },
            { tiploc: 'WDRYTON' },
            { tiploc: 'ZZZNPT' },
            { tiploc: 'WDRYJN' },
            { tiploc: 'WDRYUGL' },
            { tiploc: 'IVER' },
            { tiploc: 'LANGREC' },
            { tiploc: 'LANGLEY' },
            { tiploc: 'DOLPHNJ' },
            { tiploc: 'SLOUGH' },
            { tiploc: 'SLOUGHY' },
            { tiploc: 'SLOUGHW' },
            { tiploc: 'ZZZFRD' },
            { tiploc: 'BNHAM' },
            { tiploc: 'TAPLOW' },
            { tiploc: 'MDNHDE' },
            { tiploc: 'MDNHEAD' },
            { tiploc: 'MDNHDRS' },
            { tiploc: 'MDNHDCS' },
            { tiploc: 'RUSCOMB' },
            { tiploc: 'TWYFORD' },
            { tiploc: 'TWYF632' },
            { tiploc: 'TWYFDW' },
            { tiploc: 'RDNGKBL' },
            { tiploc: 'RDNGKBJ' },
            { tiploc: 'RDNGNJN' },
            { tiploc: 'RDNGSTJ' },
            { tiploc: 'ZZZREJN' },
            { tiploc: 'RDNGSTN' },
        ],
    },
]

export default routes

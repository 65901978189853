import React from 'react'
import { Box, Button, Grid } from '@mui/material'
import TrainLineupSlider from './TrainLineupSlider'
import { NavigateBefore, NavigateNext } from '@mui/icons-material'

export type TrainsPage = {
    startTime: string
}

type TrainLineupPagerProps = {
    defaultValue: number
    pages: TrainsPage[]
    onChange: (page: number) => void
}

const TrainLineupPager = ({
    defaultValue,
    pages,
    onChange,
}: TrainLineupPagerProps) => {
    const handlePageChange = (page: number) => {
        onChange(page)
    }

    const handleNextPage = () => {
        onChange(defaultValue + 1)
    }

    const handlePrevPage = () => {
        onChange(defaultValue - 1)
    }

    const firstPage = defaultValue === 0
    const lastPage = defaultValue === pages.length - 1

    return (
        <Box>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs="auto">
                    <Button
                        onClick={handlePrevPage}
                        disabled={firstPage}
                        variant={'contained'}
                    >
                        <NavigateBefore />
                    </Button>
                </Grid>
                <Grid item xs>
                    <TrainLineupSlider
                        defaultValue={defaultValue}
                        pages={pages}
                        onChange={handlePageChange}
                    />
                </Grid>
                <Grid item xs="auto">
                    <Button
                        onClick={handleNextPage}
                        disabled={lastPage}
                        variant={'contained'}
                    >
                        <NavigateNext />
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}

export default TrainLineupPager

import { TableCell, Tooltip } from '@mui/material'
import React from 'react'
import { styled } from '@mui/material/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LocationVisit } from '../../../../../services/dst-api'
import { TableCellProps } from '@mui/material/TableCell/TableCell'
import { UseTrainJourneyOperationResult } from '../../../hooks/useTrainJourneyOperation'
import { ActiveVisitManager } from '../../../hooks/useActiveVisit'

type JourneyOperationTableCellProps = {
    enabled: boolean
    addition: boolean
    subtraction: boolean
    colourScheme: JourneyOperationColourScheme
}

const JourneyOperationTableCell = styled(TableCell, {
    shouldForwardProp: (prop) =>
        prop !== 'enabled' &&
        prop !== 'addition' &&
        prop !== 'subtraction' &&
        prop !== 'colourScheme',
})<JourneyOperationTableCellProps>(
    ({ enabled, addition, subtraction, colourScheme }) => ({
        color: subtraction
            ? colourScheme.subtraction
            : enabled || addition
            ? colourScheme.enabled
            : colourScheme.disabled,
        opacity: addition ? 0.3 : 1,
        ':hover': {
            color: colourScheme.hover,
        },
        cursor: 'pointer',
    })
)

export type JourneyOperationColourScheme = {
    enabled: string
    disabled: string
    subtraction: string
    hover: string
}

type JourneyOperationCellProps = {
    onSubmit: () => void
    activeVisitManager: ActiveVisitManager
    visit: LocationVisit
    operation: UseTrainJourneyOperationResult
    colourScheme: JourneyOperationColourScheme
} & TableCellProps

const JourneyOperationCell = ({
    visit,
    operation,
    activeVisitManager,
    colourScheme,
    onSubmit,
    ...props
}: JourneyOperationCellProps) => {
    const enabled = operation.isEnabled(visit)

    if (operation.isSupported(visit)) {
        return <TableCell></TableCell>
    }

    const activeVisit = activeVisitManager.activeVisit

    return (
        <Tooltip title={operation.getDescription(visit)} disableInteractive>
            <JourneyOperationTableCell
                enabled={enabled}
                addition={operation.submitWillEnable(visit, activeVisit)}
                subtraction={operation.submitWillDisable(visit, activeVisit)}
                align="center"
                onClick={onSubmit}
                colourScheme={colourScheme}
                onMouseEnter={() => activeVisitManager.update(visit)}
                onMouseLeave={() => activeVisitManager.clear()}
                {...props}
            >
                <FontAwesomeIcon icon={operation.icon} />
            </JourneyOperationTableCell>
        </Tooltip>
    )
}

export default JourneyOperationCell

import BaseInformationBox, {
    BaseInformationBoxProps,
} from './BaseInformationBox'
import { Typography } from '@mui/material'
import useTheme from '@mui/material/styles/useTheme'

type ErrorBoxProps = Omit<BaseInformationBoxProps, 'title'>

const ErrorBox = ({ message, children, ...rest }: ErrorBoxProps) => {
    const theme = useTheme()

    return (
        <BaseInformationBox
            title={'Oops! An Error Occurred'}
            titleColor={theme.palette.error}
            subtitle={message}
            {...rest}
        >
            <Typography>
                Something is broken. Please let us know what you were doing when
                this error occurred. We will fix it as soon as possible. Sorry
                for any inconvenience caused.
            </Typography>
            {children}
        </BaseInformationBox>
    )
}

export default ErrorBox

import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import {
    Box,
    Button,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    IconButton,
    SvgIcon,
    Tooltip,
    Typography,
} from '@mui/material'
import {
    Simulation,
    SimulationStatus,
} from '../../../../../../../services/dst-api'
import { Link, Link as RouterLink, useNavigate } from 'react-router-dom'
import SimulationStatusChip from './SimulationStatusChip'
import {
    Edit as EditIcon,
    Repeat as RepeatIcon,
    Trash as TrashIcon,
} from 'react-feather'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import CancelIcon from '@mui/icons-material/Cancel'
import React from 'react'

const SimulationCardContainer = styled(Card)(() => ({
    width: '100%',
    height: 400,
    display: 'flex',
    flexDirection: 'column',
}))

const SimulationCardActionArea = styled(CardActionArea)(() => ({
    flexGrow: 1,
    alignItems: 'stretch',
    flexDirection: 'column',
}))

const SimulationCardMedia = styled(CardMedia)(() => ({
    objectFit: 'fill',
    height: '140px',
}))

const TopBox = styled(Box)(() => ({
    backgroundColor: '#3f51b522',
}))

const FlexGrowSpacer = styled('div')(() => ({
    flexGrow: 1,
}))

const SimulationIdentifier = styled('div')(() => ({
    opacity: '0.3',
}))

const BannerRight = styled('div')(({ theme }) => ({
    float: 'right',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    height: '140px',
    padding: theme.spacing(1),
}))

type SimulationCardProps = {
    simulation: Simulation
    onSimulationDelete: (simulationId: number) => void
    onSimulationExecute: (simulationId: number) => void
    onSimulationCancel: (simulationId: number) => void
    onSimulationResume: (simulationId: number) => void
    onSimulationRerun: (simulationId: number) => void
}

function SimulationCard({
    simulation,
    onSimulationDelete,
    onSimulationExecute,
    onSimulationCancel,
    onSimulationResume,
    onSimulationRerun,
}: SimulationCardProps) {
    const navigate = useNavigate()

    const positiveActionIconColor = 'success'
    const negativeActionIconColor = 'error.light'
    const mode = 'REPLAY'

    function getSimulationImage(simulation: Simulation) {
        return `/images/routes/${simulation.network}.svg`
    }

    function handleCardClick(simulation: Simulation) {
        const editUrl = './simulation/' + simulation.id + '/edit/basic'
        const viewDetailsUrl =
            '/simulation/' + simulation.id + `/overview?mode=${mode}`

        const status = simulation.status
        if (status === SimulationStatus.AWAITING_EXECUTION) {
            navigate(editUrl)
        }
        if (status === SimulationStatus.BREAKPOINT) {
            navigate(editUrl)
        }
        if (status === SimulationStatus.COMPLETE) {
            navigate(viewDetailsUrl)
        }
    }

    return (
        <SimulationCardContainer>
            <SimulationCardActionArea
                onClick={() => handleCardClick(simulation)}
            >
                <TopBox>
                    <BannerRight>
                        <SimulationStatusChip
                            status={simulation.status}
                            statusMessage={simulation.statusMessage}
                        />
                        <FlexGrowSpacer />
                        <SimulationIdentifier>
                            Ref: {simulation.id}
                        </SimulationIdentifier>
                    </BannerRight>

                    <SimulationCardMedia
                        // component="img"
                        // alt="Simulation"
                        image={getSimulationImage(simulation)}
                        title="Simulation"
                    />
                </TopBox>
                <CardContent>
                    <Typography gutterBottom variant="h5">
                        {simulation.title}
                    </Typography>
                    <Typography>{simulation.description}</Typography>
                </CardContent>
            </SimulationCardActionArea>
            <CardActions>
                {/* Show delete button for all sim types apart from 'RUNNING' */}
                {simulation.status !== 'RUNNING' && (
                    <Tooltip title="Delete Simulation">
                        <IconButton
                            onClick={() => onSimulationDelete(simulation.id!!)}
                        >
                            <SvgIcon fontSize="small">
                                <TrashIcon />
                            </SvgIcon>
                        </IconButton>
                    </Tooltip>
                )}

                {/* Show rerun button for 'COMPLETE' and 'ERROR' statuses */}
                {(simulation.status === 'COMPLETE' ||
                    simulation.status === 'FAILED') && (
                    <Tooltip title="Rerun Simulation">
                        <IconButton
                            onClick={() => onSimulationRerun(simulation.id!!)}
                        >
                            <SvgIcon fontSize="small">
                                <RepeatIcon />
                            </SvgIcon>
                        </IconButton>
                    </Tooltip>
                )}

                {/* Show edit button for sim types 'AWAITING_EXECUTION' and 'BREAKPOINT' */}
                {(simulation.status === 'AWAITING_EXECUTION' ||
                    simulation.status === 'BREAKPOINT') && (
                    <Tooltip title="Edit Simulation">
                        <IconButton
                            component={RouterLink}
                            to={'./simulation/' + simulation.id + '/edit/basic'}
                        >
                            <SvgIcon fontSize="small">
                                <EditIcon />
                            </SvgIcon>
                        </IconButton>
                    </Tooltip>
                )}

                <FlexGrowSpacer />

                {/* Show execute button for sim type 'AWAITING_EXECUTION' */}
                {simulation.status === 'AWAITING_EXECUTION' && (
                    <Tooltip title="Get Recommended Actions" placement="left">
                        <IconButton
                            onClick={() => onSimulationExecute(simulation.id!!)}
                        >
                            <PlayArrowIcon
                                fontSize="large"
                                sx={{ color: positiveActionIconColor }}
                            />
                        </IconButton>
                    </Tooltip>
                )}

                {/* Show cancel button for sim type 'QUEUED' */}
                {simulation.status === 'QUEUED' && (
                    <Tooltip title="Cancel Execution" placement="left">
                        <IconButton
                            onClick={() => onSimulationCancel(simulation.id!!)}
                        >
                            <CancelIcon
                                sx={{ color: negativeActionIconColor }}
                            />
                        </IconButton>
                    </Tooltip>
                )}

                {/* Show resume button for sim type 'BREAKPOINT' */}
                {simulation.status === 'BREAKPOINT' && (
                    <Tooltip title="Resume Execution" placement="left">
                        <IconButton
                            onClick={() => onSimulationResume(simulation.id!!)}
                        >
                            <PlayArrowIcon
                                fontSize="large"
                                sx={{ color: positiveActionIconColor }}
                            />
                        </IconButton>
                    </Tooltip>
                )}

                {/* Show view results button for sim type 'COMPLETE' */}
                {simulation.status === 'COMPLETE' && (
                    <Link
                        to={`/simulation/${simulation.id}/overview?mode=${mode}`}
                    >
                        <Button size="small" color="primary">
                            View Results
                        </Button>
                    </Link>
                )}
            </CardActions>
        </SimulationCardContainer>
    )
}
export default SimulationCard

import {
    getTrainGraphSectionByName,
    TrainGraphSection,
} from './trainGraphSections'

type RawTrainGraphSectionIdentifier = {
    name: string
    seq: number
}

type RawTrainGraphLayout = {
    name: string
    description: string
    sections: Array<RawTrainGraphSectionIdentifier> //order is LocationMeters.SectionSequence
}

export type TrainGraphLayout = {
    name: string
    description: string
    sections: Array<TrainGraphSection> //order is LocationMeters.SectionSequence
}

const rawTrainGraphLayouts: RawTrainGraphLayout[] =
    require('./rawTrainGraphLayouts.json').layouts

const layouts = rawTrainGraphLayouts.map((rawLayout) => {
    return {
        ...rawLayout,
        sections: rawLayout.sections
            .sort((a, b) => (a.seq < b.seq ? -1 : 1))
            .map((s) => getTrainGraphSectionByName(s.name)),
    }
})

export const firstTrainGraphLayout: TrainGraphLayout = layouts[0]

import {
    Simulation,
    SimulationType,
    useGetSimulations,
} from '../services/dst-api'
import { useChannel } from '@ably-labs/react-hooks'
import dayjs from 'dayjs'
import { useSearchParams } from 'react-router-dom'
import {
    GenericSort,
    SortDirection,
} from '../components/ListModeSearchBar/GenericSortBox/GenericSortBox'

type UseSimulationsData = {
    isLoading: boolean
    isError: boolean
    simulations: Simulation[]
}

export enum SimulationSortField {
    CREATION_DATE = 'creation_date',
    START_DATE = 'start_date',
    STATUS = 'status',
    TITLE = 'title',
}

const useSimulations = (
    simulationTypes: SimulationType[],
    sort: GenericSort<SimulationSortField>
): UseSimulationsData => {
    const [search] = useSearchParams({
        status: 'Complete',
    })

    const simulationsQuery = useGetSimulations({
        query: {},
    })

    useChannel('simulation', (message) => {
        simulationsQuery.refetch()
        console.log('Simulation message received', message)
    })

    const withDirection = (value: number): number => {
        return value * (sort.direction === SortDirection.ASC ? 1 : -1)
    }

    const sortFunctions: Record<
        SimulationSortField,
        (a: Simulation, b: Simulation) => number
    > = {
        [SimulationSortField.START_DATE]: (a: Simulation, b: Simulation) => {
            const aDate = dayjs(a.date + 'T' + a.startTime)
                .toDate()
                .valueOf()
            const bDate = dayjs(b.date + 'T' + b.startTime)
                .toDate()
                .valueOf()
            return sort.direction === SortDirection.ASC
                ? aDate - bDate
                : bDate - aDate
        },
        [SimulationSortField.TITLE]: (a: Simulation, b: Simulation) =>
            withDirection(a.title.localeCompare(b.title)),
        [SimulationSortField.STATUS]: (a: Simulation, b: Simulation) =>
            withDirection(a.status.localeCompare(b.status)),
        [SimulationSortField.CREATION_DATE]: (a: Simulation, b: Simulation) => {
            const aId = a.id ?? 0
            const bId = b.id ?? 0
            return withDirection(aId < bId ? -1 : 1)
        },
    }
    if (simulationsQuery.isLoading) {
        return {
            simulations: [],
            isError: false,
            isLoading: true,
        }
    }

    if (simulationsQuery.isError) {
        return {
            simulations: [],
            isError: true,
            isLoading: false,
        }
    }

    const simulations = simulationsQuery.data
        .filter((simulation) => {
            return simulationTypes.indexOf(simulation.type) !== -1
        })
        .filter((s) => {
            const query = search.get('query')
            if (!query || query.length === 0) return true
            const searchString = query.toLowerCase()
            return (
                s.title.toLowerCase().includes(searchString) ||
                s.date.toLowerCase().includes(searchString) ||
                s.status.toLowerCase().includes(searchString) ||
                s.description?.toLowerCase().includes(searchString) ||
                s.id?.toString().toLowerCase().includes(searchString) ||
                s.startTime.toLowerCase().includes(searchString) ||
                s.endTime.toLowerCase().includes(searchString) ||
                s.statusMessage?.toLowerCase().includes(searchString)
            )
        })

    return {
        simulations: [...simulations].sort(sortFunctions[sort.field]),
        isError: false,
        isLoading: false,
    }
}

export default useSimulations

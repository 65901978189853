import React from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { Grid } from '../../../../../../services/dst-api'

type AnalysisGridProps = {
    data: Grid
}
const AnalysisGrid = ({ data }: AnalysisGridProps) => {
    const headings = data.columns.map((heading) => {
        return (
            <TableCell align={'center'} key={heading.title}>
                {heading.title}
            </TableCell>
        )
    })

    return (
        <Table size="small">
            <TableHead>
                <TableRow>{headings}</TableRow>
            </TableHead>
            <TableBody>
                {data.data.map((row, index) => (
                    <TableRow key={index}>
                        {row.map((cell, index) => (
                            <TableCell key={index} align={'center'}>
                                {cell}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}

export default AnalysisGrid

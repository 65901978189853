import React from 'react'
import { Grid, Paper } from '@mui/material'
import { styled } from '@mui/material/styles'
import { AnalysisPanel } from './components/AnalysisPanel/AnalysisPanel'
import useSimulationResults from '../../hooks/useSimulationResults'
import InformationBox from '../../../../components/InformationBox/InformationBox'
import LoadingBox from '../../../../components/InformationBox/LoadingBox'
import ErrorBox from '../../../../components/InformationBox/ErrorBox'

const AnalysisSection = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
}))

function SimulationResultsAnalysisView({
    simulationId,
}: {
    simulationId: number
}) {
    const { isLoading, isError, simulation, simulationResults } =
        useSimulationResults(simulationId)

    if (isLoading) {
        return <LoadingBox />
    }

    if (isError) {
        return <ErrorBox message={'Analysis results could not be retrieved.'} />
    }

    if (!simulation) {
        return <ErrorBox message={'Simulation could not be found.'} />
    }

    if (!simulationResults) {
        return (
            <InformationBox
                title={'No Analysis Results'}
                message={
                    'Simulation is in progress - no results have yet been published.'
                }
            />
        )
    }

    if (
        !simulationResults!!.analyses ||
        !simulationResults!!.analyses.tables ||
        simulationResults!!.analyses.tables.length === 0
    ) {
        return (
            <InformationBox
                title={'No Simulation Analyses'}
                message={'Analysis of this simulation is in progress.'}
            />
        )
    }

    const tables = simulationResults.analyses.tables?.map((value, index) => {
        return (
            <Grid item xs={12} key={`table${index}`}>
                <AnalysisSection>
                    <AnalysisPanel data={value} />
                </AnalysisSection>
            </Grid>
        )
    })

    return (
        <Grid container spacing={3}>
            {tables && tables}
        </Grid>
    )
}

export default SimulationResultsAnalysisView

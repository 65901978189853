import { useGetSimulation } from '../services/dst-api'
import { useChannel } from '@ably-labs/react-hooks'

const useSimulation = (simulationId: number) => {
    const simulationQuery = useGetSimulation({
        simulationId: simulationId,
    })
    useChannel('simulation', (message) => {
        simulationQuery.refetch()
    })

    const { data: simulation, isLoading, isFetching, isError } = simulationQuery

    return {
        simulation,
        isLoading: isLoading || isFetching,
        isError,
    }
}

export default useSimulation

import React, { createContext, useContext, useEffect, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { SimulationContext } from '../../Simulation/context/SimulationProvider'
import { parseDateTime } from '../../../utils/timeUtils'

export type SimulationTrainGraphSettings = {
    time: Dayjs
}

export type SimulationTrainGraphSettingsDispatcher = {
    setTime: (time: SimulationTrainGraphSettings['time']) => void
}

// Create two context:
// UserContext: to query the context state
// UserDispatchContext: to mutate the context state
const SimulationTrainGraphSettingsContext = createContext<
    SimulationTrainGraphSettings | undefined
>(undefined)

const SimulationTrainGraphSettingsDispatchContext = createContext<
    SimulationTrainGraphSettingsDispatcher | undefined
>(undefined)

// A "provider" is used to encapsulate only the
// components that needs the state in this context
function SimulationTrainGraphSettingsProvider({
    children,
}: {
    children: React.ReactNode
}) {
    let simulation = useContext(SimulationContext)
    const [time, setTime] = useState<Dayjs>(dayjs())

    useEffect(() => {
        setTime(
            simulation === null
                ? dayjs()
                : parseDateTime(simulation.date, simulation.startTime).add(
                      1,
                      'hour'
                  )
        )
    }, [simulation])

    return (
        <SimulationTrainGraphSettingsContext.Provider value={{ time }}>
            <SimulationTrainGraphSettingsDispatchContext.Provider
                value={{ setTime }}
            >
                {children}
            </SimulationTrainGraphSettingsDispatchContext.Provider>
        </SimulationTrainGraphSettingsContext.Provider>
    )
}

export {
    SimulationTrainGraphSettingsProvider,
    SimulationTrainGraphSettingsContext,
    SimulationTrainGraphSettingsDispatchContext,
}

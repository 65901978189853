import { Slider, SliderProps } from '@mui/material'
import React from 'react'
import BaseTitledField, { TitledFieldProps } from './BaseTitledField'
import { BaseFieldWithChangeHandlerProps } from './BaseField'

export interface SliderFieldProps extends BaseFieldWithChangeHandlerProps {
    value: number
    min: number
    max: number
    titledFieldProps: TitledFieldProps
    sliderProps: Omit<SliderProps, 'id' | 'value' | 'onChange' | 'min' | 'max'>
}

export const SliderField = ({
    id,
    onChange,
    value,
    min,
    max,
    titledFieldProps,
    sliderProps,
}: SliderFieldProps) => {
    return (
        <BaseTitledField {...titledFieldProps}>
            <Slider
                {...sliderProps}
                id={id}
                name={id}
                value={value}
                onChange={onChange}
                min={min}
                max={max}
            />
        </BaseTitledField>
    )
}

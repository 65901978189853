import React from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import {
    LiveSettings,
    useEditLiveSettings,
    useGetLiveSettings,
} from '../../../../services/dst-api'
import { LoadingButton } from '@mui/lab'
import {
    pushErrorNotification,
    pushSuccessNotification,
} from '../../../../utils/notification'
import {
    CommonFormLayout,
    CommonSkeletonForm,
} from '../../../../components/fields/CommonForm'
import NetworkField from '../../../../components/fields/fields/NetworkField'
import SimulationDurationField from '../../../../components/fields/fields/SimulationDurationField'
import PreviousProposedAmendmentsField from '../../../../components/fields/fields/PreviousProposedAmendmentsField'

type LiveSettingsFormViewProps = {
    initialValues: LiveSettings
    onSubmit: (settings: LiveSettings) => void
    isSaving: boolean
}

const LiveSettingsFormView = ({
    initialValues,
    onSubmit,
    isSaving,
}: LiveSettingsFormViewProps) => {
    const validationSchema = yup.object({
        durationInHours: yup.number().required('Duration is required'),
        network: yup.string().required('Network is required'),
        minorPerturbations: yup.boolean(),
    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            onSubmit(values)
            // toast.success("Settings updated successfully", {
            //     position: "top-right",
            // });
        },
    })

    const handleDurationChange = (event: any) => {
        formik.setFieldValue('durationInHours', event.target.value)
    }

    return (
        <form noValidate onSubmit={formik.handleSubmit}>
            <CommonFormLayout title={'Settings'}>
                <NetworkField
                    id="network"
                    value={formik.values.network}
                    onChange={formik.handleChange}
                />
                <SimulationDurationField
                    id="durationInHours"
                    value={formik.values.durationInHours}
                    onChange={handleDurationChange}
                />
                <PreviousProposedAmendmentsField
                    id="minorPerturbations"
                    value={formik.values.minorPerturbations}
                    onChange={formik.handleChange}
                />
                <LoadingButton
                    loading={isSaving}
                    loadingPosition="start"
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                >
                    Save Settings
                </LoadingButton>
            </CommonFormLayout>
        </form>
    )
}

const LiveSettingsForm = () => {
    const { data, isInitialLoading } = useGetLiveSettings()
    const { mutate, isLoading: isSaving } = useEditLiveSettings({
        mutation: {
            onSuccess: () => {
                pushSuccessNotification('Settings updated successfully')
            },
            onError: (error) => {
                pushErrorNotification(`Settings update failed: ${error}`)
            },
        },
    })

    const updateSettings = (settings: LiveSettings) => {
        mutate({
            data: {
                ...settings,
            },
        })
    }
    if (isInitialLoading) {
        // if (true) {
        return <CommonSkeletonForm title={'Settings'} />
    } else {
        return (
            <LiveSettingsFormView
                initialValues={data!!}
                onSubmit={updateSettings}
                isSaving={isSaving}
            />
        )
    }
}

export default LiveSettingsForm

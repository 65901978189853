import React from 'react'
import { Chip, Tooltip } from '@mui/material'

type SimulationStatusChipProps = {
    status:
        | 'INITIALISING'
        | 'AWAITING_EXECUTION'
        | 'QUEUED'
        | 'RUNNING'
        | 'BREAKPOINT'
        | 'COMPLETE'
        | 'FAILED'
    statusMessage?: String
}
function SimulationStatusChip({
    status,
    statusMessage,
}: SimulationStatusChipProps) {
    function getSimulationStatusDescription() {
        if (status === 'INITIALISING') {
            return 'Initialising'
        }
        if (status === 'AWAITING_EXECUTION') {
            return 'Created'
        }
        if (status === 'QUEUED') {
            return 'Queued'
        }
        if (status === 'RUNNING') {
            return 'Running'
        }
        if (status === 'BREAKPOINT') {
            return 'Breakpoint'
        }
        if (status === 'COMPLETE') {
            return 'Complete'
        }
        if (status === 'FAILED') {
            return 'Failed'
        }
        return 'Status Unknown'
    }

    function getSimulationStatusColor():
        | 'default'
        | 'primary'
        | 'secondary'
        | 'error'
        | 'info'
        | 'success'
        | 'warning' {
        if (status === 'INITIALISING') {
            return 'warning'
        }
        if (status === 'AWAITING_EXECUTION') {
            return 'info'
        }
        if (status === 'QUEUED') {
            return 'warning'
        }
        if (status === 'RUNNING') {
            return 'success'
        }
        if (status === 'BREAKPOINT') {
            return 'error'
        }
        if (status === 'COMPLETE') {
            return 'success'
        }
        if (status === 'FAILED') {
            return 'error'
        }
        return 'error'
    }

    return (
        <Tooltip title={statusMessage} hidden={statusMessage === undefined}>
            <Chip
                size="small"
                color={getSimulationStatusColor()}
                label={getSimulationStatusDescription()}
            />
        </Tooltip>
    )
}

export default SimulationStatusChip

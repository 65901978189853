import { useParams } from 'react-router-dom'
import ErrorBox from '../../../../components/InformationBox/ErrorBox'
import React from 'react'
import Page from '../../../../components/Page/Page'
import SimulationIncidentsView from '../ReadonlySimulationIncidentsView/ReadonlySimulationIncidentsView'

const SimulationIncidentsPage = () => {
    const { simulationId } = useParams()
    if (!simulationId)
        return (
            <ErrorBox
                message={'Simulation incidents could not be displayed.'}
            />
        )
    return (
        <Page
            headerProps={{
                title: 'Simulation Incidents',
            }}
        >
            <SimulationIncidentsView simulationId={parseInt(simulationId)} />
        </Page>
    )
}

export default SimulationIncidentsPage

import { Train, useGetSimulationTrains } from '../../../services/dst-api'
import { useChannel } from '@ably-labs/react-hooks'
import dayjs from 'dayjs'
import { useSearchParams } from 'react-router-dom'
import {
    GenericSort,
    SortDirection,
} from '../../../components/ListModeSearchBar/GenericSortBox/GenericSortBox'
import { TrainsListSortField } from '../../../types/TrainsListSortField'

type UseTrainsData = {
    isLoading: boolean
    isError: boolean
    trains: Train[]
}

const useTrains = (
    sort: GenericSort<TrainsListSortField>,
    simulationId: number
): UseTrainsData => {
    const [search] = useSearchParams({
        status: 'Complete',
    })

    const trainsQuery = useGetSimulationTrains(simulationId)

    useChannel('train', (message) => {
        trainsQuery.refetch()
        console.log('Train message received', message)
    })

    const withDirection = (value: number): number => {
        return value * (sort.direction === SortDirection.ASC ? 1 : -1)
    }

    const sortFunctions: Record<
        TrainsListSortField,
        (a: Train, b: Train) => number
    > = {
        [TrainsListSortField.ORIGIN_DEPARTURE]: (a: Train, b: Train) => {
            const aDate = dayjs(a.originDepartureInstant).toDate().valueOf()
            const bDate = dayjs(b.originDepartureInstant).toDate().valueOf()
            return sort.direction === SortDirection.ASC
                ? aDate - bDate
                : bDate - aDate
        },
        [TrainsListSortField.HEADCODE]: (a: Train, b: Train) =>
            withDirection(a.headcode.localeCompare(b.headcode)),
        [TrainsListSortField.ATOC]: (a: Train, b: Train) =>
            withDirection(a.atoc.localeCompare(b.atoc)),
    }
    if (trainsQuery.isLoading) {
        return {
            trains: [],
            isError: false,
            isLoading: true,
        }
    }

    if (trainsQuery.isError) {
        return {
            trains: [],
            isError: true,
            isLoading: false,
        }
    }

    const trains = trainsQuery.data.filter((t) => {
        const query = search.get('query')
        if (!query || query.length === 0) return true
        const searchString = query.toLowerCase()
        return (
            t.atoc.toLowerCase().includes(searchString) ||
            t.headcode.toLowerCase().includes(searchString) ||
            t.originDepartureInstant.toLowerCase().includes(searchString) ||
            t.destinationArrivalInstant.toLowerCase().includes(searchString) ||
            t.origin.tiploc
                .toLowerCase()
                .toLowerCase()
                .includes(searchString) ||
            t.destination.tiploc.toLowerCase().includes(searchString) ||
            t.uid.toLowerCase().includes(searchString)
        )
    })

    return {
        trains: [...trains].sort(sortFunctions[sort.field]),
        isError: false,
        isLoading: false,
    }
}

export default useTrains

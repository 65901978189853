import {
    blue,
    blueGrey,
    green,
    grey,
    indigo,
    orange,
    red,
    teal,
} from '@mui/material/colors'

const white = '#FFFFFF'
const black = '#000000'

const customPalette = {
    primary: {
        dark: indigo[800],
        main: indigo[400],
        light: indigo[200],
        contrastText: white,
    },
    secondary: {
        dark: teal['A700'],
        main: teal['A400'],
        light: teal['A100'],
        contrastText: black,
    },
    success: {
        contrastText: white,
        dark: green[900],
        main: green[600],
        light: green[400],
    },
    info: {
        contrastText: white,
        dark: blue[900],
        main: blue[600],
        light: blue[400],
    },
    warning: {
        contrastText: white,
        dark: orange[900],
        main: orange[600],
        light: orange[400],
    },
    error: {
        contrastText: white,
        dark: red[900],
        main: red[600],
        light: red[400],
    },
    text: {
        primary: blueGrey[900],
        secondary: blueGrey[600],
        link: blue[600],
    },
    background: {
        default: '#F4F6F8',
        paper: white,
    },
    icon: blueGrey[600],
    divider: grey[200],
    type: 'light',
}

export default customPalette

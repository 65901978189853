import React from 'react'
import TextField, { TextFieldProps } from './base/TextField'

type IncidentDescriptionFieldProps = Omit<TextFieldProps, 'label'>

const IncidentDescriptionField = (props: IncidentDescriptionFieldProps) => {
    return <TextField multiline={true} label="Description" {...props} />
}

export default IncidentDescriptionField

import { Incident } from '../../../../../services/dst-api'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { LoadingButton } from '@mui/lab'
import { CommonFormLayout } from '../../../../../components/fields/CommonForm'
import IncidentIdField from '../../../../../components/fields/fields/IncidentIdField'
import React from 'react'
import LocationField from '../../../../../components/fields/fields/LocationField'
import IncidentTypeField from '../../../../../components/fields/fields/IncidentTypeField'
import TimePickerField from '../../../../../components/fields/fields/base/TimePickerField'
import { Grid } from '@mui/material'
import IncidentDescriptionField from '../../../../../components/fields/fields/IncidentDescriptionField'
import IncidentTitleField from '../../../../../components/fields/fields/IncidentTitleField'
import SectionLineField from '../../../../../components/fields/fields/SectionLineField'

type ManageIncidentFormProps = {
    initialValues: Omit<Incident, 'id'>
    onSubmit: (settings: Omit<Incident, 'id'>) => void
    isSaving: boolean
}

const ManageIncidentForm = ({
    initialValues,
    onSubmit,
    isSaving,
}: ManageIncidentFormProps) => {
    const validationSchema = yup.object({
        incidentId: yup
            .string()
            //TODO put this constraint back?
            // .length(6, "The identifier for an incident should be exactly 6 characters")
            .required('Incident ID is required'),
        fromLocation: yup
            .string()
            .max(50)
            .required('From Location is required'),
        toLocation: yup.string().max(50),
        line: yup.string(),
        // .length(6, "The name of a line should be exactly 6 characters"),
        type: yup.string().max(50),
        startTime: yup
            .string()
            .required('Start Time is required')
            .matches(
                /^([0-1]?[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?$/,
                'Incorrect time format'
            ),
        endTime: yup
            .string()
            .required('End Time is required')
            .matches(
                /^([0-1]?[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?/,
                'Incorrect time format'
            ),
        description: yup.string().max(250),
        title: yup.string().max(50).required('Incident Title is required'),
    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            onSubmit(values)
        },
    })

    const sectionIncident =
        formik.values.fromLocation !== undefined &&
        formik.values.fromLocation.length > 0 &&
        formik.values.toLocation !== undefined &&
        formik.values.toLocation.length > 0 &&
        formik.values.fromLocation !== formik.values.toLocation

    return (
        <form noValidate onSubmit={formik.handleSubmit}>
            <CommonFormLayout>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <IncidentIdField
                            id="incidentId"
                            value={formik.values.incidentId}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.incidentId &&
                                Boolean(formik.errors.incidentId)
                            }
                            helperText={
                                formik.touched.incidentId &&
                                formik.errors.incidentId
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <IncidentTitleField
                            id="title"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.title &&
                                Boolean(formik.errors.title)
                            }
                            helperText={
                                formik.touched.title && formik.errors.title
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <IncidentDescriptionField
                            id="description"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.description &&
                                Boolean(formik.errors.description)
                            }
                            helperText={
                                formik.touched.description &&
                                formik.errors.description
                            }
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <LocationField
                            id="fromLocation"
                            label={'From Location'}
                            value={formik.values.fromLocation}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.fromLocation &&
                                Boolean(formik.errors.fromLocation)
                            }
                            helperText={
                                formik.touched.fromLocation &&
                                formik.errors.fromLocation
                            }
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <LocationField
                            id="toLocation"
                            label={'To Location'}
                            value={formik.values.toLocation}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.toLocation &&
                                Boolean(formik.errors.toLocation)
                            }
                            helperText={
                                formik.touched.toLocation &&
                                formik.errors.toLocation
                            }
                        />
                    </Grid>
                    {sectionIncident && (
                        <Grid item xs={12}>
                            <SectionLineField
                                id="line"
                                value={formik.values.line}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.line &&
                                    Boolean(formik.errors.line)
                                }
                                helperText={
                                    formik.touched.line && formik.errors.line
                                }
                                fromLocation={formik.values.fromLocation}
                                toLocation={formik.values.toLocation!!}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <IncidentTypeField
                            id="type"
                            value={formik.values.type}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.type &&
                                Boolean(formik.errors.type)
                            }
                            helperText={
                                formik.touched.type && formik.errors.type
                            }
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TimePickerField
                            id="startTime"
                            label={'Start Time'}
                            value={formik.values.startTime}
                            onChange={(value) =>
                                formik.setFieldValue('startTime', value)
                            }
                            error={
                                formik.touched.startTime &&
                                Boolean(formik.errors.startTime)
                            }
                            helperText={
                                formik.touched.startTime &&
                                formik.errors.startTime
                            }
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TimePickerField
                            id="endTime"
                            label={'End Time'}
                            value={formik.values.endTime}
                            onChange={(value) =>
                                formik.setFieldValue('endTime', value)
                            }
                            error={
                                formik.touched.endTime &&
                                Boolean(formik.errors.endTime)
                            }
                            helperText={
                                formik.touched.endTime && formik.errors.endTime
                            }
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <LoadingButton
                            loading={isSaving}
                            color="primary"
                            variant="contained"
                            fullWidth
                            type="submit"
                        >
                            Save
                        </LoadingButton>
                    </Grid>
                </Grid>
            </CommonFormLayout>
        </form>
    )
}

export default ManageIncidentForm

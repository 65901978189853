import routes, { Route } from '../../../data/routes'

export type RouteManager = {
    route: Route
}

const useRoute = (routeId: number): RouteManager => {
    const route = routes.find((route) => route.id === routeId)!!
    return {
        route: route,
    }
}

export default useRoute

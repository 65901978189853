import { useParams } from 'react-router-dom'
import ErrorBox from '../../../components/InformationBox/ErrorBox'
import React from 'react'
import SimulationResultsView from './SimulationResultsView'
import Page from '../../../components/Page/Page'

const SimulationResultsPage = () => {
    const { simulationId } = useParams()
    if (!simulationId)
        return (
            <ErrorBox message={'Simulation results could not be displayed.'} />
        )
    return (
        <Page
            headerProps={{
                title: 'Simulation Results',
            }}
        >
            <SimulationResultsView simulationId={parseInt(simulationId)} />
        </Page>
    )
}

export default SimulationResultsPage

import React from 'react'
import { ToolbarView, ToolbarViewProps } from './Toolbars/ToolbarView'
import {
    SearchAndSortToolbar,
    SearchAndSortToolbarProps,
} from './Toolbars/SearchAndSortToolbar'
import { styled } from '@mui/material/styles'
import { DisplayButtonGenericType } from './DisplayButtonsView/DisplayButtonsView'
import { SortFieldGenericType } from './GenericSortBox/GenericSortBox'

const ListHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
}))

type ListModeSearchBarProps<
    T1 extends DisplayButtonGenericType,
    T2 extends SortFieldGenericType
> = {} & ToolbarViewProps<T1> & SearchAndSortToolbarProps<T2>

export function ListModeSearchBar<
    T1 extends DisplayButtonGenericType,
    T2 extends SortFieldGenericType
>({
    itemCount,
    entityDescription,
    modeProps,
    sortProps,
}: ListModeSearchBarProps<T1, T2>) {
    return (
        <>
            <ListHeader>
                <ToolbarView
                    itemCount={itemCount}
                    modeProps={modeProps}
                    entityDescription={entityDescription}
                />
                <SearchAndSortToolbar
                    entityDescription={entityDescription}
                    sortProps={sortProps}
                />
            </ListHeader>
        </>
    )
}

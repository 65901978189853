import { Integration, INTEGRATIONS } from '../../../../../data/integrations'

const useIntegrations = () => {
    const integrations = INTEGRATIONS

    const toggleIntegration = (id: Integration['id']) => {
        console.log(`Toggled integration ${id}`)
    }

    return {
        integrations: integrations,
        toggleIntegration: toggleIntegration,
    }
}

export default useIntegrations

import { TableCellProps } from '@mui/material/TableCell/TableCell'
import { TableCell, Typography } from '@mui/material'
import React from 'react'
import { formatTime } from '../../utils/timeUtils'
import { LocationVisit } from '../../services/dst-api'
import { styled } from '@mui/material/styles'

type TimeTableCellProps = {
    visit: LocationVisit | null
} & TableCellProps

const StyledCellText = styled(Typography, {
    shouldForwardProp: (propName: PropertyKey) => propName !== 'stop',
})<{ stop: boolean }>(({ stop }) => ({
    ...(stop && {
        fontWeight: 'bold',
    }),
}))

const StyledTableCell = styled(TableCell, {
    shouldForwardProp: (propName: PropertyKey) => propName !== 'cancelled',
})<{ cancelled: boolean }>(({ cancelled, theme }) => ({
    ...(cancelled && {
        backgroundColor: theme.palette.error.light,
    }),
}))

const VisitCell = ({ visit, ...props }: TimeTableCellProps) => {
    const time = visit
        ? visit.departureInstant
            ? formatTime(visit.departureInstant, true)
            : formatTime(visit.arrivalInstant!!, true)
        : ''
    return (
        <StyledTableCell
            align={'center'}
            cancelled={visit?.state.cancelled ?? false}
            {...props}
        >
            <StyledCellText stop={visit?.stop ?? false}>{time}</StyledCellText>
        </StyledTableCell>
    )
}

export default VisitCell

import { Typography } from '@mui/material'
import {
    DisplayButtonGenericType,
    DisplayButtonProps,
    DisplayButtonsView,
} from '../DisplayButtonsView/DisplayButtonsView'
import React from 'react'
import { ToolbarGroup } from './ToolbarGroup'

function ItemCountDescription({
    count,
    entityDescription,
}: {
    count: number
    entityDescription: string
}) {
    const getDescription = () => {
        switch (count) {
            case 0:
                return `No ${entityDescription} found`
            case 1:
                return `1 ${entityDescription} found`
            default:
                return `${count} ${entityDescription} found`
        }
    }

    return <Typography>{getDescription()}</Typography>
}

export type ToolbarViewProps<T extends DisplayButtonGenericType> = {
    itemCount: number
    modeProps: DisplayButtonProps<T>
    entityDescription: string
}

export function ToolbarView<T extends DisplayButtonGenericType>({
    modeProps,
    itemCount,
    entityDescription,
}: ToolbarViewProps<T>) {
    return (
        <>
            <ToolbarGroup>
                <DisplayButtonsView {...modeProps} />
                <ItemCountDescription
                    count={itemCount}
                    entityDescription={entityDescription}
                />
            </ToolbarGroup>
        </>
    )
}

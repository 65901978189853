import React from 'react'
import { MenuItem } from '@mui/material'
import { SelectField, SelectFieldProps } from './base/SelectField'
import { useGetLocations } from '../../../services/dst-api'

type LocationFieldProps = {
    label: 'Location' | 'From Location' | 'To Location'
} & Omit<SelectFieldProps, 'children'>

const LocationField = ({
    id,
    label,
    onChange,
    value,
    ...rest
}: LocationFieldProps) => {
    const { data: locations, isLoading } = useGetLocations()

    const listItems =
        locations?.map((location) => {
            return (
                <MenuItem key={location.id} value={location.tiploc}>
                    {location.name}
                </MenuItem>
            )
        }) ?? []

    return (
        <SelectField
            id={id}
            value={value}
            onChange={onChange}
            label={label}
            disabled={isLoading}
            {...rest}
        >
            {listItems}
        </SelectField>
    )
}

export default LocationField

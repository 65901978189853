type TempLocation = {
    shortDescription: string
    mediumDescription: string
    longDescription: string
}

const locationDescriptions: Record<string, TempLocation> = {
    ABWDXR: {
        mediumDescription: 'ABBEYWDXR',
        shortDescription: 'ABX',
        longDescription: 'Abbey Wood (Crossrail)',
    },
    ABWDXRS: {
        mediumDescription: 'ABYWDXRSD',
        shortDescription: 'ABW',
        longDescription: 'Abbey Wood Crossrail Siding',
    },
    ACTONML: {
        mediumDescription: 'ACTON ML ',
        shortDescription: 'AML',
        longDescription: 'Acton Main Line',
    },
    ACTONTC: {
        mediumDescription: 'ACTONYARD',
        shortDescription: 'XAX',
        longDescription: 'Acton T.C.',
    },
    ACTONW: {
        mediumDescription: 'ACTONWEST',
        shortDescription: 'ACT',
        longDescription: 'Acton West',
    },
    ACWLJN: {
        mediumDescription: 'ACTONWLSJ ',
        shortDescription: 'ACW',
        longDescription: 'Acton Wells Jn',
    },
    ALDB337: {
        mediumDescription: 'ILFORDUCS',
        shortDescription: 'ALD',
        longDescription: 'Aldersbrook Signal 337/354',
    },
    ALDBUCS: {
        mediumDescription: 'ILFORDUCS',
        shortDescription: 'XAK',
        longDescription: 'Aldersbrook Up C.H.S.',
    },
    BNHAM: {
        mediumDescription: 'BURNHAM ',
        shortDescription: 'BNM',
        longDescription: 'Burnham',
    },
    BONDST: {
        mediumDescription: 'BONDSTXR',
        shortDescription: 'BDS',
        longDescription: 'Bond Street',
    },
    BOWD: {
        mediumDescription: 'BOW DEPOT',
        shortDescription: 'BOW',
        longDescription: 'Bow Depot',
    },
    BOWJ: {
        mediumDescription: 'BOW JUNCT ',
        shortDescription: 'XOW',
        longDescription: 'Bow Junction',
    },
    BRTWOOD: {
        mediumDescription: 'BRENTWOOD',
        shortDescription: 'BRE',
        longDescription: 'Brentwood',
    },
    BTHNLGR: {
        mediumDescription: 'BETHNALGN ',
        shortDescription: 'BET',
        longDescription: 'Bethnal Green',
    },
    CAMHTH: {
        mediumDescription: 'CAMBHEATH ',
        shortDescription: 'CBH',
        longDescription: 'Cambridge Heath',
    },
    CANWHRF: {
        mediumDescription: 'CANRYWFXR',
        shortDescription: 'CWX',
        longDescription: 'Canary Wharf Xrail',
    },
    CARPNRC: {
        mediumDescription: 'CRPNTRDCV',
        shortDescription: 'CAR',
        longDescription: 'Carpenters Road Curve',
    },
    CARPSJN: {
        mediumDescription: 'CARPTRDSJ',
        shortDescription: 'CAR',
        longDescription: 'Carpenters Rd South Jn',
    },
    CHDWHTT: {
        mediumDescription: 'CHADWLHTB',
        shortDescription: 'CHD',
        longDescription: 'Chadwell Heath Turnback',
    },
    CHDWLHT: {
        mediumDescription: 'CHADWLHTH',
        shortDescription: 'CTH',
        longDescription: 'Chadwell Heath',
    },
    CHNELSJ: {
        mediumDescription: 'CHNNLSE ',
        shortDescription: 'CHN',
        longDescription: 'Channelsea Jn',
    },
    CNBK503: {
        mediumDescription: 'CNBK503',
        shortDescription: 'CNB',
        longDescription: 'Colnbrook Signal T3503',
    },
    CUSTEBT: {
        mediumDescription: 'CUSTEBT',
        shortDescription: 'CUS',
        longDescription: 'Custom House East Crossover',
    },
    CUSTMHS: {
        mediumDescription: 'CUSTOMHSE',
        shortDescription: 'CUS',
        longDescription: 'Custom House',
    },
    CUSTWBT: {
        mediumDescription: 'CUSTWBT',
        shortDescription: 'CUS',
        longDescription: 'Custom House West Crossover',
    },
    DOLPHNJ: {
        mediumDescription: 'DOLPHIN J',
        shortDescription: 'DOL',
        longDescription: 'Dolphin Jn',
    },
    DRAYGJN: {
        mediumDescription: 'DRAYTNGNJ',
        shortDescription: 'DRA',
        longDescription: 'Drayton Green Junction',
    },
    EALINGB: {
        mediumDescription: 'EALINGBDY',
        shortDescription: 'EAL',
        longDescription: 'Ealing Broadway',
    },
    FRIARSJ: {
        mediumDescription: 'FRIARS JN',
        shortDescription: 'FRI',
        longDescription: 'Friars Jn',
    },
    FRNDFST: {
        mediumDescription: 'FRNDFST',
        shortDescription: 'FRN',
        longDescription: 'Fisher Street East',
    },
    FRNDXR: {
        mediumDescription: 'FARGDONXR',
        shortDescription: 'FDX',
        longDescription: 'Farringdon Crossrail',
    },
    FRSTGT: {
        mediumDescription: 'FORESTGTE',
        shortDescription: 'FOG',
        longDescription: 'Forest Gate',
    },
    FRSTGTJ: {
        mediumDescription: 'FORESTGTJ ',
        shortDescription: 'FRS',
        longDescription: 'Forest Gate Jn',
    },
    FURZEP: {
        mediumDescription: 'FURZE PLT',
        shortDescription: 'FZP',
        longDescription: 'Furze Platt',
    },
    GASFCTJ: {
        mediumDescription: 'GASFACTJN',
        shortDescription: 'GAS',
        longDescription: 'Gas Factory Jn',
    },
    GASFLP: {
        mediumDescription: 'GASFACTLP',
        shortDescription: 'GAS',
        longDescription: 'Gas Factory Loop',
    },
    GIDEAPK: {
        mediumDescription: 'GIDEAPARK ',
        shortDescription: 'GDP',
        longDescription: 'Gidea Park',
    },
    GIDEPKJ: {
        mediumDescription: 'GIDEAPCEJ',
        shortDescription: 'GID',
        longDescription: 'Gidea Park Jn',
    },
    GIDEPKS: {
        mediumDescription: 'GIDEAPKCS ',
        shortDescription: 'GID',
        longDescription: 'Gidea Park C.H.S.',
    },
    GODMAYS: {
        mediumDescription: 'GOODMAYES',
        shortDescription: 'GMY',
        longDescription: 'Goodmayes',
    },
    HANWELL: {
        mediumDescription: 'HANWELL',
        shortDescription: 'HAN',
        longDescription: 'Hanwell',
    },
    HAYESAH: {
        mediumDescription: 'HAYES&HAR',
        shortDescription: 'HAY',
        longDescription: 'Hayes & Harlington',
    },
    HRLDWOD: {
        mediumDescription: 'HAROLDWOD ',
        shortDescription: 'HRO',
        longDescription: 'Harold Wood',
    },
    HTRWAJN: {
        mediumDescription: 'AIRPORTJN',
        shortDescription: 'HTR',
        longDescription: 'Heathrow Airport Jn',
    },
    HTRWAPT: {
        mediumDescription: 'LHR T 2&3',
        shortDescription: 'HXX',
        longDescription: 'Heathrow Terminals 1-2-3',
    },
    HTRWTJN: {
        mediumDescription: 'LHR APT J',
        shortDescription: 'HTR',
        longDescription: 'Heathrow Tunnel Jn.',
    },
    HTRWTM4: {
        mediumDescription: 'LHR TML 4',
        shortDescription: 'HAF',
        longDescription: 'Heathrow Terminal 4',
    },
    HTRWTM5: {
        mediumDescription: 'LHR TML 5',
        shortDescription: 'HWV',
        longDescription: 'Heathrow Terminal 5',
    },
    ILFE336: {
        mediumDescription: 'ILFE336',
        shortDescription: 'ILF',
        longDescription: 'Ilford Signal L336',
    },
    ILFE341: {
        mediumDescription: 'ILFORD SJ',
        shortDescription: 'ILF',
        longDescription: 'Ilford Sig L341',
    },
    ILFELEJ: {
        mediumDescription: 'ILFD LEJN',
        shortDescription: 'ILF',
        longDescription: 'Ilford Lon End Junction',
    },
    ILFEMUD: {
        mediumDescription: 'ILFORDEMD ',
        shortDescription: 'XIF',
        longDescription: 'Ilford E.M.U.D. (Il)',
    },
    ILFORD: {
        mediumDescription: 'ILFORD ',
        shortDescription: 'IFD',
        longDescription: 'Ilford',
    },
    INGTSTL: {
        mediumDescription: 'INGATEDPL',
        shortDescription: 'ING',
        longDescription: 'Ingatestone Loop',
    },
    IVER: {
        mediumDescription: 'IVER ',
        shortDescription: 'IVR',
        longDescription: 'Iver',
    },
    LANGLEY: {
        mediumDescription: 'LANGLEY ',
        shortDescription: 'LNY',
        longDescription: 'Langley',
    },
    LANGREC: {
        mediumDescription: 'LANGLEYRS',
        shortDescription: 'LAN',
        longDescription: 'Langley Reception Sidings',
    },
    LDBRKJ: {
        mediumDescription: 'LADBROKGV ',
        shortDescription: 'LDB',
        longDescription: 'Ladbroke Grove',
    },
    LIMHSE: {
        mediumDescription: 'LIMEHOUSE ',
        shortDescription: 'LHS',
        longDescription: 'Limehouse',
    },
    LIVST: {
        mediumDescription: 'LIVERPLST ',
        shortDescription: 'LST',
        longDescription: 'London Liverpool Street',
    },
    LIVSTLL: {
        mediumDescription: 'LIVERSTXR',
        shortDescription: 'LSX',
        longDescription: 'Liverpool Street Crossrail',
    },
    MANRPK: {
        mediumDescription: 'MANORPARK ',
        shortDescription: 'MNP',
        longDescription: 'Manor Park',
    },
    MANRPKJ: {
        mediumDescription: 'MANORPRK ',
        shortDescription: 'MAN',
        longDescription: 'Manor Park Junction',
    },
    MDNHDE: {
        mediumDescription: 'MAIDNHEDE',
        shortDescription: 'MDN',
        longDescription: 'Maidenhead East',
    },
    MDNHDRS: {
        mediumDescription: 'MDNHDMIDS',
        shortDescription: 'MDN',
        longDescription: 'Maidenhead Reversing Sdgs',
    },
    MDNHEAD: {
        mediumDescription: 'MAIDENHED ',
        shortDescription: 'MAI',
        longDescription: 'Maidenhead',
    },
    MNTNJN: {
        mediumDescription: '',
        shortDescription: 'MNT',
        longDescription: 'Mountnessing Junction',
    },
    MRYLAND: {
        mediumDescription: 'MARYLAND ',
        shortDescription: 'MYL',
        longDescription: 'Maryland',
    },
    NPLEIEP: {
        mediumDescription: 'NPOLE IEP',
        shortDescription: 'NPL',
        longDescription: 'North Pole Iep Depot',
    },
    OLDOKCE: {
        mediumDescription: 'OOC EAST ',
        shortDescription: 'OLD',
        longDescription: 'Old Oak Common East',
    },
    OLDOKCW: {
        mediumDescription: 'OOC WEST ',
        shortDescription: 'OLD',
        longDescription: 'Old Oak Common West',
    },
    OLDOXAR: {
        mediumDescription: 'OLDOAKARX',
        shortDescription: 'OLD',
        longDescription: 'Old Oak Common Arrival Sig',
    },
    OLDOXDP: {
        mediumDescription: 'OLDOAKDPX',
        shortDescription: 'OLD',
        longDescription: 'Old Oak Common Depart Sig',
    },
    OLDOXRS: {
        mediumDescription: 'OLDOKDTXR',
        shortDescription: 'OLD',
        longDescription: 'Old Oak Depot',
    },
    PADTLL: {
        mediumDescription: 'PADDGTNXR',
        shortDescription: 'PDX',
        longDescription: 'Paddington Xrail',
    },
    PADTON: {
        mediumDescription: 'PADDINGTN ',
        shortDescription: 'PAD',
        longDescription: 'London Paddington',
    },
    PLMSEJ: {
        mediumDescription: 'PLUMSTDEJ',
        shortDescription: 'PLM',
        longDescription: 'Plumstead East Jn',
    },
    PLMSRS: {
        mediumDescription: 'PLMSRS',
        shortDescription: 'PLM',
        longDescription: 'Plumstead Reverse Siding',
    },
    PLMSXCR: {
        mediumDescription: 'PLMSXCR',
        shortDescription: 'PLM',
        longDescription: 'Plumstead Depot',
    },
    PRTOBJP: {
        mediumDescription: 'PORTOBELJ',
        shortDescription: 'PRT',
        longDescription: 'Portobello Jn',
    },
    PUDGMLL: {
        mediumDescription: 'PUDGMLL',
        shortDescription: 'PUD',
        longDescription: 'Pudding Mill Lane Portal',
    },
    RDNG726: {
        mediumDescription: 'RDNG726',
        shortDescription: 'RDN',
        longDescription: 'Reading Sig. T1726',
    },
    RDNG728: {
        mediumDescription: 'RDNG728',
        shortDescription: 'RDN',
        longDescription: 'Reading Sig. T1728',
    },
    RDNGHLJ: {
        mediumDescription: 'RDNGHLJ',
        shortDescription: 'RDN',
        longDescription: 'Reading High Level Jn',
    },
    RDNGKBJ: {
        mediumDescription: 'KEN BG JN',
        shortDescription: 'RDN',
        longDescription: 'Rdngkbj',
    },
    RDNGKBL: {
        mediumDescription: 'KEN BG LP',
        shortDescription: 'RDN',
        longDescription: 'Kennet Bridge Loop',
    },
    RDNGNJN: {
        mediumDescription: 'RDG NEWJN',
        shortDescription: 'RDN',
        longDescription: 'Reading New Jn',
    },
    RDNGORJ: {
        mediumDescription: 'OXFRDJN ',
        shortDescription: 'RDN',
        longDescription: 'Oxford Road Jn',
    },
    RDNGSTN: {
        mediumDescription: 'READNG ',
        shortDescription: 'RDG',
        longDescription: 'Reading',
    },
    RDNGTCC: {
        mediumDescription: 'RDNGTCC',
        shortDescription: 'RDN',
        longDescription: 'Reading Depot Entry C',
    },
    RDNGTCD: {
        mediumDescription: 'RDGTRNDEP',
        shortDescription: 'RDN',
        longDescription: 'Reading Traincare Depot',
    },
    REDGWJN: {
        mediumDescription: 'READINGWJ ',
        shortDescription: 'RED',
        longDescription: 'Reading West Jn',
    },
    ROMF450: {
        mediumDescription: 'ROMFD450',
        shortDescription: 'ROM',
        longDescription: 'Romford Signal L450',
    },
    ROMFORD: {
        mediumDescription: 'ROMFORD ',
        shortDescription: 'RMF',
        longDescription: 'Romford',
    },
    ROYAOJN: {
        mediumDescription: 'ROYLOAKJN',
        shortDescription: 'ROY',
        longDescription: 'Royal Oak Junction',
    },
    RUSCOMB: {
        mediumDescription: 'RUSCOMBE',
        shortDescription: 'RUS',
        longDescription: 'Ruscombe',
    },
    SHENFLD: {
        mediumDescription: 'SHENFIELD ',
        shortDescription: 'SNF',
        longDescription: 'Shenfield',
    },
    SHENFMS: {
        mediumDescription: 'SHENFLDMS',
        shortDescription: 'SHE',
        longDescription: 'Shenfield Middle Sidings',
    },
    SHENFUL: {
        mediumDescription: 'SHENFLDUL',
        shortDescription: 'SHE',
        longDescription: 'Shenfield Up Loop',
    },
    SHENLEJ: {
        mediumDescription: 'SHEN LEJN',
        shortDescription: 'SHE',
        longDescription: 'Shenfield London End Jn',
    },
    SLOU538: {
        mediumDescription: 'SLOU538',
        shortDescription: 'SLO',
        longDescription: 'Slough Sig T3538',
    },
    SLOUGH: {
        mediumDescription: 'SLOUGH ',
        shortDescription: 'SLO',
        longDescription: 'Slough',
    },
    SLOUGHW: {
        mediumDescription: 'SLOUGH W',
        shortDescription: 'SLO',
        longDescription: 'Slough West',
    },
    SPNY205: {
        mediumDescription: 'SPNY205',
        shortDescription: 'SPN',
        longDescription: 'Stepney Green Signal Xr205/Xr207',
    },
    SPNYGNJ: {
        mediumDescription: 'STEPNYGNJ',
        shortDescription: 'SPN',
        longDescription: 'Stepney Green Junction',
    },
    STFD: {
        mediumDescription: 'STRATFORD ',
        shortDescription: 'SRA',
        longDescription: 'Stratford',
    },
    STFD251: {
        mediumDescription: 'STRATL251',
        shortDescription: 'STF',
        longDescription: 'Stratford Signal L251',
    },
    STFD261: {
        mediumDescription: 'STRATL261',
        shortDescription: 'STF',
        longDescription: 'Stratford Signal L261',
    },
    STFD295: {
        mediumDescription: 'STRATL295',
        shortDescription: 'STF',
        longDescription: 'Stratford Signal L295',
    },
    STHALEJ: {
        mediumDescription: 'STHALLEJN',
        shortDescription: 'STH',
        longDescription: 'Southall East Jn',
    },
    STHALL: {
        mediumDescription: 'SOUTHALL ',
        shortDescription: 'STL',
        longDescription: 'Southall',
    },
    STHALWJ: {
        mediumDescription: 'STHALLWJN',
        shortDescription: 'STH',
        longDescription: 'Southall West Jn',
    },
    STHATC: {
        mediumDescription: 'STHALL YD',
        shortDescription: 'STH',
        longDescription: 'Southall T.C.',
    },
    STKYJN: {
        mediumDescription: 'STOCKLYJN',
        shortDescription: 'STK',
        longDescription: 'Stockley Junction',
    },
    SVNKNGS: {
        mediumDescription: 'SEVENKNGS ',
        shortDescription: 'SVK',
        longDescription: 'Seven Kings',
    },
    TAPLOW: {
        mediumDescription: 'TAPLOW ',
        shortDescription: 'TAP',
        longDescription: 'Taplow',
    },
    TMPLMEJ: {
        mediumDescription: 'TM EASTJN ',
        shortDescription: 'XTA',
        longDescription: 'Temple Mills East Jn.',
    },
    TOTCFST: {
        mediumDescription: 'TOTCFST',
        shortDescription: 'TOT',
        longDescription: 'Fisher Street West',
    },
    TOTCTRD: {
        mediumDescription: 'TOTCTRDXR',
        shortDescription: 'TCR',
        longDescription: 'Tottenham Court Road',
    },
    TWYF632: {
        mediumDescription: 'TWYF632',
        shortDescription: 'TWY',
        longDescription: 'Twyford Sig T1632',
    },
    TWYFDW: {
        mediumDescription: 'TWYFORDW',
        shortDescription: 'TWY',
        longDescription: 'Twyford West',
    },
    TWYFORD: {
        mediumDescription: 'TWYFORD ',
        shortDescription: 'TWY',
        longDescription: 'Twyford',
    },
    WARGRAV: {
        mediumDescription: 'WARGRAVE',
        shortDescription: 'WGV',
        longDescription: 'Wargrave',
    },
    WBRNPKS: {
        mediumDescription: 'WBRNPKCS',
        shortDescription: 'WBR',
        longDescription: 'Westbourne Park Cs',
    },
    WCHAPXR: {
        mediumDescription: 'WHITECLXR',
        shortDescription: 'WHX',
        longDescription: 'Whitechapel Crossrail',
    },
    WCHAVRD: {
        mediumDescription: 'WCHAVRD',
        shortDescription: 'WCH',
        longDescription: 'Whitechapel X-Over',
    },
    WDRYJN: {
        mediumDescription: 'W DRAYTNJ',
        shortDescription: 'WDR',
        longDescription: 'West Drayton Jcn',
    },
    WDRYTON: {
        mediumDescription: 'W DRAYTON',
        shortDescription: 'WDT',
        longDescription: 'West Drayton',
    },
    WEALING: {
        mediumDescription: 'W EALING ',
        shortDescription: 'WEA',
        longDescription: 'West Ealing',
    },
    WEALJN: {
        mediumDescription: 'W EALNG J',
        shortDescription: 'WEA',
        longDescription: 'West Ealing Junction',
    },
    WHAMHL: {
        mediumDescription: 'W HAM HL ',
        shortDescription: 'XHH',
        longDescription: 'West Ham',
    },
    WHELSTJ: {
        mediumDescription: 'WHELERSTJ',
        shortDescription: 'WHE',
        longDescription: 'Wheler Street Jn',
    },
    WOLWXR: {
        mediumDescription: 'WOOLWCHXR',
        shortDescription: 'WWC',
        longDescription: 'Woolwich Xrail',
    },
    ZZZREJN: {
        mediumDescription: 'ZZZREJN',
        shortDescription: 'ZZZ',
        longDescription: 'ZZZREJN',
    },
    ZZZPUDG: {
        mediumDescription: 'ZZZPUDG',
        shortDescription: 'ZZZ',
        longDescription: 'ZZZPUDG',
    },
    ZZZNPT: {
        mediumDescription: 'ZZZNPT',
        shortDescription: 'ZZZ',
        longDescription: 'ZZZNPT',
    },
    ZZZHBJN: {
        mediumDescription: 'ZZZHBJN',
        shortDescription: 'ZZZ',
        longDescription: 'ZZZHBJN',
    },
    ZZZHAN: {
        mediumDescription: 'ZZZHAN',
        shortDescription: 'ZZZ',
        longDescription: 'ZZZHAN',
    },
    ZZZFRD: {
        mediumDescription: 'ZZZFRD',
        shortDescription: 'ZZZ',
        longDescription: 'ZZZFRD',
    },
    ZZZACTE: {
        mediumDescription: 'ZZZACTE',
        shortDescription: 'ZZZ',
        longDescription: 'ZZZACTE',
    },
    ROJAOJN: {
        mediumDescription: 'ROJAOJN',
        shortDescription: 'ROJ',
        longDescription: 'Reading West Jn',
    },
    ILFELWJ: {
        mediumDescription: 'ILFELWJ',
        shortDescription: 'ILF',
        longDescription: 'Ilford Lon End Junction',
    },
}

export function getLocationName(tiploc: string | undefined) {
    if (tiploc === undefined) return '?'
    return locationDescriptions[tiploc]?.longDescription ?? tiploc
}

export function getShortLocationName(tiploc: string | undefined) {
    if (tiploc === undefined) return '?'
    return locationDescriptions[tiploc]?.shortDescription ?? tiploc
}

import AdminPage from '../AdminPage/AdminPage'
import React from 'react'
import useIntegrations from './hooks/useIntegrations'
import IntegrationsList from './components/IntegrationsList'

const AdministerIntegrations = () => {
    const { integrations, toggleIntegration } = useIntegrations()

    return (
        <AdminPage headerProps={{ title: 'Manage Integrations' }}>
            <IntegrationsList
                integrations={integrations}
                onToggleEnabled={toggleIntegration}
            />
        </AdminPage>
    )
}

export default AdministerIntegrations

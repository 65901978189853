import { Box, Container, Stack, Typography } from '@mui/material'
import React, { ReactNode } from 'react'
import PageHeader, { PageHeaderProps } from '../PageHeader/PageHeader'
import { Breakpoint } from '@mui/system'
import { styled } from '@mui/material/styles'
import QuickInfoChip, {
    QuickInfoChipProps,
} from '../../features/QuickInfo/components/QuickInfoChip/QuickInfoChip'
import useSimulationQuickInfo from '../../features/QuickInfo/hooks/useSimulationQuickInfoProps'

type PageProps = {
    headerProps: PageHeaderProps
    quickInfoProps?: QuickInfoChipProps[]
    maxWidth?: Breakpoint | false
    children: ReactNode
}

const PageContainer = styled(Container)(({ theme }) => ({
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
}))

const QuickInfoChipBar = ({
    chips,
}: {
    chips: NonNullable<PageProps['quickInfoProps']>
}) => {
    return (
        <Stack direction={'row'} gap={1} alignItems={'center'} mt={1}>
            <Typography fontSize={'small'}>Quick Info:</Typography>
            {chips.map((itemProps, index) => (
                <QuickInfoChip key={'qic' + index} {...itemProps} />
            ))}
        </Stack>
    )
}

const Page = ({
    headerProps,
    maxWidth = 'lg',
    children,
    quickInfoProps = [],
}: PageProps) => {
    const simulationQuickInfo = useSimulationQuickInfo()

    const quickInfos = [...quickInfoProps, ...simulationQuickInfo]

    return (
        <PageContainer maxWidth={maxWidth}>
            <PageHeader {...headerProps} />
            {quickInfos?.length > 0 && <QuickInfoChipBar chips={quickInfos} />}
            <Box mt={3}>{children}</Box>
        </PageContainer>
    )
}

export default Page

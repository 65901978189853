import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material'
import React from 'react'
import useExcelSimulations, {
    ImportedIncident,
    ImportedSimulation,
} from '../hooks/useExcelSimulations'
import dayjs from 'dayjs'
import {
    CreateIncidentRequest,
    CreateSimulationRequest,
    useCreateIncident,
    useCreateSimulation,
} from '../../../services/dst-api'
import {
    pushErrorNotification,
    pushSuccessNotification,
} from '../../../utils/notification'

type UploadedSimulationsProps = {
    file: File
}

const GenericTable = ({ data }: { data: any[] }) => {
    if (data.length === 0) return <p>No Data</p>

    const dateAndOrTimeToString = (jsDate: Date) => {
        const date = dayjs(jsDate)
        if (date.hour() === 0 && date.minute() === 0 && date.second() === 0)
            return date.format('YYYY-MM-DD')
        if (date.year() === 1899) return date.format('HH:mm')
        return date.format('YYYY-MM-DD HH:mm')
    }

    const headerCells = Object.keys(data[0]).map((key) => (
        <TableCell key={key}>{key}</TableCell>
    ))
    const rows = data.map((item, itemIndex) => (
        <TableRow key={itemIndex}>
            {Object.keys(item).map((key) => {
                const attributeName = key as keyof typeof item
                const value: any = item[attributeName]
                const cellKey = `row${itemIndex}-${key}`
                if (value instanceof Date)
                    return (
                        <TableCell key={cellKey}>
                            {dateAndOrTimeToString(value)}
                        </TableCell>
                    )
                else return <TableCell key={cellKey}>{value}</TableCell>
            })}
        </TableRow>
    ))

    return (
        <Table size="small">
            <TableHead>
                <TableRow>{headerCells}</TableRow>
            </TableHead>
            <TableBody>{rows}</TableBody>
        </Table>
    )
}

const UploadedSimulations = ({ file }: UploadedSimulationsProps) => {
    const DEFAULT_SIMULATION: Omit<
        CreateSimulationRequest,
        keyof ImportedSimulation
    > = {
        iterationCount: 1,
        majorPerturbations: false,
        minorPerturbations: false,
        network: 'Crossrail',
        type: 'REPLAY',
    }

    const DEFAULT_INCIDENT: Omit<
        CreateIncidentRequest,
        keyof ImportedIncident
    > = {}

    const { simulations, incidents } = useExcelSimulations(file)

    // TODO handle UI during import of simulations
    const { mutateAsync: createSimulationMutation, isLoading: isCreateSaving } =
        useCreateSimulation({
            mutation: {
                onSuccess: (simulation) => {
                    pushSuccessNotification('Simulation created successfully')
                },
                onError: (error) => {
                    pushErrorNotification(
                        `Simulation creation failed: ${error}`
                    )
                },
            },
        })

    // TODO handle UI during import of simulation incidents
    const createIncidentMutation = useCreateIncident({
        mutation: {
            onSuccess: () => {
                pushSuccessNotification('Incident created successfully.')
            },
            onError: () => {
                pushErrorNotification(`Error creating incident.`)
            },
        },
    })

    const createAndExecuteOne = (simulationToImport: ImportedSimulation) => {
        const incidentsToImport = incidents.filter(
            (incident) => incident.simulationId === simulationToImport.id
        )

        createSimulationMutation({
            data: {
                ...DEFAULT_SIMULATION,
                ...simulationToImport,
            },
        }).then((persistentSimulation) => {
            incidentsToImport.forEach((incidentToImport) => {
                createIncidentMutation.mutate({
                    data: {
                        ...DEFAULT_INCIDENT,
                        ...incidentToImport,
                        simulationId: persistentSimulation.id!!,
                    },
                })
            })
        })
    }

    const createAndExecute = () => {
        simulations.forEach((simulationToImport) => {
            createAndExecuteOne(simulationToImport)
        })
    }

    return (
        <>
            <h1>Simulations</h1>
            <GenericTable data={simulations} />
            <h1>Incidents</h1>
            <GenericTable data={incidents} />
            <Button onClick={createAndExecute}>Import All</Button>
        </>
    )
}
export default UploadedSimulations

import {
    getGetSimulationQueryKey,
    getGetSimulationsQueryKey,
    useCancelSimulation,
    useDeleteSimulation,
    useExecuteSimulation,
    useResumeSimulation,
} from '../../../services/dst-api'
import { useConfirm } from 'material-ui-confirm'
import { useQueryClient } from '@tanstack/react-query'

const useManageSimulation = () => {
    const confirm = useConfirm()
    const { mutate: deleteSimulationMutation } = useDeleteSimulation()
    const { mutate: executeSimulationMutation } = useExecuteSimulation()
    const { mutate: cancelSimulationMutation } = useCancelSimulation()
    const { mutate: resumeSimulationMutation } = useResumeSimulation()
    const queryClient = useQueryClient()

    // const handleSimulationAddClick = () => {
    //     if (simulations.length === 0) {
    //         // createSimulation();
    //         history.push(`/simulation/new`)
    //     } else {
    //         confirm({
    //             title: 'Simulation Creation Failed',
    //             description:
    //                 'This application is in the first phase of development and only a single simulation is allowed. Please delete the existing simulation before creating a new one.',
    //         })
    //             .then(() => {})
    //             .catch(() => {
    //                 console.log('Canceled simulation delete')
    //             })
    //     }
    // }

    const deleteSimulation = (simulationId: number) => {
        confirm({
            description:
                'Deleting the simulation is permanent and cannot be undone.',
        })
            .then(() => {
                deleteSimulationMutation(
                    {
                        params: {
                            simulationId: simulationId,
                        },
                    },
                    {
                        onSuccess: () => {
                            queryClient.invalidateQueries(
                                getGetSimulationQueryKey({
                                    simulationId: simulationId,
                                })
                            )
                            queryClient.invalidateQueries(
                                getGetSimulationsQueryKey()
                            )
                        },
                    }
                )
            })
            .catch(() => {
                console.log('Cancelled simulation delete')
            })
    }

    const _executeSimulation = (simulationId: number) => {
        executeSimulationMutation(
            {
                simulationId: simulationId,
                data: {},
            },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(
                        getGetSimulationQueryKey({
                            simulationId: simulationId,
                        })
                    )
                    queryClient.invalidateQueries(getGetSimulationsQueryKey())
                },
            }
        )
    }

    const executeSimulation = (simulationId: number) => {
        confirm({
            description:
                'Once the simulation is executing it can no longer be edited.',
        })
            .then(() => {
                _executeSimulation(simulationId)
            })
            .catch(() => {
                console.log('Cancelled simulation execution')
            })
    }

    const cancelSimulation = (simulationId: number) => {
        confirm({
            description:
                'Once the simulation is cancelled it will go to the back of the queue upon execution.',
        })
            .then(() => {
                cancelSimulationMutation(
                    {
                        simulationId: simulationId,
                        data: {},
                    },
                    {
                        onSuccess: () => {
                            queryClient.invalidateQueries(
                                getGetSimulationQueryKey({
                                    simulationId: simulationId,
                                })
                            )
                            queryClient.invalidateQueries(
                                getGetSimulationsQueryKey()
                            )
                        },
                    }
                )
            })
            .catch(() => {
                console.log('Cancelled simulation cancellation')
            })
    }

    const resumeSimulation = (simulationId: number) => {
        confirm({
            description:
                'Once the simulation is resumed it can no longer be edited.',
        })
            .then(() => {
                resumeSimulationMutation(
                    {
                        simulationId: simulationId,
                        data: {},
                    },
                    {
                        onSuccess: () => {
                            queryClient.invalidateQueries(
                                getGetSimulationQueryKey({
                                    simulationId: simulationId,
                                })
                            )
                            queryClient.invalidateQueries(
                                getGetSimulationsQueryKey()
                            )
                        },
                    }
                )
            })
            .catch(() => {
                console.log('Canceled simulation resume')
            })
    }

    const rerunSimulation = (simulationId: number) => {
        confirm({
            description:
                'Rerunning the simulation will delete previous results.',
        })
            .then(() => {
                _executeSimulation(simulationId)
            })
            .catch(() => {
                console.log('Cancelled simulation rerun')
            })
    }

    return {
        deleteSimulation,
        executeSimulation,
        cancelSimulation,
        resumeSimulation,
        rerunSimulation,
    }
}

export default useManageSimulation

import React, { useState } from 'react'
import { Slider } from '@mui/material'
import { TrainsPage } from './TrainLineupPager'

type TrainLineupSliderProps = {
    defaultValue: number
    pages: TrainsPage[]
    onChange: (page: number) => void
}

const TrainLineupSlider = ({
    defaultValue,
    pages,
    onChange,
}: TrainLineupSliderProps) => {
    const [uncommittedValue, setUncommittedValue] = useState<number | null>(
        null
    )

    function valueLabelFormat(value: number) {
        return pages[value].startTime
    }

    function handleChangeCommitted(
        event: React.SyntheticEvent | Event,
        value: number | number[]
    ) {
        if (typeof value !== 'number') return
        setUncommittedValue(null)
        onChange(value)
    }

    function handleChange(
        event: React.SyntheticEvent | Event,
        value: number | number[]
    ) {
        if (typeof value !== 'number') return
        setUncommittedValue(value)
    }

    return (
        <Slider
            aria-label="Time of day"
            defaultValue={defaultValue}
            value={uncommittedValue || defaultValue}
            getAriaValueText={(value, index) => pages[index].startTime}
            valueLabelDisplay="auto"
            step={1}
            marks
            min={0}
            max={pages.length - 1}
            valueLabelFormat={valueLabelFormat}
            onChangeCommitted={handleChangeCommitted}
            onChange={handleChange}
        />
    )
}

export default TrainLineupSlider

import { ChangeEvent, useState } from 'react'
import { LocationDataType } from '../../../data/locationDatasources'
import LocationDatasourcesField from '../LocationDatasourcesField/LocationDatasourcesField'
import AvailableDataTypesField from '../AvailableDataTypesField/AvailableDataTypesField'
import { Button } from '@mui/material'

const RailLakeLocationFields = () => {
    const [tiplocDatasource, setTiplocDatasource] = useState('Impact')
    const [tiplocFieldType, setTiplocFieldType] = useState(
        LocationDataType.TIPLOC
    )
    const [friendlyFieldType, setFriendlyFieldType] =
        useState<LocationDataType | null>(null)
    const [filterFieldType, setFilterFieldType] =
        useState<LocationDataType | null>(null)

    return (
        <>
            <LocationDatasourcesField
                dataType={LocationDataType.TIPLOC}
                value={tiplocDatasource}
                onChange={(e: ChangeEvent<any>) =>
                    setTiplocDatasource(e.target.value)
                }
            />
            <AvailableDataTypesField
                id={'tiplocField'}
                label={'Tiploc Mapping'}
                datasourceId={tiplocDatasource}
                value={tiplocFieldType}
                onChange={(e: ChangeEvent<any>) =>
                    setTiplocFieldType(e.target.value)
                }
            />
            <AvailableDataTypesField
                id={'friendlyField'}
                label={'Friendly Name Mapping'}
                datasourceId={tiplocDatasource}
                value={friendlyFieldType}
                onChange={(e: ChangeEvent<any>) =>
                    setFriendlyFieldType(e.target.value)
                }
            />
            <AvailableDataTypesField
                id={'filterField'}
                label={'Filter Dataset'}
                datasourceId={tiplocDatasource}
                value={filterFieldType}
                onChange={(e: ChangeEvent<any>) =>
                    setFilterFieldType(e.target.value)
                }
            />
            <Button variant={'outlined'}>Save</Button>
        </>
    )
}

export default RailLakeLocationFields

import React from 'react'
import TextField, { TextFieldProps } from './base/TextField'

type IncidentIdFieldProps = Omit<TextFieldProps, 'label'>

const IncidentIdField = (props: IncidentIdFieldProps) => {
    return <TextField label="Incident ID" {...props} />
}

export default IncidentIdField

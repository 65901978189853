import { styled } from '@mui/material/styles'
import { ArrowRight } from '@mui/icons-material'
import theme from '../../../../../theme/theme'
import TrainModificationChip from '../../TrainModificationChip/TrainModificationChip'
import React from 'react'
import { Train, TrainModification } from '../../../../../services/dst-api'
import { Typography } from '@mui/material'
import { formatTime } from '../../../../../utils/timeUtils'
import { getLocationName } from '../../../../../data/locationDescriptions'
import useTrainModifications, {
    UseModificationsResults,
} from '../../../hooks/useTrainModifications'

const TimetableOverviewContainer = styled('div')(() => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
}))

const TimetableOverviewLocationsContainer = styled('div')<
    Pick<TimetableOverviewProps, 'orientation'>
>((props) => ({
    display: 'flex',
    flexDirection: props.orientation === 'horizontal' ? 'row' : 'column',
}))

const TimetableOverviewOtherContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '1em',
}))

type TimetableOverviewProps = {
    train: Train
    orientation: 'horizontal' | 'vertical'
    displayModifications: boolean
}

const ImportantText = styled(Typography)(() => ({
    ...theme.typography.h5,
    color: 'inherit',
}))

type ContentProps = {
    train: Train
}

const HorizontalContent = ({ train }: ContentProps) => {
    return (
        <>
            <>
                <ImportantText
                    sx={{
                        minWidth: '100px',
                        maxWidth: '400px',
                    }}
                >
                    {formatTime(train.originDepartureInstant)}{' '}
                    {getLocationName(train.origin.tiploc)}
                </ImportantText>
                <ArrowRight
                    sx={{ marginX: theme.spacing(1) }}
                    height={'100%'}
                />
                <ImportantText>
                    {formatTime(train.destinationArrivalInstant)}{' '}
                    {getLocationName(train.destination.tiploc)}
                </ImportantText>
            </>
        </>
    )
}

const VerticalContent = ({ train }: ContentProps) => {
    return (
        <>
            <>
                <ImportantText>
                    {formatTime(train.originDepartureInstant)}
                </ImportantText>
                <ImportantText>
                    {getLocationName(train.origin.tiploc)}
                </ImportantText>
            </>
        </>
    )
}

const TimetableOverview = ({
    train,
    orientation,
    displayModifications,
}: TimetableOverviewProps) => {
    const { isSupported, remove } = useTrainModifications(train.id)
    const getDeleteHandler = (
        modification: TrainModification
    ): UseModificationsResults['remove'] | undefined => {
        if (!isSupported(modification.type)) return undefined
        return remove
    }

    return (
        <TimetableOverviewContainer>
            <TimetableOverviewLocationsContainer orientation={orientation}>
                {orientation === 'horizontal' ? (
                    <HorizontalContent train={train} />
                ) : (
                    <VerticalContent train={train} />
                )}
            </TimetableOverviewLocationsContainer>

            {displayModifications && (
                <TimetableOverviewOtherContainer>
                    {train.modifications.map((modification, index) => {
                        const handleDelete = getDeleteHandler(modification)
                        if (handleDelete === undefined)
                            console.log(
                                'Deleting is not supported for ' +
                                    modification.type
                            )
                        else
                            console.log(
                                'Deleting is supported for ' + modification.type
                            )
                        return (
                            <TrainModificationChip
                                key={modification.type + index}
                                modification={modification}
                                onDelete={handleDelete}
                            />
                        )
                    })}
                </TimetableOverviewOtherContainer>
            )}
        </TimetableOverviewContainer>
    )
}

export default TimetableOverview

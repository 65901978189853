import React from 'react'
import { TextField as MuiTextField } from '@mui/material'
import BaseField, { BaseFieldWithChangeHandlerProps } from './BaseField'
import { BaseTextFieldProps } from '@mui/material/TextField/TextField'

export interface TextFieldProps
    extends BaseFieldWithChangeHandlerProps,
        Omit<
            BaseTextFieldProps,
            'id' | 'name' | 'variant' | 'value' | 'label' | 'fullWidth'
        > {
    label: string
    value: any
}

export const TextField = ({
    id,
    label,
    onChange,
    value,
    ...rest
}: TextFieldProps) => {
    return (
        <BaseField>
            <MuiTextField
                id={id}
                name={id}
                fullWidth
                label={label}
                onChange={onChange}
                value={value}
                variant="outlined"
                {...rest}
            />
        </BaseField>
    )
}

export default TextField

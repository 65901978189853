import AdminPage from '../AdminPage/AdminPage'
import DataStrategyField from '../../../../components/fields/fields/DataStrategyField'
import LocationsList from '../LocationsList/LocationsList'
import { CommonFormLayout } from '../../../../components/fields/CommonForm'
import React, { useState } from 'react'
import RailLakeLocationFields from '../../../railLake/components/location/RailLakeLocationFields/RailLakeLocationFields'
import RailLakeDataOperations from '../../../railLake/components/RailLakeDataOperations/RailLakeDataOperations'
import { useGetLocations } from '../../../../services/dst-api'
import LoadingBox from '../../../../components/InformationBox/LoadingBox'

const AdministerLocations = () => {
    const [strategy, setStrategy] = useState('Manual')
    const { data: locations, isLoading: locationsLoading } = useGetLocations()

    console.log(`locations loading ${locationsLoading}`)
    console.log(locations || [])

    return (
        <AdminPage headerProps={{ title: 'Manage Locations' }}>
            <CommonFormLayout title={'Configuration'}>
                <DataStrategyField
                    id={'dataStrategyField'}
                    value={strategy}
                    onChange={(e: React.ChangeEvent<any>) =>
                        setStrategy(e.target.value)
                    }
                />

                {strategy === 'RL' && <RailLakeLocationFields />}
            </CommonFormLayout>

            {strategy === 'RL' && <RailLakeDataOperations />}

            <CommonFormLayout title={'Data'}>
                {locationsLoading ? (
                    <LoadingBox />
                ) : (
                    <LocationsList locations={locations || []} />
                )}
            </CommonFormLayout>
        </AdminPage>
    )
}

export default AdministerLocations

import React, { createContext, useState } from 'react'
import { TrainGraphSettings } from '../components/TrainGraphSettings/TrainGraphSettingsForm'
import { TrainChartColourScheme } from '../hooks/useTrainGraphSettings'
import { colourSchemes } from '../data/colourSchemes'
import { TrainGraphSection } from '../data/trainGraphSections'
import {
    firstTrainGraphLayout,
    TrainGraphLayout,
} from '../data/trainGraphLayout'

type GlobalTrainGraphSettingsContextType = {
    settings: TrainGraphSettings
    period: number
    colourScheme: TrainChartColourScheme
    setSettings: (time: GlobalTrainGraphSettingsContextType['settings']) => void
    setPeriod: (period: GlobalTrainGraphSettingsContextType['period']) => void
    trainGraphLayout: TrainGraphLayout
    sections: TrainGraphSection[]
    setSections: (
        sections: GlobalTrainGraphSettingsContextType['sections']
    ) => void
}

// Create two context:
// UserContext: to query the context state
// UserDispatchContext: to mutate the context state
const GlobalTrainGraphSettingsContext = createContext<
    GlobalTrainGraphSettingsContextType | undefined
>(undefined)

// A "provider" is used to encapsulate only the
// components that needs the state in this context
function GlobalTrainGraphSettingsProvider({
    children,
}: {
    children: React.ReactNode
}) {
    const [settings, setSettings] = useState<TrainGraphSettings>({
        hoverMode: 'dataset',
        primaryJourneyType: 'baseline',
        comparatorJourneyType: 'none',
        showJourneyLabels: true,
        showMinorLocations: false,
        lineColourStrategy: 'NONE',
        heightRatio: 100,
    })
    const [period, setPeriod] = useState<number>(30)
    const [trainGraphLayout, setTrainGraphLayout] = useState(
        firstTrainGraphLayout
    )
    const [sections, setSections] = useState([trainGraphLayout.sections[0]])
    const [colourScheme, setColourScheme] = useState(colourSchemes[1])

    return (
        <GlobalTrainGraphSettingsContext.Provider
            value={{
                settings,
                period,
                colourScheme,
                setSettings,
                setPeriod,
                trainGraphLayout,
                sections,
                setSections,
            }}
        >
            {children}
        </GlobalTrainGraphSettingsContext.Provider>
    )
}

export { GlobalTrainGraphSettingsProvider, GlobalTrainGraphSettingsContext }

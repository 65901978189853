import React from 'react'
import {
    SwitchField,
    SwitchFieldProps,
} from '../../../../../components/fields/fields/base/SwitchField'

type ShowJourneyLabelsFieldProps = Pick<
    SwitchFieldProps,
    'id' | 'value' | 'onChange'
>

const ShowJourneyLabelsField = ({
    id,
    value,
    onChange,
}: ShowJourneyLabelsFieldProps) => {
    return (
        <SwitchField
            id={id}
            titledFieldProps={{
                title: 'Show Headcode Labels',
                subtitle:
                    'Enabling this will display a headcode at the start of each journey',
            }}
            onChange={onChange}
            value={value}
        />
    )
}

export default ShowJourneyLabelsField

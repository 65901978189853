import dayjs from 'dayjs'

export const formatTime = (time: string, includeSeconds: boolean = false) => {
    const template = includeSeconds ? 'HH:mm:ss' : 'HH:mm'

    const iso8601 =
        time.search(
            '^(\\d{4})-(\\d\\d)-(\\d\\d)T(\\d\\d):(\\d\\d):(\\d\\d).(\\d)+Z'
        ) !== -1
    if (iso8601) return dayjs(time).format(template)

    const iso8601WithoutZone =
        time.search(
            '^(\\d{4})-(\\d\\d)-(\\d\\d)T(\\d\\d):(\\d\\d):(\\d\\d).(\\d)'
        ) !== -1
    if (iso8601WithoutZone) return dayjs(time).format(template)

    const iso8601WithoutMillis =
        time.search(
            '^(\\d{4})-(\\d\\d)-(\\d\\d)T(\\d\\d):(\\d\\d):(\\d\\d)+Z'
        ) !== -1
    if (iso8601WithoutMillis) return dayjs(time).format(template)

    const iso8601WithoutMillisAndZone =
        time.search(
            '^(\\d{4})-(\\d\\d)-(\\d\\d)T(\\d\\d):(\\d\\d):(\\d\\d)'
        ) !== -1
    if (iso8601WithoutMillisAndZone) return dayjs(time).format(template)

    const timeWithSeconds = time.search('^(\\d\\d):(\\d\\d):(\\d\\d)$') !== -1
    if (timeWithSeconds) return dayjs(time, 'HH:mm:ss').format(template)

    console.error(`Time cell could not parse ${time}`)
    return '??:??'
}

export const formatDateTime = (
    dateTime: string,
    includeSeconds: boolean = false
) => {
    const template = includeSeconds ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD HH:mm'

    const iso8601WithoutTimezone =
        dateTime.search(
            '^(\\d{4})-(\\d\\d)-(\\d\\d)T(\\d\\d):(\\d\\d):(\\d\\d).(\\d)'
        ) !== -1
    if (iso8601WithoutTimezone) return dayjs(dateTime).format(template)

    const iso8601WithoutMillisAndTimezone =
        dateTime.search(
            '^(\\d{4})-(\\d\\d)-(\\d\\d)T(\\d\\d):(\\d\\d):(\\d\\d)'
        ) !== -1
    if (iso8601WithoutMillisAndTimezone) return dayjs(dateTime).format(template)

    console.error(`Could not date time ${dateTime}`)
    return '??-??-?? ??:??'
}

export const parseDateTime = (date: string, time: string) => {
    return dayjs(date)
        .hour(Number(time.split(':')[0]))
        .minute(Number(time.split(':')[1]))
}

import { useState } from 'react'
import { Train } from '../../../services/dst-api'
import { TrainsPage } from '../components/TrainsList/TrainLineup/components/TrainLineupPager'
import { formatTime } from '../../../utils/timeUtils'

export type TrainLineupManager = {
    trains: Train[]
    setPage: (page: number) => void
    currentPage: number
    pages: TrainsPage[]
}

export type UseTrainLineupProps = {
    trains: Train[]
    pageSize: number
}

const useTrainLineup = ({
    trains,
    pageSize,
}: UseTrainLineupProps): TrainLineupManager => {
    const [index, setIndex] = useState<number>(0)

    const pageTrains = trains.slice(index, index + pageSize)

    const getPageStartIndex = (page: number) => page * pageSize + 1

    const setPage = (page: number) => {
        const pageStartIndex = getPageStartIndex(page)
        setIndex(pageStartIndex)
    }

    const getPageTime = (page: number): string => {
        const pageStartIndex = getPageStartIndex(page)
        return formatTime(trains[pageStartIndex].originDepartureInstant)
    }

    const pageCount = Math.floor((trains.length - 1) / pageSize)
    const currentPage = Math.floor(index / pageSize)

    const pages: TrainsPage[] = new Array(pageCount).fill(1).map((_, i) => {
        return {
            startTime: getPageTime(i),
        }
    })

    return {
        trains: pageTrains,
        setPage: setPage,
        currentPage: currentPage,
        pages: pages,
    }
}

export default useTrainLineup

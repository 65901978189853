import dayjs from 'dayjs'
import { Point } from 'chart.js'
import { TrainGraphLocation } from '../data/trainGraphSections'
import {
    SimulatedJourney,
    SimulatedJourneyVisit,
} from '../../../services/dst-api'
import { JourneyType } from '../components/TrainGraphSettings/TrainGraphSettingsFields/JourneyTypeField'

export type GraphableJourney = {
    headcode: string
    uid: string
    toc: string
    points: Point[]
}

const useTrainGraphData = (
    route: Array<TrainGraphLocation>,
    simulatedJourneys: SimulatedJourney[]
) => {
    const tiplocDistances = new Map(
        route.map((location) => [location.tiploc, location.distance])
    )

    const createTimePoint = (x: string, y: number): Point => {
        return {
            y: y,
            x: dayjs(x).valueOf(),
        }
    }

    const getValues = (visits: SimulatedJourneyVisit[]): Point[] => {
        return visits
            .map((visit) => {
                const yValue = tiplocDistances.get(visit.tiploc) ?? null
                if (yValue === null) {
                    return null
                }

                const points: Point[] = []
                if (visit.arrival != null) {
                    const arrival = createTimePoint(visit.arrival, yValue)
                    points.push(arrival)
                }

                if (
                    visit.departure != null &&
                    visit.arrival !== visit.departure
                ) {
                    const departure = createTimePoint(visit.departure, yValue)
                    points.push(departure)
                }

                return points
            })
            .flat()
            .filter((v) => {
                return v != null
            }) as Point[]
    }

    const generateChartData = (
        startTime: dayjs.Dayjs,
        endTime: dayjs.Dayjs,
        journeyType: JourneyType['id']
    ): GraphableJourney[] => {
        const journeys = simulatedJourneys
            .filter((j) => j.type === journeyType)
            .filter((j) => {
                const originTime = dayjs(
                    (j.visits[0].arrival ?? j.visits[0].departure)!!
                )
                const destinationTime = dayjs(
                    (j.visits[j.visits.length - 1].arrival ??
                        j.visits[j.visits.length - 1].departure)!!
                )
                return (
                    originTime.isBefore(endTime) &&
                    destinationTime.isAfter(startTime)
                )
            })

        //TODO visits could be filtered to only render visits spanning the time period
        return journeys.map((journey) => {
            return {
                headcode: journey.headcode,
                uid: journey.uid,
                toc: journey.toc,
                points: getValues(journey.visits),
            }
        })
    }

    return {
        generateChartData,
    }
}

export default useTrainGraphData

import React from 'react'
import { useParams } from 'react-router-dom'
import { Grid } from '@mui/material'
import LoadingBox from '../../../../components/InformationBox/LoadingBox'
import ErrorBox from '../../../../components/InformationBox/ErrorBox'
import InformationBox from '../../../../components/InformationBox/InformationBox'
import Page from '../../../../components/Page/Page'
import {
    GenericSort,
    SortDirection,
} from '../../../../components/ListModeSearchBar/GenericSortBox/GenericSortBox'
import useTrains from '../../hooks/useTrains'
import { TrainsListSortField } from '../../../../types/TrainsListSortField'
import TrainLineup from '../TrainsList/TrainLineup/TrainLineup'

const ReadonlySimulationTrainsContent = () => {
    const sort: GenericSort<TrainsListSortField> = {
        field: TrainsListSortField.ORIGIN_DEPARTURE,
        direction: SortDirection.ASC,
    }

    //get simulation id from url param
    let { simulationId } = useParams()
    const liveMode = simulationId === undefined
    const validSimulationId = liveMode ? 1 : Number(simulationId)

    // retrieve trains from backend
    const { trains, isLoading, isError } = useTrains(sort, validSimulationId)

    if (isLoading) {
        return <LoadingBox />
    }

    if (isError) {
        return <ErrorBox message={'Trains could not be retrieved.'} />
    }

    if (trains.length === 0) {
        return (
            <InformationBox
                title={'No Trains'}
                message={'No trains exist for this simulation.'}
            />
        )
    }

    return (
        <>
            <TrainLineup
                trains={trains}
                pageSize={8}
                mode={'grid'}
                editable={false}
            />
        </>
    )
}

const ReadonlySimulationTrains = () => {
    return (
        <Page
            maxWidth="lg"
            headerProps={{
                title: 'Service Changes',
            }}
        >
            <Grid item xs={12}>
                <ReadonlySimulationTrainsContent />
            </Grid>
        </Page>
    )
}

export default ReadonlySimulationTrains

import {
    LocationVisit,
    LocationVisitAction,
} from '../../../../../services/dst-api'
import { JourneyOperations } from '../../../hooks/useModifiableTrainSchedule'
import { JourneyOperationMode } from '../../../hooks/useJourneyOperation'
import JourneyOperationCell, {
    JourneyOperationColourScheme,
} from './JourneyOperationCell'
import useTrainJourneyOperation from '../../../hooks/useTrainJourneyOperation'
import useActiveVisit from '../../../hooks/useActiveVisit'
import theme from '../../../../../theme/theme'
import { SxProps } from '@mui/system'
import {
    Checkbox,
    FormControlLabel,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
} from '@mui/material'
import TimeCell from '../../../../../components/BaseTable/TimeCell'
import PlatformChip from './PlatformChip'
import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { mergeSx } from '../../../../../utils/sxUtils'
import ActionChip from './ActionChip'
import {
    getLocationName,
    getShortLocationName,
} from '../../../../../data/locationDescriptions'

type WideVisitGridProps = {
    visits: LocationVisit[]
    operations: JourneyOperations
}

type CompactVisitGridProps = {
    visits: LocationVisit[]
}

type ModeColourSchemes = {
    [key in JourneyOperationMode]: JourneyOperationColourScheme
}

export const CompactVisitsGrid = ({ visits }: CompactVisitGridProps) => {
    const genericCancelledStyling: SxProps<Theme> = {
        textDecoration: 'line-through',
        color: 'error.main',
        opacity: 0.5,
    }

    const getCancelledStyling: (visit: LocationVisit) => SxProps<Theme> = (
        visit
    ) => (visit.state.cancelled ? genericCancelledStyling : {})

    const getCancelledToStyling: (visit: LocationVisit) => SxProps<Theme> = (
        visit
    ) =>
        visit.state.cancelled || visit.state.cancelledTo
            ? genericCancelledStyling
            : {}

    const tableRows = visits.map((visit) => {
        const cancelledStyling = getCancelledStyling(visit)
        const cancelledToStyling = getCancelledToStyling(visit)

        const actionIcon = visit.stop
            ? solid('circle-dot')
            : solid('grip-lines-vertical')
        return (
            <TableRow key={visit.id}>
                <TableCell sx={cancelledStyling}>
                    {getShortLocationName(visit.location.tiploc)}
                </TableCell>
                <TimeCell sx={cancelledToStyling} align={'center'}>
                    {visit.arrivalInstant}
                </TimeCell>
                <TableCell sx={cancelledToStyling} align={'center'}>
                    <PlatformChip>{visit.arrivalPlatform}</PlatformChip>
                </TableCell>
                <TableCell sx={cancelledToStyling} align={'center'}>
                    <FontAwesomeIcon icon={actionIcon} />
                </TableCell>
            </TableRow>
        )
    })

    return (
        <React.Fragment>
            <Table size="small" cellPadding={0} cellSpacing={0}>
                <TableHead>
                    <TableRow>
                        <TableCell>Loc</TableCell>
                        <TableCell align={'center'}>Arr</TableCell>
                        <TableCell align={'center'}>Pf</TableCell>
                        <TableCell align={'center'}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{tableRows}</TableBody>
            </Table>
        </React.Fragment>
    )
}

export const WideVisitsGrid = ({ visits, operations }: WideVisitGridProps) => {
    const [displayPasses, setDisplayPasses] = useState(true)

    const cancelFromOperation = useTrainJourneyOperation(
        visits,
        'CANCEL',
        'FROM'
    )
    const cancelToOperation = useTrainJourneyOperation(visits, 'CANCEL', 'TO')
    const runFastFromOperation = useTrainJourneyOperation(
        visits,
        'RUNFAST',
        'FROM'
    )
    const runFastToOperation = useTrainJourneyOperation(visits, 'RUNFAST', 'TO')

    const cancelFromActiveVisit = useActiveVisit()
    const cancelToActiveVisit = useActiveVisit()
    const runFastFromActiveVisit = useActiveVisit()
    const runFastToActiveVisit = useActiveVisit()

    const journeyOperationColourSchemes: ModeColourSchemes = {
        CANCEL: {
            enabled: theme.palette.error.main,
            disabled: theme.palette.grey.A400,
            subtraction: theme.palette.success.main,
            hover: theme.palette.error.dark,
        },
        RUNFAST: {
            enabled: theme.palette.success.main,
            disabled: theme.palette.grey.A400,
            subtraction: theme.palette.warning.main,
            hover: theme.palette.success.dark,
        },
        TOGGLE_STOP: {
            enabled: theme.palette.success.main,
            disabled: theme.palette.grey.A400,
            subtraction: theme.palette.warning.main,
            hover: theme.palette.success.dark,
        },
    }

    const genericCancelledStyling: SxProps<Theme> = {
        textDecoration: 'line-through',
        color: 'error.main',
        opacity: 0.5,
    }

    const getCancelledStyling: (visit: LocationVisit) => SxProps<Theme> = (
        visit
    ) => (visit.state.cancelled ? genericCancelledStyling : {})

    const getCancelledFromStyling: (visit: LocationVisit) => SxProps<Theme> = (
        visit
    ) =>
        visit.state.cancelled || visit.state.cancelledFrom
            ? genericCancelledStyling
            : {}

    const getCancelledToStyling: (visit: LocationVisit) => SxProps<Theme> = (
        visit
    ) =>
        visit.state.cancelled || visit.state.cancelledTo
            ? genericCancelledStyling
            : {}

    const getActionStyling: (visit: LocationVisit) => SxProps<Theme> = (
        visit
    ) => {
        return visit.stop
            ? {
                  fontWeight: 'bold',
              }
            : {}
    }

    const tableRows = visits
        .filter(
            (visit) =>
                visit.action !== LocationVisitAction.PASS || displayPasses
        )
        .map((visit) => {
            const cancelledStyling = getCancelledStyling(visit)
            const cancelledFromStyling = getCancelledFromStyling(visit)
            const cancelledToStyling = getCancelledToStyling(visit)
            const actionStyling = getActionStyling(visit)
            return (
                <TableRow key={visit.id} sx={cancelledStyling}>
                    <TableCell sx={cancelledStyling}>
                        {getLocationName(visit.location.tiploc)}
                    </TableCell>
                    <TableCell sx={cancelledStyling} align={'center'}>
                        <ActionChip
                            visit={visit}
                            colourScheme={
                                journeyOperationColourSchemes['TOGGLE_STOP']
                            }
                            onToggleStop={() =>
                                operations.TOGGLE_STOP.submit(visit)
                            }
                        />
                    </TableCell>
                    <TimeCell
                        sx={mergeSx(cancelledToStyling, actionStyling)}
                        align={'center'}
                    >
                        {visit.action !== LocationVisitAction.PASS
                            ? visit.arrivalInstant
                            : ''}
                    </TimeCell>
                    <TimeCell
                        sx={mergeSx(cancelledFromStyling, actionStyling)}
                        align={'center'}
                    >
                        {visit.departureInstant}
                    </TimeCell>
                    <TableCell sx={cancelledFromStyling} align={'center'}>
                        <PlatformChip>{visit.departurePlatform}</PlatformChip>
                    </TableCell>
                    <JourneyOperationCell
                        visit={visit}
                        operation={cancelToOperation}
                        activeVisitManager={cancelToActiveVisit}
                        onSubmit={() => operations.CANCEL_TO.submit(visit)}
                        colourScheme={journeyOperationColourSchemes['CANCEL']}
                    />
                    <JourneyOperationCell
                        visit={visit}
                        operation={cancelFromOperation}
                        activeVisitManager={cancelFromActiveVisit}
                        onSubmit={() => operations.CANCEL_FROM.submit(visit)}
                        colourScheme={journeyOperationColourSchemes['CANCEL']}
                    />

                    <JourneyOperationCell
                        visit={visit}
                        operation={runFastToOperation}
                        activeVisitManager={runFastToActiveVisit}
                        onSubmit={() => operations.RUNFAST_TO.submit(visit)}
                        colourScheme={journeyOperationColourSchemes['RUNFAST']}
                    />
                    <JourneyOperationCell
                        visit={visit}
                        operation={runFastFromOperation}
                        activeVisitManager={runFastFromActiveVisit}
                        onSubmit={() => operations.RUNFAST_FROM.submit(visit)}
                        colourScheme={journeyOperationColourSchemes['RUNFAST']}
                    />
                </TableRow>
            )
        })

    return (
        <React.Fragment>
            <FormControlLabel
                label={'Display Passes'}
                control={
                    <Checkbox
                        defaultChecked={displayPasses}
                        onClick={() => setDisplayPasses(!displayPasses)}
                    />
                }
            />
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Location</TableCell>
                        <TableCell align={'center'}>Action</TableCell>
                        <TableCell align={'center'}>Arr</TableCell>
                        <TableCell align={'center'}>Dep</TableCell>
                        <TableCell align={'center'}>Pf</TableCell>
                        <TableCell align={'center'}>Cx To</TableCell>
                        <TableCell align={'center'}>Cx From</TableCell>
                        <TableCell align={'center'}>FTS To</TableCell>
                        <TableCell align={'center'}>FTS From</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{tableRows}</TableBody>
            </Table>
        </React.Fragment>
    )
}

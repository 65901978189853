import React from 'react'
import { useParams } from 'react-router-dom'
import { useGetIncident } from '../../../../services/dst-api'
import { CommonSkeletonForm } from '../../../../components/fields/CommonForm'
import { BreadcrumbsProps } from '../../../../components/PageHeader/PageHeader'
import ErrorBox from '../../../../components/InformationBox/ErrorBox'
import EditIncidentForm from './components/EditIncidentForm'
import Page from '../../../../components/Page/Page'

const EditSimulationIncidentContent = ({
    incidentId,
}: {
    incidentId: number
}) => {
    const getIncidentQuery = useGetIncident({
        incidentId: Number(incidentId),
    })

    if (getIncidentQuery.isLoading) {
        return <CommonSkeletonForm />
    }

    if (getIncidentQuery.isError) {
        return (
            <ErrorBox
                message={`Retrieval of Incident ${incidentId} failed.`}
            ></ErrorBox>
        )
    }

    return <EditIncidentForm incident={getIncidentQuery.data} />
}

const EditSimulationIncident = () => {
    let { incidentId, simulationId } = useParams()
    const liveMode = simulationId === undefined

    const nonLiveModeBreadcrumbs: BreadcrumbsProps = {
        previous: [
            {
                text: 'Simulations',
                to: '/replay',
            },
            {
                text: 'Edit Simulation',
                to: '../basic',
            },
            {
                text: 'Edit Incidents',
                to: '../incidents',
            },
        ],
        current: {
            text: 'Edit Incident',
        },
    }

    const liveModeBreadcrumbs: BreadcrumbsProps = {
        previous: [
            {
                text: 'Manage Incidents',
                to: '../incidents',
            },
        ],
        current: {
            text: 'Edit Incident',
        },
    }

    return (
        <Page
            maxWidth="lg"
            headerProps={{
                title: 'Edit Incident',
                breadcrumbConfig: liveMode
                    ? liveModeBreadcrumbs
                    : nonLiveModeBreadcrumbs,
            }}
        >
            <EditSimulationIncidentContent incidentId={Number(incidentId)} />
        </Page>
    )
}

export default EditSimulationIncident

import { useContext } from 'react'
import {
    SimulationTrainGraphSettingsContext,
    SimulationTrainGraphSettingsDispatchContext,
} from '../context/SimulationTrainGraphSettingsProvider'

export default function useSimulationSettingsContext() {
    const context = useContext(SimulationTrainGraphSettingsContext)
    const dispatcherContext = useContext(
        SimulationTrainGraphSettingsDispatchContext
    )
    if (!context || !dispatcherContext)
        throw Error(
            'useSimulationSettingsContext can only be used inside an SimulationTrainGraphSettingsProvider'
        )
    return {
        data: context,
        dispatcher: dispatcherContext,
    }
}

import { useContext } from 'react'
import { SimulationContext } from '../context/SimulationProvider'

const useSimulationContext = () => {
    let simulation = useContext(SimulationContext)

    return {
        simulation,
    }
}

export default useSimulationContext

export type Integration = {
    id: string
    title: string
    description: string
    enabled: boolean
}

export const INTEGRATIONS: Integration[] = [
    {
        id: '1',
        title: 'Schedule Importer',
        description:
            'Import CIF & VSTP schedules upon creation of a simulation',
        enabled: false,
    },
    {
        id: '2',
        title: 'Trust Incident Alerts',
        description:
            'Notify users when a new Trust incident is created on the organisations network.',
        enabled: false,
    },
    {
        id: '3',
        title: 'Geographic Data Sync',
        description:
            'Remove the need to manually manage geographic data by automatically synchronising. By default this will be hourly but can be customised.',
        enabled: false,
    },
]

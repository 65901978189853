import React from 'react'
import { Container, Stack } from '@mui/material'
import InformationBox from '../../../../../../../components/InformationBox/InformationBox'
import NarrowSimulationCard from './NarrowSimulationCard'
import { BaseSimulationsDisplayProps } from '../SimulationsDisplay'

type SimulationListProps = BaseSimulationsDisplayProps

function SimulationList({
    simulations,
    onSimulationDelete,
    onSimulationExecute,
    onSimulationCancel,
    onSimulationResume,
    onSimulationRerun,
}: SimulationListProps) {
    if (simulations.length === 0) {
        return (
            <InformationBox
                title={'No Simulations'}
                message={
                    'No simulations exist. Click the button above to create one.'
                }
            />
        )
    }

    return (
        <Container maxWidth="lg">
            <Stack spacing={1}>
                {simulations.map((simulation) => (
                    <NarrowSimulationCard
                        key={simulation.id}
                        simulation={simulation}
                        onSimulationDelete={onSimulationDelete}
                        onSimulationExecute={onSimulationExecute}
                        onSimulationCancel={onSimulationCancel}
                        onSimulationResume={onSimulationResume}
                        onSimulationRerun={onSimulationRerun}
                    />
                ))}
            </Stack>
        </Container>
    )
}
export default SimulationList

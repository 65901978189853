import { ToolbarGroup } from './ToolbarGroup'
import React from 'react'
import GenericSearchBar from '../GenericSearchBar/GenericSearchBar'
import GenericSortBox, {
    GenericSortBoxProps,
    SortFieldGenericType,
} from '../GenericSortBox/GenericSortBox'

export type SearchAndSortToolbarProps<T extends SortFieldGenericType> = {
    entityDescription: string
    sortProps: GenericSortBoxProps<T>
}

export function SearchAndSortToolbar<T extends SortFieldGenericType>({
    entityDescription,
    sortProps,
}: SearchAndSortToolbarProps<T>) {
    return (
        <>
            <ToolbarGroup>
                <GenericSearchBar entityDescription={entityDescription} />
                <GenericSortBox {...sortProps} />
            </ToolbarGroup>
        </>
    )
}

import {
    SimulationResults,
    useGetSimulationResults,
} from '../../../services/dst-api'
import useSimulation from '../../../hooks/useSimulation'

const useSimulationResults = (simulationId: number) => {
    const {
        isLoading: isSimulationLoading,
        isError: isSimulationError,
        simulation,
    } = useSimulation(simulationId)

    // retrieve simulation results
    const simulationResultsQuery = useGetSimulationResults(simulationId)
    const simulationResults: SimulationResults | undefined =
        simulationResultsQuery.data

    const isLoading = simulationResultsQuery.isLoading
    const isError = simulationResultsQuery.isError

    return {
        isLoading: isLoading || isSimulationLoading,
        isError: isError || isSimulationError,
        simulation,
        simulationResults,
    }
}

export default useSimulationResults

import BaseField, { BaseFieldProps } from './BaseField'
import React, { ReactNode } from 'react'
import { DatePicker } from '@mui/x-date-pickers'
import { TextField } from '@mui/material'
import dayjs from 'dayjs'

export interface TimePickerFieldProps extends BaseFieldProps {
    label: string
    value: any
    onChange: (value: string | undefined) => void
    error?: boolean
    helperText?: ReactNode
}

const DatePickerField = ({
    id,
    label,
    onChange,
    value,
    error,
    helperText,
}: TimePickerFieldProps) => {
    const dateFormat = 'YYYY-MM-DD'
    const validatedValue = value?.length === 8 ? value.substring(0, 5) : value
    const dateString = dayjs(validatedValue, dateFormat)

    return (
        <BaseField>
            <DatePicker
                label={label}
                value={dateString}
                onChange={(value) => onChange(value?.format(dateFormat))}
                renderInput={(params) => (
                    <TextField
                        id={id}
                        name={id}
                        fullWidth
                        error={error}
                        helperText={helperText}
                        {...params}
                    />
                )}
            />
        </BaseField>
    )
}

export default DatePickerField

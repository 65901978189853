import { LocationVisit, Train } from '../../../services/dst-api'
import useTrainsSchedules from './useTrainsSchedules'
import { Route } from '../../../data/routes'

export type RouteLocationVisits = {
    tiploc: string
    times: Array<LocationVisit | null>
}

export type RouteTrainsManager = {
    loading: boolean
    error?: Error
    visitsByLocation: RouteLocationVisits[]
}

export type UseRouteTrainsProps = {
    route: Route
    trains: Train[]
}

const useRouteTrains = ({
    trains,
    route,
}: UseRouteTrainsProps): RouteTrainsManager => {
    const { loading, error, getSchedule } = useTrainsSchedules({ trains })

    const getVisitAtLocation = (
        train: Train,
        tiploc: string
    ): LocationVisit | null => {
        const schedule = getSchedule(train)
        if (!schedule) return null

        const visit = schedule.find((visit) => visit.location.tiploc === tiploc)
        return visit ? visit : null
    }

    const visitsByLocation: RouteLocationVisits[] = route.locations.map(
        (location) => {
            return {
                tiploc: location.tiploc,
                times: trains.map((train) =>
                    getVisitAtLocation(train, location.tiploc)
                ),
            }
        }
    )

    return {
        visitsByLocation: visitsByLocation,
        loading: loading,
        error: error,
    }
}

export default useRouteTrains

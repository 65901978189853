import React from 'react'
import { styled } from '@mui/material/styles'

export type PlatformChipProps = {
    children: string | undefined
}

const Container = styled('div')(({ theme }) => ({
    paddingTop: theme.spacing(0.2),
    paddingBottom: theme.spacing(0.2),
    paddingLeft: '0',
    border: '1px solid',
    borderRadius: theme.spacing(0.5),
    display: 'inline-block',
    lineHeight: 1,
    width: '2.5em',
}))

const PlatformChip = ({ children }: PlatformChipProps) => {
    if (!children) return <></>

    return <Container>{children}</Container>
}

export default PlatformChip

/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Decision Support Tool API
 * Decision Support Tool Application
 * OpenAPI spec version: v0.0.1
 */
import {
  useQuery
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  Location,
  GetLocations400Item,
  GetSectionLines400OneItem,
  GetSectionLines400Two,
  GetSectionLinesParams
} from '.././model'
import { useCustomAxios } from '../../../utils/use-custom-axios';


/**
 * Retrieve all of the locations reference data
 * @summary Retrieve all locations
 */
export const useGetLocationsHook = () => {
        const getLocations = useCustomAxios<Location[]>();

        return (
    
 signal?: AbortSignal
) => {
        return getLocations(
          {url: `/reference/locations`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetLocationsQueryKey = () => [`/reference/locations`];

    
export type GetLocationsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetLocationsHook>>>>
export type GetLocationsQueryError = GetLocations400Item[]

export const useGetLocations = <TData = Awaited<ReturnType<ReturnType<typeof useGetLocationsHook>>>, TError = GetLocations400Item[]>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLocationsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetLocationsQueryKey();

  const getLocations =  useGetLocationsHook();


  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetLocationsHook>>>> = ({ signal }) => getLocations(signal);


  

  const query = useQuery<Awaited<ReturnType<ReturnType<typeof useGetLocationsHook>>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * Retrieve all of the lines for a location section
 * @summary Retrieve location section
 */
export const useGetSectionLinesHook = () => {
        const getSectionLines = useCustomAxios<string[]>();

        return (
    params: GetSectionLinesParams,
 signal?: AbortSignal
) => {
        return getSectionLines(
          {url: `/reference/lines`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetSectionLinesQueryKey = (params: GetSectionLinesParams,) => [`/reference/lines`, ...(params ? [params]: [])];

    
export type GetSectionLinesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetSectionLinesHook>>>>
export type GetSectionLinesQueryError = GetSectionLines400OneItem[] | GetSectionLines400Two

export const useGetSectionLines = <TData = Awaited<ReturnType<ReturnType<typeof useGetSectionLinesHook>>>, TError = GetSectionLines400OneItem[] | GetSectionLines400Two>(
 params: GetSectionLinesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSectionLinesHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSectionLinesQueryKey(params);

  const getSectionLines =  useGetSectionLinesHook();


  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetSectionLinesHook>>>> = ({ signal }) => getSectionLines(params, signal);


  

  const query = useQuery<Awaited<ReturnType<ReturnType<typeof useGetSectionLinesHook>>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


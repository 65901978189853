import { useParams } from 'react-router-dom'
import ErrorBox from '../../../../components/InformationBox/ErrorBox'
import React from 'react'
import Page from '../../../../components/Page/Page'
import SimulationResultsAnalysisView from './SimulationResultsAnalysisView'

const SimulationResultsAnalysisPage = () => {
    const { simulationId } = useParams()
    if (!simulationId)
        return (
            <ErrorBox message={'Simulation analysis could not be displayed.'} />
        )
    return (
        <Page
            headerProps={{
                title: 'Analysis',
            }}
        >
            <SimulationResultsAnalysisView
                simulationId={parseInt(simulationId)}
            />
        </Page>
    )
}

export default SimulationResultsAnalysisPage

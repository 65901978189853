import UpdateIcon from '@mui/icons-material/Update'
import NavigationIcon from '@mui/icons-material/Navigation'
import React, { ReactNode } from 'react'
import { Alert, Paper, Tooltip, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { Disabled } from '../../../../components/disabled/Disabled'

const VerticallyCentered = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    height: '100%',
}))

const ModeContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: '100%',
}))

const ModeButtonContainer = styled(Paper)(({ theme }) => ({
    aspectRatio: '1 / 1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: theme.spacing(2),
    alignItems: 'center',
    transition: 'ease background-color 250ms',
    ':hover': {
        backgroundColor: theme.palette.secondary.light,
    },
}))

const ModeButtonLabel = styled(Typography)(({ theme }) => ({
    ...theme.typography.button,
}))
const ModeButtonIcon = styled('div')(({ theme }) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    '& svg': {
        width: '68%',
        height: '80%',
        color: theme.palette.primary.dark,
    },
}))

type ModeSelectionButtonProps = {
    label: string
    children: ReactNode
    href: string
    disabled?: boolean
    alertText?: string
}

const ModeSelectionButton = ({
    label,
    children,
    href,
    disabled = false,
    alertText,
}: ModeSelectionButtonProps) => {
    return (
        <div>
            <Disabled disabled={disabled}>
                <Link to={href}>
                    <Tooltip title={`Enter ${label}`}>
                        <ModeButtonContainer>
                            <ModeButtonIcon>{children}</ModeButtonIcon>
                            <ModeButtonLabel>{label}</ModeButtonLabel>
                        </ModeButtonContainer>
                    </Tooltip>
                </Link>
            </Disabled>
            {alertText && <Alert severity="info">{alertText}</Alert>}
        </div>
    )
}

export const ModeSelectionView = () => {
    return (
        <VerticallyCentered>
            <ModeContainer>
                <ModeSelectionButton label="Live Mode" href="/live/overview">
                    <NavigationIcon />
                </ModeSelectionButton>
                <ModeSelectionButton label="Replay Mode" href="/replay">
                    {/*disabled alertText={"Replay Mode is not currently available."}>*/}
                    <UpdateIcon />
                </ModeSelectionButton>
            </ModeContainer>
        </VerticallyCentered>
    )
}

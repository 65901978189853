import React from 'react'
import ManageIncidentForm from './ManageIncidentForm'
import {
    pushErrorNotification,
    pushSuccessNotification,
} from '../../../../../utils/notification'
import { Incident, useEditIncident } from '../../../../../services/dst-api'
import { useNavigate } from 'react-router-dom'

type EditIncidentFormProps = {
    incident: Incident
}

const EditIncidentForm = ({ incident }: EditIncidentFormProps) => {
    const navigate = useNavigate()

    const updateIncidentMutation = useEditIncident({
        mutation: {
            onSuccess: () => {
                pushSuccessNotification('Incident updated successfully.')
                navigate(`../incidents`)
            },
            onError: () => {
                pushErrorNotification(`Error updating incident.`)
            },
        },
    })

    return (
        <ManageIncidentForm
            initialValues={incident}
            onSubmit={(formData) => {
                updateIncidentMutation.mutate({
                    data: {
                        ...formData,
                        id: incident.id,
                    },
                })
            }}
            isSaving={updateIncidentMutation.isLoading}
        />
    )
}

export default EditIncidentForm

/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Decision Support Tool API
 * Decision Support Tool Application
 * OpenAPI spec version: v0.0.1
 */
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  Incident,
  GetIncident400Item,
  GetIncidentParams,
  CreateIncident400Item,
  CreateIncidentRequest,
  DeleteIncident400Item,
  DeleteIncidentParams,
  EditIncident400Item,
  EditIncidentRequest,
  GetIncidents400Item,
  GetIncidentsParams
} from '.././model'
import { useCustomAxios } from '../../../utils/use-custom-axios';


/**
 * This method retrieves a specified incident by id
 * @summary Get simulation incident
 */
export const useGetIncidentHook = () => {
        const getIncident = useCustomAxios<Incident>();

        return (
    params: GetIncidentParams,
 signal?: AbortSignal
) => {
        return getIncident(
          {url: `/simulation/incident`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetIncidentQueryKey = (params: GetIncidentParams,) => [`/simulation/incident`, ...(params ? [params]: [])];

    
export type GetIncidentQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetIncidentHook>>>>
export type GetIncidentQueryError = GetIncident400Item[]

export const useGetIncident = <TData = Awaited<ReturnType<ReturnType<typeof useGetIncidentHook>>>, TError = GetIncident400Item[]>(
 params: GetIncidentParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetIncidentHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetIncidentQueryKey(params);

  const getIncident =  useGetIncidentHook();


  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetIncidentHook>>>> = ({ signal }) => getIncident(params, signal);


  

  const query = useQuery<Awaited<ReturnType<ReturnType<typeof useGetIncidentHook>>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * Create a new incident
 * @summary Create incident
 */
export const useCreateIncidentHook = () => {
        const createIncident = useCustomAxios<Incident>();

        return (
    createIncidentRequest: CreateIncidentRequest,
 ) => {
        return createIncident(
          {url: `/simulation/incident`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createIncidentRequest
    },
          );
        }
      }
    


    export type CreateIncidentMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateIncidentHook>>>>
    export type CreateIncidentMutationBody = CreateIncidentRequest
    export type CreateIncidentMutationError = CreateIncident400Item[]

    export const useCreateIncident = <TError = CreateIncident400Item[],
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateIncidentHook>>>, TError,{data: CreateIncidentRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      const createIncident =  useCreateIncidentHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateIncidentHook>>>, {data: CreateIncidentRequest}> = (props) => {
          const {data} = props ?? {};

          return  createIncident(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof createIncident>>, TError, {data: CreateIncidentRequest}, TContext>(mutationFn, mutationOptions);
    }
    /**
 * Delete an existing incident of a simulation
 * @summary Delete an incident
 */
export const useDeleteIncidentHook = () => {
        const deleteIncident = useCustomAxios<void>();

        return (
    params: DeleteIncidentParams,
 ) => {
        return deleteIncident(
          {url: `/simulation/incident`, method: 'delete',
        params
    },
          );
        }
      }
    


    export type DeleteIncidentMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteIncidentHook>>>>
    
    export type DeleteIncidentMutationError = DeleteIncident400Item[]

    export const useDeleteIncident = <TError = DeleteIncident400Item[],
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteIncidentHook>>>, TError,{params: DeleteIncidentParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      const deleteIncident =  useDeleteIncidentHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteIncidentHook>>>, {params: DeleteIncidentParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteIncident(params,)
        }

        

      return useMutation<Awaited<ReturnType<typeof deleteIncident>>, TError, {params: DeleteIncidentParams}, TContext>(mutationFn, mutationOptions);
    }
    /**
 * Edit an existing incident
 * @summary Edit incident
 */
export const useEditIncidentHook = () => {
        const editIncident = useCustomAxios<Incident>();

        return (
    editIncidentRequest: EditIncidentRequest,
 ) => {
        return editIncident(
          {url: `/simulation/incident`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: editIncidentRequest
    },
          );
        }
      }
    


    export type EditIncidentMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useEditIncidentHook>>>>
    export type EditIncidentMutationBody = EditIncidentRequest
    export type EditIncidentMutationError = EditIncident400Item[]

    export const useEditIncident = <TError = EditIncident400Item[],
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useEditIncidentHook>>>, TError,{data: EditIncidentRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      const editIncident =  useEditIncidentHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useEditIncidentHook>>>, {data: EditIncidentRequest}> = (props) => {
          const {data} = props ?? {};

          return  editIncident(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof editIncident>>, TError, {data: EditIncidentRequest}, TContext>(mutationFn, mutationOptions);
    }
    /**
 * This method retrieves all incidents for a specified simulation
 * @summary Get simulation incidents
 */
export const useGetIncidentsHook = () => {
        const getIncidents = useCustomAxios<Incident[]>();

        return (
    params: GetIncidentsParams,
 signal?: AbortSignal
) => {
        return getIncidents(
          {url: `/simulation/incidents`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetIncidentsQueryKey = (params: GetIncidentsParams,) => [`/simulation/incidents`, ...(params ? [params]: [])];

    
export type GetIncidentsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetIncidentsHook>>>>
export type GetIncidentsQueryError = GetIncidents400Item[]

export const useGetIncidents = <TData = Awaited<ReturnType<ReturnType<typeof useGetIncidentsHook>>>, TError = GetIncidents400Item[]>(
 params: GetIncidentsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetIncidentsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetIncidentsQueryKey(params);

  const getIncidents =  useGetIncidentsHook();


  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetIncidentsHook>>>> = ({ signal }) => getIncidents(params, signal);


  

  const query = useQuery<Awaited<ReturnType<ReturnType<typeof useGetIncidentsHook>>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


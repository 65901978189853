import React, { ReactNode } from 'react'
import { TextField } from '@mui/material'
import BaseField, { BaseFieldWithChangeHandlerProps } from './BaseField'
import { BaseTextFieldProps } from '@mui/material/TextField/TextField'

export interface SelectFieldProps
    extends BaseFieldWithChangeHandlerProps,
        Omit<
            BaseTextFieldProps,
            | 'id'
            | 'name'
            | 'fullWidth'
            | 'select'
            | 'label'
            | 'value'
            | 'variant'
        > {
    label: string
    value: any
    children: ReactNode
}

export const SelectField = ({
    id,
    label,
    onChange,
    children,
    ...rest
}: SelectFieldProps) => {
    return (
        <BaseField>
            <TextField
                id={id}
                name={id}
                fullWidth
                select
                label={label}
                onChange={onChange}
                variant="outlined"
                {...rest}
            >
                {children}
            </TextField>
        </BaseField>
    )
}

import { QuickInfoChipProps } from '../components/QuickInfoChip/QuickInfoChip'
import useSimulationContext from '../../Simulation/hooks/useSimulationContext'

const useSimulationQuickInfo = (): Array<QuickInfoChipProps> => {
    let { simulation } = useSimulationContext()

    if (!simulation) return []

    return [
        {
            type: 'SIMULATION',
            simulation: simulation,
        },
    ]
}

export default useSimulationQuickInfo

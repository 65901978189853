import { Simulation, useGetSimulations } from '../../../services/dst-api'

const usePreviousLiveSimulation = () => {
    const isComplete = (simulation: Simulation | undefined) =>
        simulation && simulation.status === 'COMPLETE'
    const latestSimulationComparator = (a: Simulation, b: Simulation) => {
        if (a.id === undefined) return b
        if (b.id === undefined) return a
        return a.id > b.id ? a : b
    }

    // retrieve last completed live simulation
    const simulationsQuery = useGetSimulations()
    const completedLiveSimulations = simulationsQuery.data?.filter(
        (simulation: Simulation) => {
            return simulation.type === 'LIVE' && isComplete(simulation)
        }
    )
    const simulation =
        completedLiveSimulations && completedLiveSimulations.length > 0
            ? completedLiveSimulations.reduce((prev, current) =>
                  latestSimulationComparator(prev, current)
              )
            : undefined

    const isLoading = simulationsQuery.isLoading || simulationsQuery.isFetching
    const isError = simulationsQuery.isError

    return {
        isLoading,
        isError,
        simulation,
    }
}

export default usePreviousLiveSimulation

import theme from './theme/theme'
import { ConfirmProvider } from 'material-ui-confirm'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { SnackbarProvider } from 'notistack'
import { BrowserRouter } from 'react-router-dom'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { PrivateRoutes } from './routes/PrivateRoutes'
import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import './assets/scss/index.css'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Auth0ProviderWithNavigate } from './components/ProtectedRoute/Auth0ProviderWithNavigate'
import 'dayjs/locale/en-gb'
import dayjs from 'dayjs'
import { configureAbly } from '@ably-labs/react-hooks'

const getApiKey = () => process.env.REACT_APP_ABLY_API_KEY // todo api key should be retrieved from backend (when a user logs in?)
const getRandomClientId = () => 'user1' //todo generate a random client id, only required if usePresence is required
configureAbly({
    key: getApiKey(),
    clientId: getRandomClientId(),
})

function App() {
    const queryClient = new QueryClient({})
    dayjs.locale('en-gb')

    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
                <CssBaseline enableColorScheme />
                <BrowserRouter>
                    <Auth0ProviderWithNavigate>
                        <ConfirmProvider>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <SnackbarProvider maxSnack={1}>
                                    <PrivateRoutes />
                                    <ToastContainer />
                                    <ReactQueryDevtools initialIsOpen={true} />
                                </SnackbarProvider>
                            </LocalizationProvider>
                        </ConfirmProvider>
                    </Auth0ProviderWithNavigate>
                </BrowserRouter>
            </ThemeProvider>
        </QueryClientProvider>
    )
}

export default App

import React from 'react'
import { MenuItem } from '@mui/material'
import { SelectField, SelectFieldProps } from './base/SelectField'
import incidentTypes from '../../../data/incidentTypes'

type IncidentTypeFieldProps = Omit<SelectFieldProps, 'children' | 'label'>

const IncidentTypeField = ({
    id,
    onChange,
    value,
    ...rest
}: IncidentTypeFieldProps) => {
    const listItems = incidentTypes.map((incidentType) => {
        return (
            <MenuItem key={incidentType.id} value={incidentType.id}>
                {incidentType.name}
            </MenuItem>
        )
    })

    return (
        <SelectField
            id={id}
            value={value}
            onChange={onChange}
            label="Incident Type"
            {...rest}
        >
            {listItems}
        </SelectField>
    )
}

export default IncidentTypeField

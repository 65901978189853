import React from 'react'
import TrainChart from '../TrainChart/TrainChart'
import { SimulatedJourney } from '../../../../services/dst-api'
import { Stack, Typography } from '@mui/material'
import { AllTrainGraphSettings } from '../../hooks/useTrainGraphSettings'

interface TrainChartsProps {
    settings: AllTrainGraphSettings
    journeys: SimulatedJourney[]
}

const TrainCharts = ({ settings, journeys }: TrainChartsProps) => {
    const sections = settings.sections.enabledSections
    return (
        <>
            {sections.map((section, index) => {
                const height = settings.getChartHeight(section)

                return (
                    <Stack
                        key={section.name}
                        direction={'row'}
                        sx={{ height: height }}
                    >
                        <Typography
                            sx={{
                                writingMode: 'vertical-rl',
                                textOrientation: 'mixed',
                                textAlign: 'center',
                                backgroundColor: 'primary.light',
                                borderTopWidth: index === 0 ? '0px' : '1px',
                                borderTopStyle: 'solid',
                                textOverflow: 'clip',
                            }}
                        >
                            {section.name}
                        </Typography>
                        <div style={{ flex: 1 }}>
                            <TrainChart
                                key={section.name}
                                route={section.locations}
                                displayXAxis={index === 0}
                                settings={settings}
                                journeys={journeys}
                            />
                        </div>
                    </Stack>
                )
            })}
        </>
    )
}

export default TrainCharts

import Axios, { AxiosRequestConfig } from 'axios'
import { useAuth0 } from '@auth0/auth0-react'

export const AXIOS_INSTANCE = Axios.create({
    baseURL: process.env.REACT_APP_BACKEND_SERVICE_API,
})

export const useCustomAxios = <T>(): ((
    config: AxiosRequestConfig
) => Promise<T>) => {
    const { getAccessTokenSilently } = useAuth0()

    return async (config: AxiosRequestConfig) => {
        const token = await getAccessTokenSilently()

        const source = Axios.CancelToken.source()
        const promise = AXIOS_INSTANCE({
            ...config,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            cancelToken: source.token,
        }).then(({ data }) => data)

        // @ts-ignore
        promise.cancel = () => {
            source.cancel('Query was cancelled by React Query')
        }

        return promise
    }
}

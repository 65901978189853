import {
    SelectField,
    SelectFieldProps,
} from '../../../../../components/fields/fields/base/SelectField'
import { MenuItem } from '@mui/material'
import React from 'react'

type JourneyTypeFieldProps = Omit<SelectFieldProps, 'children'>

export type JourneyType = {
    id:
        | 'none'
        | 'base-amended'
        | 'base-baseline'
        | 'amended'
        | 'baseline'
        | 'all-amended'
        | 'all-baseline'
        | 'actual'
    name: string
}

const JourneyTypeField = ({
    id,
    onChange,
    value,
    ...rest
}: JourneyTypeFieldProps) => {
    const journeyTypes: JourneyType[] = [
        {
            id: 'none',
            name: 'No Comparison',
        },
        // {
        //     id: 'base-baseline',
        //     name: 'Base',
        // },
        // {
        //     id: 'base-amended',
        //     name: 'Base (Amended)',
        // },
        {
            id: 'baseline',
            name: 'Notified',
        },
        {
            id: 'amended',
            name: 'Notified (Amended)',
        },
        // {
        //     id: 'all-baseline',
        //     name: 'All',
        // },
        // {
        //     id: 'all-amended',
        //     name: 'All (Amended)',
        // },
        // {
        //     id: 'actual',
        //     name: 'Actual',
        // },
    ]

    const listItems = journeyTypes.map((incidentType) => {
        return (
            <MenuItem key={incidentType.id} value={incidentType.id}>
                {incidentType.name}
            </MenuItem>
        )
    })

    return (
        <SelectField id={id} value={value} onChange={onChange} {...rest}>
            {listItems}
        </SelectField>
    )
}

export default JourneyTypeField

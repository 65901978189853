import React from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { Incident } from '../../../../services/dst-api'
import ActionsCell, {
    ListAction,
} from '../../../../components/BaseTable/ActionsCell'
import TimeCell from '../../../../components/BaseTable/TimeCell'

export interface IncidentListProps {
    incidents: Incident[]
    actions: ListAction<Incident>[]
}

export const IncidentsList = ({ incidents, actions }: IncidentListProps) => {
    return (
        <React.Fragment>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Incident</TableCell>
                        <TableCell>From Loc</TableCell>
                        <TableCell>To Loc</TableCell>
                        <TableCell>Line</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Start</TableCell>
                        <TableCell>End</TableCell>
                        {actions.length > 0 && (
                            <TableCell align="right">Actions</TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {incidents.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell>{row.incidentId}</TableCell>
                            <TableCell>{row.fromLocation}</TableCell>
                            <TableCell>{row.toLocation}</TableCell>
                            <TableCell>{row.line}</TableCell>
                            <TableCell>{row.type}</TableCell>
                            <TimeCell>{row.startTime}</TimeCell>
                            <TimeCell>{row.endTime}</TimeCell>
                            {actions.length > 0 && (
                                <ActionsCell actions={actions} data={row} />
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </React.Fragment>
    )
}

import { Chart } from 'react-google-charts'
import React from 'react'
import { Grid } from '../../../../../../services/dst-api'
import { Container } from '@mui/material'

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    if (value === null || value === undefined) return false
    const testDummy: TValue = value
    return true
}

const AnalysisChart = ({ data }: { data: Grid }) => {
    const seriesColumns = data.columns
        .map((column, index) => {
            if (column.dataType === 'INTEGER') return index
            return null
        })
        .filter(notEmpty)

    const chartData = data.data.map((item) => {
        const xValue = item[0]
        const yValues = seriesColumns.map((index) => Number(item[index]))
        return [xValue, ...yValues]
    })
    const xHeading = data.columns[0].title
    const yHeadings = seriesColumns.map((index) => data.columns[index].title)
    const headings = [xHeading, ...yHeadings]

    const chartHeight = '400px'
    return (
        <Container sx={{ minHeight: chartHeight }}>
            <Chart
                chartType="ColumnChart"
                width="100%"
                height={chartHeight}
                data={[headings, ...chartData]}
                options={{
                    hAxis: {
                        title: xHeading,
                    },
                    animation: {
                        startup: true,
                        duration: 300,
                        easing: 'out',
                    },
                }}
            />
        </Container>
    )
}

export default AnalysisChart

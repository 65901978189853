import React, { ReactNode, useRef, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { Box } from '@mui/material'

const DraggableTimeAdjuster = ({
    border = 0,
    children,
    onChange,
    time,
    period,
}: {
    border?: number
    children: ReactNode
    onChange: (time: Dayjs) => void
    time: dayjs.Dayjs
    period: number
}) => {
    const ourRef = useRef<HTMLDivElement>(null)
    const [isMouseDown, setIsMouseDown] = useState(false)
    const [initialTime, setInitialTime] = useState<Dayjs | null>(null)
    const mouseCoords = useRef({
        startX: 0,
    })
    const handleDragStart: React.MouseEventHandler<HTMLDivElement> = (e) => {
        if (!ourRef.current) return
        // @ts-ignore
        const startX = e.pageX
        mouseCoords.current = { startX }
        setIsMouseDown(true)
        setInitialTime(time)
        document.body.style.cursor = 'grabbing'
    }
    const handleDragEnd: React.MouseEventHandler<HTMLDivElement> = () => {
        setIsMouseDown(false)
        if (!ourRef.current) return
        document.body.style.cursor = 'default'
        setInitialTime(null)
    }
    const handleDrag: React.MouseEventHandler<HTMLDivElement> = (e) => {
        if (!isMouseDown || !ourRef.current) return
        e.preventDefault()
        // @ts-ignore
        const x = e.pageX
        const pixelDelta = x - mouseCoords.current.startX
        const timeDelta = (pixelDelta / ourRef.current.clientWidth) * period

        const newTime = initialTime!!.subtract(timeDelta, 'minutes')
        if (Math.abs(newTime.diff(time, 'minute')) > 0) onChange(newTime!!)
    }

    return (
        <Box
            ref={ourRef}
            onMouseDown={handleDragStart}
            onMouseUp={handleDragEnd}
            onMouseMove={handleDrag}
            className={'flex overflow-x-scroll'}
            sx={{ border: border }}
        >
            {children}
        </Box>
    )
}

export default DraggableTimeAdjuster

import { InputAdornment, TextField } from '@mui/material'
import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { debounce } from 'lodash'
import { SearchRounded } from '@mui/icons-material'

type SearchBarProps = { entityDescription: string }

function GenericSearchBar({ entityDescription }: SearchBarProps) {
    const [search, setSearch] = useSearchParams()

    const onSearchChange = debounce(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const text = e.target.value

            if (text.length === 0) {
                search.delete('query')
                setSearch(search, {
                    replace: true,
                })
            } else {
                search.set('query', text)
                setSearch(search, {
                    replace: true,
                })
            }
        },
        300
    )
    return (
        <TextField
            variant="standard"
            defaultValue={search.get('query' || '')}
            onChange={onSearchChange}
            placeholder={`Search ${entityDescription}...`}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchRounded />
                    </InputAdornment>
                ),
            }}
        />
    )
}
export default GenericSearchBar

import { styled } from '@mui/material/styles'
import React from 'react'

export interface BaseFieldProps {
    id: string
}

export interface BaseFieldWithChangeHandlerProps extends BaseFieldProps {
    onChange: {
        (e: React.ChangeEvent<any>): void
        <T_1 = string | React.ChangeEvent<any>>(
            field: T_1
        ): T_1 extends React.ChangeEvent<any>
            ? void
            : (e: string | React.ChangeEvent<any>) => void
    }
}

const BaseField = styled('div')(() => ({
    // marginTop: theme.spacing(3)
}))

export default BaseField

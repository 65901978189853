import React, { useState } from 'react'
import { TrainsListProps } from '../TrainsList'
import TrainLineupGrid from './components/TrainLineupGrid'
import useTrainLineup from '../../../hooks/useTrainLineup'
import TrainLineupPager from './components/TrainLineupPager'
import EditableTrainCard from '../EditableTrainCard/EditableTrainCard'
import useRoute from '../../../hooks/useRoute'
import { Paper, Stack } from '@mui/material'
import { Train } from '../../../../../services/dst-api'

type TrainLineupProps = {
    pageSize: number
    mode: 'card' | 'grid'
    editable: boolean
} & TrainsListProps

const TrainLineup = ({
    trains: allTrains,
    pageSize,
    mode,
    editable,
}: TrainLineupProps) => {
    const [editing, setEditing] = useState<Train | undefined>(undefined)
    const { trains, setPage, currentPage, pages } = useTrainLineup({
        trains: allTrains,
        pageSize: pageSize,
    })

    // TODO route selection
    const { route } = useRoute(1)

    const handleOpenEditor = (train: Train) => {
        setEditing(train)
    }
    const handleCloseEditor = () => {
        setEditing(undefined)
    }

    if (editing !== undefined) {
        return (
            <EditableTrainCard
                trains={[editing]}
                onTrainDelete={() => {}}
                editor={
                    editable
                        ? {
                              onOpenEditor: handleOpenEditor,
                              onCloseEditor: handleCloseEditor,
                              editing: true,
                          }
                        : undefined
                }
            />
        )
    }

    return (
        <Stack gap={2}>
            <TrainLineupPager
                defaultValue={currentPage}
                pages={pages}
                onChange={setPage}
            />
            {mode === 'grid' && (
                <Paper>
                    <TrainLineupGrid
                        trains={trains}
                        route={route}
                        onOpenEditor={editable ? handleOpenEditor : undefined}
                    />
                </Paper>
            )}
            {mode === 'card' && (
                <EditableTrainCard
                    trains={trains}
                    onTrainDelete={() => {}}
                    editor={
                        editable
                            ? {
                                  onOpenEditor: handleOpenEditor,
                                  onCloseEditor: handleCloseEditor,
                                  editing: editing !== undefined,
                              }
                            : undefined
                    }
                />
            )}
        </Stack>
    )
}

export default TrainLineup

type Network = {
    id: string
    name: string
}

const networks: Network[] = [
    {
        id: 'Crossrail',
        name: 'Crossrail',
    },
]

export default networks

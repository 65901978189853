import { TableCellProps } from '@mui/material/TableCell/TableCell'
import { TableCell } from '@mui/material'
import React from 'react'
import { formatTime } from '../../utils/timeUtils'

type TimeTableCellProps = {
    children?: string
} & TableCellProps

const TimeCell = ({ children, ...props }: TimeTableCellProps) => {
    return (
        <TableCell align="right" {...props}>
            {children ? formatTime(children) : ''}
        </TableCell>
    )
}

export default TimeCell

import React from 'react'
import SwaggerUI from 'swagger-ui-react'
import 'swagger-ui-react/swagger-ui.css'
import { useAuth0 } from '@auth0/auth0-react'

const BackendDocumentation = () => {
    const { getAccessTokenSilently } = useAuth0()
    const oasUrl = process.env.REACT_APP_BACKEND_SERVICE_API!!.replace(
        '/api',
        '/docs'
    )

    return (
        <SwaggerUI
            url={oasUrl}
            requestInterceptor={async (request) => {
                const bearerToken = await getAccessTokenSilently()
                request.headers.Authorization = `Bearer ${bearerToken}`
                return request
            }}
        />
    )
}
export default BackendDocumentation

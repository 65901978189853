import React from 'react'
import { Grid, Paper } from '@mui/material'
import OutcomeMeasuresGrid from './components/OutcomeMeasuresGrid/OutcomeMeasuresGrid'
import { styled } from '@mui/material/styles'
import useSimulationResults from '../hooks/useSimulationResults'
import LoadingBox from '../../../components/InformationBox/LoadingBox'
import ErrorBox from '../../../components/InformationBox/ErrorBox'
import InformationBox from '../../../components/InformationBox/InformationBox'
import ServiceAmendmentDescriptionsGrid from './components/ServiceAmendmentsGrid/ServiceAmendmentDescriptionsGrid'
import SimulationOverviewGrid from './components/SimulationOverviewGrid/SimulationOverviewGrid'

const ResultSection = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
}))

type SimulationResultsViewProps = {
    simulationId: number
}

const SimulationResultsView = ({
    simulationId,
}: SimulationResultsViewProps) => {
    const { isLoading, isError, simulation, simulationResults } =
        useSimulationResults(simulationId)

    if (isLoading) {
        return <LoadingBox />
    }

    if (isError) {
        return (
            <ErrorBox message={'Simulation results could not be retrieved.'} />
        )
    }

    if (!simulation) {
        return <ErrorBox message={'Simulation could not be found.'} />
    }

    if (!simulationResults) {
        return (
            <InformationBox
                title={'No Simulation Results'}
                message={
                    'Simulation is in progress - no results have yet been published.'
                }
            />
        )
    }

    return (
        <Grid container spacing={3}>
            {/* Outcome Measures */}
            <Grid item xs={12}>
                <ResultSection>
                    <SimulationOverviewGrid
                        simulation={simulation}
                        results={simulationResults}
                        showDescription={true}
                    />
                </ResultSection>
            </Grid>

            {/* Outcome Measures */}
            <Grid item xs={12}>
                <ResultSection>
                    <OutcomeMeasuresGrid results={simulationResults} />
                </ResultSection>
            </Grid>

            {/* Service Amendments */}
            <Grid item xs={12}>
                <ResultSection>
                    <ServiceAmendmentDescriptionsGrid
                        amendmentDescriptions={
                            simulationResults.withAmendments
                                .serviceAmendmentDescriptions
                        }
                    />
                </ResultSection>
            </Grid>
        </Grid>
    )
}

export default SimulationResultsView

import { MenuItem, Select, Stack, Typography } from '@mui/material'
import { ArrowDown, ArrowUp } from 'react-feather'
import React from 'react'
import { SimulationSortField } from '../../../hooks/useSimulations'
import { TrainsListSortField } from '../../../types/TrainsListSortField'

export type SortFieldGenericType = TrainsListSortField | SimulationSortField

type GenericSortSelectProps<T extends SortFieldGenericType> = {
    value: T
    updateSort: (value: T) => void
    labels: Record<T, string>
}

function GenericSortSelect<T extends SortFieldGenericType>({
    value,
    updateSort,
    labels,
}: GenericSortSelectProps<T>) {
    return (
        <Select
            value={value}
            variant={'standard'}
            label="Sort"
            onChange={(event) => updateSort(event.target.value as T)}
        >
            {(Object.keys(labels) as Array<T>).map((field) => {
                const contents = labels[field]
                return (
                    <MenuItem key={field} value={field}>
                        {contents}
                    </MenuItem>
                )
            })}
        </Select>
    )
}

export type GenericSort<T extends SortFieldGenericType> = {
    field: T
    direction: SortDirection
}

export type GenericSortBoxProps<T extends SortFieldGenericType> = {
    value: GenericSort<T>
    updateSort: (value: GenericSort<T>) => void
    labels: GenericSortSelectProps<T>['labels']
}

export enum SortDirection {
    ASC = 'asc',
    DESC = 'desc',
}

function GenericSortBox<T extends SortFieldGenericType>({
    value,
    updateSort,
    labels,
}: GenericSortBoxProps<T>) {
    const updateSortField = (field: T) => {
        updateSort({
            ...value,
            field: field,
        })
    }

    const toggleSortDirection = () => {
        updateSort({
            ...value,
            direction:
                value.direction === SortDirection.DESC
                    ? SortDirection.ASC
                    : SortDirection.DESC,
        })
    }

    return (
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <Typography color={'textSecondary'}>Sort by</Typography>
            <GenericSortSelect
                value={value.field}
                updateSort={updateSortField}
                labels={labels}
            />
            {value.direction === SortDirection.ASC && (
                <ArrowDown onClick={() => toggleSortDirection()} />
            )}
            {value.direction === SortDirection.DESC && (
                <ArrowUp onClick={() => toggleSortDirection()} />
            )}
        </Stack>
    )
}

export default GenericSortBox

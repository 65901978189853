import React from 'react'
import { Simulation, SimulationResults } from '../../../../../services/dst-api'
import { formatDateTime, formatTime } from '../../../../../utils/timeUtils'
import PropertiesGrid from '../../../../../components/PropertiesGrid/PropertiesGrid'
import ResultSectionTitle from '../ResultSectionTitle/ResultSectionTitle'

type SimulationOverviewGridProps = {
    simulation: Simulation
    results?: SimulationResults
    title?: string
    showDescription?: boolean
}

const SimulationOverviewGrid = ({
    simulation,
    results,
    title = 'Overview',
    showDescription = false,
}: SimulationOverviewGridProps) => {
    const startTime = formatTime(simulation.startTime, false)
    const endTime = formatTime(simulation.endTime, false)

    const properties = [
        { label: 'ID', value: simulation.id!! },
        {
            label: 'Date',
            value: `${simulation.date} (${startTime} to ${endTime})`,
        },
        { label: 'Title', value: simulation.title },
        { label: 'Network', value: simulation.network },
    ]
    if (showDescription && simulation.description)
        properties.push({ label: 'Description', value: simulation.description })

    if (results) {
        const runAt = formatDateTime(results.createdAt)
        properties.push({ label: 'Run At', value: runAt })
    }

    return (
        <React.Fragment>
            {title !== undefined && (
                <ResultSectionTitle>{title}</ResultSectionTitle>
            )}
            <PropertiesGrid properties={properties} />
        </React.Fragment>
    )
}

export default SimulationOverviewGrid

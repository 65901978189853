import {
    getGetLiveTrainsQueryKey,
    getGetVisitsQueryKey,
    LocationVisit,
    useCancelFrom,
    useCancelTo,
    useRunFastFrom,
    useRunFastTo,
    useToggleStop,
} from '../../../services/dst-api'
import useTrainSchedule, { UseTrainScheduleResults } from './useTrainSchedule'
import {
    pushErrorNotification,
    pushSuccessNotification,
} from '../../../utils/notification'
import { UseMutationResult, useQueryClient } from '@tanstack/react-query'
import useJourneyOperation, {
    UseJourneyOperationResult,
} from './useJourneyOperation'

type JourneyOperationType =
    | 'CANCEL_FROM'
    | 'CANCEL_TO'
    | 'RUNFAST_FROM'
    | 'RUNFAST_TO'
    | 'TOGGLE_STOP'

export type JourneyOperation = {
    submit: (visit: LocationVisit) => void
    operation: UseJourneyOperationResult
}

export type JourneyOperations = Record<JourneyOperationType, JourneyOperation>

type UseModifiableTrainScheduleResults = {
    schedule: UseTrainScheduleResults
    operations: {
        journey: JourneyOperations
    }
}

const useModifiableTrainSchedule = (
    trainId: number
): UseModifiableTrainScheduleResults => {
    const schedule = useTrainSchedule(trainId)
    const queryClient = useQueryClient()
    const invalidateVisits = () =>
        queryClient.invalidateQueries(getGetVisitsQueryKey(trainId))
    const invalidateTrains = () =>
        queryClient.invalidateQueries(getGetLiveTrainsQueryKey())

    const mutation = {
        onSuccess: () => {
            pushSuccessNotification('Journey modified successfully')
            invalidateVisits()
            invalidateTrains()
        },
        onError: () => {
            pushErrorNotification(`Error modifying journey.`)
        },
    }

    const cancelFrom = useCancelFrom()
    const cancelTo = useCancelTo()
    const runFastFrom = useRunFastFrom()
    const runFastTo = useRunFastTo()
    const toggleStop = useToggleStop()

    const submit = (
        hook: UseMutationResult<
            void,
            unknown,
            { trainId: number; visitIndex: number; data: any }
        >,
        visit: LocationVisit
    ) =>
        hook.mutate(
            {
                trainId: trainId,
                visitIndex: visit.sequence,
                data: {},
            },
            mutation
        )

    const operations: JourneyOperations = {
        CANCEL_FROM: {
            submit: (visit: LocationVisit) => submit(cancelFrom, visit),
            operation: useJourneyOperation('CANCEL', 'FROM'),
        },
        CANCEL_TO: {
            submit: (visit: LocationVisit) => submit(cancelTo, visit),
            operation: useJourneyOperation('CANCEL', 'TO'),
        },
        RUNFAST_FROM: {
            submit: (visit: LocationVisit) => submit(runFastFrom, visit),
            operation: useJourneyOperation('RUNFAST', 'FROM'),
        },
        RUNFAST_TO: {
            submit: (visit: LocationVisit) => submit(runFastTo, visit),
            operation: useJourneyOperation('RUNFAST', 'TO'),
        },
        TOGGLE_STOP: {
            submit: (visit: LocationVisit) => submit(toggleStop, visit),
            operation: useJourneyOperation('TOGGLE_STOP'),
        },
    }

    return {
        schedule: schedule,
        operations: {
            journey: operations,
        },
    }
}

export default useModifiableTrainSchedule

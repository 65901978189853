import React from 'react'
import { Container, Grid } from '@mui/material'
import InformationBox from '../../../../../../../components/InformationBox/InformationBox'
import SimulationCard from './SimulationCard'
import { BaseSimulationsDisplayProps } from '../SimulationsDisplay'

type SimulationsGridProps = BaseSimulationsDisplayProps

function SimulationsGrid({
    simulations,
    onSimulationDelete,
    onSimulationExecute,
    onSimulationCancel,
    onSimulationResume,
    onSimulationRerun,
}: SimulationsGridProps) {
    if (simulations.length === 0) {
        return (
            <InformationBox
                title={'No Simulations'}
                message={
                    'No simulations exist. Click the button above to create one.'
                }
            />
        )
    }

    return (
        <Container maxWidth="lg">
            <Grid container spacing={4}>
                {simulations.map((simulation) => (
                    <Grid item key={simulation.id} xs={12} md={4} lg={4}>
                        <SimulationCard
                            simulation={simulation}
                            onSimulationDelete={onSimulationDelete}
                            onSimulationExecute={onSimulationExecute}
                            onSimulationCancel={onSimulationCancel}
                            onSimulationResume={onSimulationResume}
                            onSimulationRerun={onSimulationRerun}
                        />
                    </Grid>
                ))}
            </Grid>
        </Container>
    )
}
export default SimulationsGrid

import React from 'react'
import { styled } from '@mui/material/styles'
import { Button, Divider } from '@mui/material'
import TrainTimetableGrid from '../../TimetableGrid/TrainTimetableGrid'
import TrainListItem from '../components/TrainListItem'
import { EditableTrainsListProps } from '../TrainsList'

const List = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    flexWrap: 'wrap',
}))

const ListItem = styled('div')(() => ({
    flex: 1,
}))

const EditableTrainCard = ({
    trains,
    onTrainDelete,
    editor,
}: EditableTrainsListProps) => {
    const listItems = trains.map((train) => {
        return (
            <ListItem key={train.id}>
                <TrainListItem
                    train={train}
                    collapsible={false}
                    orientation={'vertical'}
                >
                    <>
                        <Button
                            onClick={() => {
                                if (!editor) return
                                editor.editing
                                    ? editor.onCloseEditor()
                                    : editor?.onOpenEditor(train)
                            }}
                        >
                            {editor?.editing ? 'Close Editor' : 'Edit Journey'}
                        </Button>
                        <Divider
                            orientation="horizontal"
                            variant="middle"
                            flexItem
                        />
                        <TrainTimetableGrid
                            trainId={train.id}
                            mode={editor?.editing ? 'wide' : 'compact'}
                        />
                    </>
                </TrainListItem>
            </ListItem>
        )
    })

    return <List>{listItems}</List>
}

export default EditableTrainCard

import React from 'react'
import InformationBox from '../../../../components/InformationBox/InformationBox'
import ErrorBox from '../../../../components/InformationBox/ErrorBox'
import LoadingBox from '../../../../components/InformationBox/LoadingBox'
import useModifiableTrainSchedule from '../../hooks/useModifiableTrainSchedule'
import { CompactVisitsGrid, WideVisitsGrid } from './components/VisitsGrid'

export interface TrainTimetableGridProps {
    trainId: number
    mode?: 'wide' | 'compact'
}

const TrainTimetableGrid = ({
    trainId,
    mode = 'wide',
}: TrainTimetableGridProps) => {
    const { schedule, operations } = useModifiableTrainSchedule(trainId)

    if (schedule.isLoading) {
        return <LoadingBox />
    }

    if (schedule.isError) {
        return (
            <ErrorBox message={'The train schedule could not be retrieved.'} />
        )
    }

    if (schedule.visits.length === 0) {
        return (
            <InformationBox
                title={'No Visits'}
                message={
                    'The train schedule appears to be empty. If this is incorrect please contact your system administrator.'
                }
            />
        )
    }

    switch (mode) {
        case 'wide':
            return (
                <WideVisitsGrid
                    visits={schedule.visits}
                    operations={operations.journey}
                />
            )
        case 'compact':
            return <CompactVisitsGrid visits={schedule.visits} />
    }
}

export default TrainTimetableGrid

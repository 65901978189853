import React from 'react'
import Page from '../../../../components/Page/Page'
import ManageSimulationForm from './components/ManageSimulationForm'
import { useGetSimulation } from '../../../../services/dst-api'
import { useParams } from 'react-router-dom'
import { CommonSkeletonForm } from '../../../../components/fields/CommonForm'
import ErrorBox from '../../../../components/InformationBox/ErrorBox'

function EditSimulation() {
    let { simulationId } = useParams()

    const getSimulationQuery = useGetSimulation({
        simulationId: Number(simulationId),
    })

    if (getSimulationQuery.isLoading) {
        return <CommonSkeletonForm />
    }

    if (getSimulationQuery.isError) {
        return (
            <ErrorBox
                message={`Retrieval of simulation ${simulationId} failed.`}
            ></ErrorBox>
        )
    }

    return (
        <Page
            maxWidth="lg"
            headerProps={{
                title: 'Edit Simulation',
                breadcrumbConfig: {
                    previous: [
                        {
                            text: 'Simulations',
                            to: '/replay',
                        },
                    ],
                    current: {
                        text: 'Edit Simulation',
                    },
                },
            }}
        >
            <ManageSimulationForm simulation={getSimulationQuery.data} />
        </Page>
    )
}

export default EditSimulation

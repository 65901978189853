import React, { ReactNode } from 'react'
import { IconButton, SvgIcon, TableCell } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { TableCellProps } from '@mui/material/TableCell/TableCell'

interface BaseListAction {
    icon: ReactNode
}

export interface LinkAction<T> extends BaseListAction {
    to: (item: T) => string
    type: 'LinkAction'
}

export interface ClickAction<T> extends BaseListAction {
    onClick: (item: T) => void
    type: 'ClickAction'
}

export type ListAction<T> = LinkAction<T> | ClickAction<T>

type ActionsTableCellProps<T> = {
    actions: ListAction<T>[]
    data: T
} & TableCellProps

const ActionsCell = <T extends object>({
    actions,
    data,
    ...props
}: ActionsTableCellProps<T>) => {
    return (
        <TableCell align="right" {...props}>
            {actions.map((action, index) => {
                const icon = <SvgIcon fontSize="small">{action.icon}</SvgIcon>

                const type = action.type
                switch (type) {
                    case 'ClickAction':
                        return (
                            <IconButton
                                key={index}
                                onClick={() => action.onClick(data)}
                            >
                                {icon}
                            </IconButton>
                        )
                    case 'LinkAction':
                        return (
                            <IconButton
                                key={index}
                                component={RouterLink}
                                to={action.to(data)}
                            >
                                {icon}
                            </IconButton>
                        )
                    default:
                        throw new Error(`Unhandled case: ${type}`)
                }
            })}
        </TableCell>
    )
}

export default ActionsCell

import { CreateSimulationRequest } from '../../../../../services/dst-api'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { CommonFormLayout } from '../../../../../components/fields/CommonForm'
import { Grid } from '@mui/material'
import TextField from '../../../../../components/fields/fields/base/TextField'
import NetworkField from '../../../../../components/fields/fields/NetworkField'
import DatePickerField from '../../../../../components/fields/fields/base/DatePickerField'
import TimePickerField from '../../../../../components/fields/fields/base/TimePickerField'
import { LoadingButton } from '@mui/lab'
import React from 'react'
import { ManageSimulationRequest } from './ManageSimulationForm'

type ManageSimulationFormViewProps = {
    initialValues: ManageSimulationRequest
    onSubmit: (simulation: ManageSimulationRequest) => void
    isSaving: boolean
}

const ManageSimulationFormView = ({
    initialValues,
    onSubmit,
    isSaving,
}: ManageSimulationFormViewProps) => {
    const validationSchema = yup.object({
        title: yup.string().required('Title is required'),
        description: yup.string(),
        type: yup.string().required('Type is required'),
        iterationCount: yup.number().required('Iteration count is required'),
        network: yup.string().required('Network is required'),
        date: yup.string().required('Date is required'),
        startTime: yup
            .string()
            .required('Start Time is required')
            .matches(
                /^([0-1]?[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?$/,
                'Incorrect time format'
            ),
        endTime: yup
            .string()
            .required('End Time is required')
            .matches(
                /^([0-1]?[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?/,
                'Incorrect time format'
            ),
        minorPerturbations: yup.boolean().required(),
        majorPerturbations: yup.boolean().required(),
        maximumMajorPerturbation: yup.number().nullable(true),
    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            onSubmit(values)
        },
    })

    const formikTextFieldHandler = (key: keyof CreateSimulationRequest) => {
        return {
            id: key,
            value: formik.values[key],
            onChange: formik.handleChange,
            error: formik.touched.title && Boolean(formik.errors[key]),
            helperText: formik.touched[key] && formik.errors[key],
        }
    }

    const formikTimeFieldHandler = (key: keyof CreateSimulationRequest) => {
        return {
            id: key,
            value: formik.values[key],
            onChange: (value: string | undefined) =>
                formik.setFieldValue(key, value),
            error: formik.touched.title && Boolean(formik.errors[key]),
            helperText: formik.touched[key] && formik.errors[key],
        }
    }

    const handleSubmit = (e?: React.FormEvent<HTMLFormElement> | undefined) => {
        formik.handleSubmit(e)
    }

    return (
        <form noValidate onSubmit={handleSubmit}>
            <CommonFormLayout>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            label="Title"
                            {...formikTextFieldHandler('title')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Description"
                            multiline={true}
                            {...formikTextFieldHandler('description')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <NetworkField
                            id="network"
                            value={formik.values.network}
                            onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <DatePickerField
                            label={'Date'}
                            {...formikTimeFieldHandler('date')}
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <TimePickerField
                            label={'Start Time'}
                            {...formikTimeFieldHandler('startTime')}
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <TimePickerField
                            label={'End Time'}
                            {...formikTimeFieldHandler('endTime')}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <LoadingButton
                            loading={isSaving}
                            color="primary"
                            variant="contained"
                            fullWidth
                            type="submit"
                        >
                            Save
                        </LoadingButton>
                    </Grid>
                </Grid>
            </CommonFormLayout>
        </form>
    )
}

export default ManageSimulationFormView

import { useContext } from 'react'
import { GlobalTrainGraphSettingsContext } from '../context/TrainGraphSettingsProvider'

export default function useSettingsContext() {
    const context = useContext(GlobalTrainGraphSettingsContext)
    if (!context)
        throw Error(
            'useSettingsContext can only be used inside an GlobalTrainGraphSettingsProvider'
        )
    return context
}

import React from 'react'
import { MenuItem } from '@mui/material'
import { SelectField, SelectFieldProps } from './base/SelectField'

type DataStrategyFieldProps = Omit<SelectFieldProps, 'children' | 'label'>

type DataStrategy = {
    id: string
    name: string
}

const DataStrategyField = ({
    id,
    onChange,
    value,
    ...rest
}: DataStrategyFieldProps) => {
    const dataStrategies: DataStrategy[] = [
        {
            id: 'Manual',
            name: 'Manual User Input',
        },
        {
            id: 'RL',
            name: 'Rail Lake Synchronisation',
        },
    ]

    const listItems = dataStrategies.map((incidentType) => {
        return (
            <MenuItem key={incidentType.id} value={incidentType.id}>
                {incidentType.name}
            </MenuItem>
        )
    })

    return (
        <SelectField
            id={id}
            value={value}
            onChange={onChange}
            label="Data Management Strategy"
            {...rest}
        >
            {listItems}
        </SelectField>
    )
}

export default DataStrategyField

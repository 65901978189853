import { Incident, Simulation } from '../../../services/dst-api'
import useExcelWorkbook from './useExcelWorkbook'

export type ImportedSimulation = Pick<
    Simulation,
    'title' | 'date' | 'startTime' | 'endTime'
> & {
    id: number
}

export type ImportedIncident = Pick<
    Incident,
    | 'incidentId'
    | 'title'
    | 'fromLocation'
    | 'toLocation'
    | 'startTime'
    | 'endTime'
> & {
    simulationId: number
}

export type UseExcelSimulationsResult = {
    // simulations: Simulation[]
    simulations: ImportedSimulation[]
    incidents: ImportedIncident[]
}

const useExcelSimulations = (file: File): UseExcelSimulationsResult => {
    const SIMULATIONS_SHEET_NAME = 'Simulations'
    const INCIDENTS_SHEET_NAME = 'Incidents'
    const excel = useExcelWorkbook(file)

    //TODO Import simulations UI during loading
    if (excel.loading)
        return {
            simulations: [],
            incidents: [],
        }

    //TODO Import simulations UI upon error
    if (excel.error) {
        return {
            simulations: [],
            incidents: [],
        }
    }

    //TODO Import simulations UI when no data (not possible?)
    if (!excel.data)
        return {
            simulations: [],
            incidents: [],
        }

    return {
        simulations: excel.parseSheet<ImportedSimulation>(
            SIMULATIONS_SHEET_NAME
        ),
        incidents: excel.parseSheet<ImportedIncident>(INCIDENTS_SHEET_NAME),
    }
}

export default useExcelSimulations

import React from 'react'
import { useParams } from 'react-router-dom'
import { useConfirm } from 'material-ui-confirm'
import { Grid, Paper } from '@mui/material'
import { styled } from '@mui/material/styles'
import {
    getGetIncidentsQueryKey,
    useDeleteIncident,
    useGetIncidents,
} from '../../../../services/dst-api'
import {
    pushErrorNotification,
    pushSuccessNotification,
} from '../../../../utils/notification'
import LoadingBox from '../../../../components/InformationBox/LoadingBox'
import ErrorBox from '../../../../components/InformationBox/ErrorBox'
import InformationBox from '../../../../components/InformationBox/InformationBox'
import { BreadcrumbsProps } from '../../../../components/PageHeader/PageHeader'
import EditableIncidentsList from '../IncidentsList/EditableIncidentsList'
import Page from '../../../../components/Page/Page'
import { useQueryClient } from '@tanstack/react-query'

const IncidentsPaper = styled(Paper)(({ theme }) => ({
    adding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
}))

const EditSimulationIncidentsContent = () => {
    const confirm = useConfirm()

    //get simulation id from url param
    let { simulationId } = useParams()
    const liveMode = simulationId === undefined
    const validSimulationId = liveMode ? 1 : Number(simulationId)

    // retrieve incidents from backend
    const incidentsQuery = useGetIncidents({
        simulationId: validSimulationId,
    })

    const { mutate } = useDeleteIncident()
    const queryClient = useQueryClient()

    if (incidentsQuery.isLoading) {
        return <LoadingBox />
    }

    if (incidentsQuery.isError) {
        return <ErrorBox message={'Incidents could not be retrieved.'} />
    }

    const handleIncidentDeleteClick = (id: number) => {
        confirm({
            description:
                'Deleting the incident is permanent and cannot be undone.',
        })
            .then(() => {
                mutate(
                    {
                        params: {
                            incidentId: id,
                        },
                    },
                    {
                        onSuccess: () => {
                            pushSuccessNotification(
                                `Incident ${id} deleted successfully.`
                            )
                            queryClient.invalidateQueries(
                                getGetIncidentsQueryKey({
                                    simulationId: validSimulationId,
                                })
                            )
                        },
                        onError: () => {
                            pushErrorNotification(
                                `Failed to delete Incident ${id}.`
                            )
                        },
                    }
                )
            })
            .catch(() => {
                console.log('Canceled incident delete')
            })
    }

    if (incidentsQuery.data.length === 0) {
        return (
            <InformationBox
                title={'No Incidents'}
                message={
                    'No incidents exist for this simulation. Click the button above to create one.'
                }
            />
        )
    }

    return (
        <IncidentsPaper>
            <EditableIncidentsList
                incidents={incidentsQuery.data}
                onIncidentDelete={handleIncidentDeleteClick}
            />
        </IncidentsPaper>
    )
}

const ManageSimulationIncidents = () => {
    let { simulationId } = useParams()
    const liveMode = simulationId === undefined

    const nonLiveModeBreadcrumbs: BreadcrumbsProps = {
        previous: [
            {
                text: 'Simulations',
                to: '/replay',
            },
            {
                text: 'Edit Simulation',
                to: '../basic',
            },
        ],
        current: {
            text: 'Incidents',
        },
    }

    return (
        <Page
            maxWidth="lg"
            headerProps={{
                title: 'Manage Incidents',
                actionProps: {
                    type: 'LinkAction',
                    icon: 'add',
                    text: 'Add Incident',
                    to: '../incident/new',
                },
                breadcrumbConfig: liveMode ? undefined : nonLiveModeBreadcrumbs,
            }}
        >
            <Grid item xs={12}>
                <EditSimulationIncidentsContent />
            </Grid>
        </Page>
    )
}

export default ManageSimulationIncidents

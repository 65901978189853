import BaseInformationBox, {
    BaseInformationBoxProps,
} from './BaseInformationBox'

type InformationBoxProps = BaseInformationBoxProps

const InformationBox = (props: InformationBoxProps) => {
    return <BaseInformationBox {...props} />
}

export default InformationBox

import React, { MouseEventHandler } from 'react'
import DashboardIcon from '@mui/icons-material/Dashboard'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend'
import TimelineIcon from '@mui/icons-material/Timeline'
import InsightsIcon from '@mui/icons-material/Insights'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import TrainIcon from '@mui/icons-material/Train'
import MoreIcon from '@mui/icons-material/More'
import PsychologyIcon from '@mui/icons-material/Psychology'
import QueryStatsIcon from '@mui/icons-material/QueryStats'
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt'
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions'
import HubIcon from '@mui/icons-material/Hub'

import { useParams, useSearchParams } from 'react-router-dom'
import { Profile, User } from './components/Profile'
import { SidebarNav } from './components/SidebarNav'
import { Divider, Drawer, DrawerProps } from '@mui/material'
import { styled } from '@mui/system'
import { useAuth0 } from '@auth0/auth0-react'

// const styles = theme => ({
//     drawer: {
//         width: 240,
//         [theme.breakpoints.up('lg')]: {
//             marginTop: 64,
//             height: 'calc(100% - 64px)',
//         },
//     },
//     root: {
//         backgroundColor: theme.palette.white,
//         display: 'flex',
//         flexDirection: 'column',
//         height: '100%',
//         padding: theme.spacing(2),
//     },
//     divider: {
//         margin: theme.spacing(2, 0),
//     },
//     nav: {
//         marginBottom: theme.spacing(2),
//     },
// });

const SidebarDivider = styled(Divider)(({ theme }) => ({
    margin: theme.spacing(2, 0),
}))

const SidebarDrawerContainer = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
}))

export interface Page {
    title: string
    href: string
    icon: React.ReactNode
    disabled?: boolean
}

type SidebarProps = {
    open: boolean
    onClose: MouseEventHandler | undefined
    type:
        | 'view_simulation'
        | 'edit_simulation'
        | 'live_edit_simulation'
        | 'admin' //TODO enum? also used in NavigationLayout
        | 'docs'
} & Pick<DrawerProps, 'variant'>

export const Sidebar = ({ open, variant, onClose, type }: SidebarProps) => {
    let { simulationId } = useParams()
    const [searchParams] = useSearchParams()
    const { user } = useAuth0()

    const mode = searchParams.get('mode')

    let pages: Page[]
    let secondaryListItems: Page[]

    function getExitUrl(mode: string | null) {
        if (mode === 'LIVE') return '/live/overview'
        else if (mode === 'REPLAY') return '/replay'
        return '/'
    }

    if (type === 'view_simulation') {
        pages = [
            {
                title: 'Recommendations',
                href: '/simulation/' + simulationId + `/overview?mode=${mode}`,
                icon: <DashboardIcon />,
            },
            {
                title: 'Service Changes',
                href:
                    '/simulation/' +
                    simulationId +
                    `/timetableChanges?mode=${mode}`,
                icon: <TrainIcon />,
                disabled: mode === 'LIVE',
            },
            {
                title: 'Incidents',
                href: '/simulation/' + simulationId + `/incidents?mode=${mode}`,
                icon: <CancelScheduleSendIcon />,
            },
            // {
            //   title: 'Financial Penalties',
            //   href: '/simulation/' + simulationId + `/financialPenalties?mode=${mode}`,
            //   icon: <AttachMoneyIcon/>
            // },
            // {
            //   title: 'Delay & Punctuality',
            //   href: '/simulation/' + simulationId + `/delayAndPunctuality?mode=${mode}`,
            //   icon: <TimerIcon/>
            // },
            // {
            //   title: 'Iterations',
            //   href: '/simulation/' + simulationId + `/iterations?mode=${mode}`,
            //   icon: <LayersIcon/>
            // }
        ]
        if (mode === 'REPLAY') {
            pages.push({
                title: 'Analysis',
                href: '/simulation/' + simulationId + `/analysis?mode=${mode}`,
                icon: <TimelineIcon />,
            })

            pages.push({
                title: 'Train Graph',
                href:
                    '/simulation/' + simulationId + `/trainGraph?mode=${mode}`,
                icon: <InsightsIcon />,
            })
        }

        secondaryListItems = [
            {
                title: 'Exit Simulation',
                href: getExitUrl(mode),
                icon: <ExitToAppIcon />,
            },
        ]
    } else if (type === 'edit_simulation') {
        pages = [
            {
                title: 'Basic Setup',
                href: '/replay/simulation/' + simulationId + '/edit/basic',
                icon: <DashboardIcon />,
            },
            // {
            //   title: 'Timetable',
            //   href: '/simulation/' + simulationId + '/edit/timetable',
            //   icon: <ScheduleIcon/>
            // },
            {
                title: 'Incidents',
                href: '/replay/simulation/' + simulationId + '/edit/incidents',
                icon: <CancelScheduleSendIcon />,
            },
            {
                title: 'Service Changes',
                href: '/replay/simulation/' + simulationId + '/edit/services',
                icon: <TrainIcon />,
                disabled: false,
            },
            {
                title: 'Stock Changes',
                href: '/replay/simulation/' + simulationId + '/edit/stock',
                icon: <MoreIcon />,
                disabled: true,
            },
            {
                title: 'Crew Changes',
                href: '/replay/simulation/' + simulationId + '/edit/crew',
                icon: <PeopleAltIcon />,
                disabled: false,
            },
        ]

        secondaryListItems = [
            {
                title: 'Exit Simulation',
                href: getExitUrl('REPLAY'),
                icon: <ExitToAppIcon />,
            },
        ]
    } else if (type === 'live_edit_simulation') {
        pages = [
            // {
            //   title: 'Timetable',
            //   href: '/live/edit/timetable',
            //   icon: <ScheduleIcon/>
            // },
            {
                title: 'Overview',
                href: '/live/overview',
                icon: <DashboardIcon />,
            },
            {
                title: 'Incidents',
                href: '/live/edit/incidents',
                icon: <CancelScheduleSendIcon />,
            },
            {
                title: 'Service Changes',
                href: '/live/edit/services',
                icon: <TrainIcon />,
                disabled: true,
            },
            {
                title: 'Stock Changes',
                href: '/live/edit/stock',
                icon: <MoreIcon />,
                disabled: true,
            },
            {
                title: 'Crew Changes',
                href: '/live/edit/crew',
                icon: <PeopleAltIcon />,
                disabled: true,
            },
        ]

        secondaryListItems = [
            {
                title: 'Last Recommendations',
                href: '/live/previous-recommendations',
                icon: <QueryStatsIcon />,
            },
            {
                title: 'Recommend Actions',
                href: '/live/recommend-actions',
                icon: <PsychologyIcon />,
            },
            {
                title: 'Exit Live Mode',
                href: '/',
                icon: <ExitToAppIcon />,
            },
        ]
    } else if (type === 'admin') {
        pages = [
            {
                title: 'Users',
                href: '/admin/users',
                icon: <PeopleAltIcon />,
            },
            {
                title: 'Locations',
                href: '/admin/locations',
                icon: <AddLocationAltIcon />,
            },
            {
                title: 'Incident Types',
                href: '/admin/incident/types',
                icon: <CancelScheduleSendIcon />,
            },
            {
                title: 'Integrations',
                href: '/admin/integrations',
                icon: <IntegrationInstructionsIcon />,
            },
        ]

        secondaryListItems = []
    } else if (type === 'docs') {
        pages = [
            {
                title: 'Backend',
                href: '/docs/backend',
                icon: <HubIcon />,
            },
        ]

        secondaryListItems = [
            {
                title: 'Exit Documentation',
                href: '/',
                icon: <ExitToAppIcon />,
            },
        ]
    } else {
        pages = []
        secondaryListItems = []
    }

    const auth0User = user!!
    const authenticatedUser: User = {
        name: auth0User.name ?? auth0User.email ?? 'Unknown User',
        avatar: auth0User.picture ?? '/images/avatars/avatar_generic.png',
        bio: 'MTR Elizabeth Line',
    }

    return (
        <Drawer
            anchor="left"
            onClose={onClose}
            open={open}
            variant={variant}
            PaperProps={{
                sx: {
                    width: 240,
                    marginTop: '64px',
                    height: 'calc(100% - 64px)',
                },
            }}
        >
            <SidebarDrawerContainer>
                <Profile user={authenticatedUser} />
                <SidebarDivider />
                <SidebarNav pages={pages} />
                <SidebarDivider />
                <SidebarNav pages={secondaryListItems} />
            </SidebarDrawerContainer>
        </Drawer>
    )
}

import React from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import ResultSectionTitle from '../ResultSectionTitle/ResultSectionTitle'
import { SimulationResults } from '../../../../../services/dst-api'

type OutcomeMeasure = {
    id: number
    kpi: string
    withoutAmendments: string
    withAmendments: string
    difference: string
}

type OutcomeMeasuresGridProps = {
    results: SimulationResults
}

const OutcomeMeasuresGrid = ({ results }: OutcomeMeasuresGridProps) => {
    const calculateChange = (
        first: number,
        second: number,
        numberFormatter: (value: number) => string
    ): string => {
        const change = Math.abs(second - first)
        const changeSymbol = second >= first ? '+' : '-'
        return changeSymbol + numberFormatter(change)
    }

    const formatCurrency = (value: number): string => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'GBP',
            maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        })
        return formatter.format(value)
    }

    const formatSeconds = (value: number): string => `${value} Seconds`

    const getOutcomeMeasureFigures = (
        before: number,
        after: number,
        formatter: (value: number) => string = (value) => value.toString()
    ): Omit<OutcomeMeasure, 'id' | 'kpi'> => {
        return {
            withoutAmendments: formatter(before),
            withAmendments: formatter(after),
            difference: calculateChange(before, after, formatter),
        }
    }

    const rows: OutcomeMeasure[] = [
        {
            id: 0,
            kpi: 'PPM Failures',
            ...getOutcomeMeasureFigures(
                results.withoutAmendments.summary.ppmFailureCount,
                results.withAmendments.summary.ppmFailureCount
            ),
        },
        {
            id: 1,
            kpi: 'Total Lateness',
            ...getOutcomeMeasureFigures(
                results.withoutAmendments.summary.totalLatenessSeconds,
                results.withAmendments.summary.totalLatenessSeconds,
                formatSeconds
            ),
        },
        {
            id: 2,
            kpi: 'On-Time',
            ...getOutcomeMeasureFigures(
                results.withoutAmendments.summary.onTimeCount,
                results.withAmendments.summary.onTimeCount
            ),
        },
        {
            id: 3,
            kpi: 'Cancellations',
            ...getOutcomeMeasureFigures(
                results.withoutAmendments.summary.cancellationCount,
                results.withAmendments.summary.cancellationCount
            ),
        },
        {
            id: 4,
            kpi: 'Cancellation Penalty',
            ...getOutcomeMeasureFigures(
                results.withoutAmendments.summary.cancellationPenalty,
                results.withAmendments.summary.cancellationPenalty,
                formatCurrency
            ),
        },
        {
            id: 5,
            kpi: 'Delay Penalty',
            ...getOutcomeMeasureFigures(
                results.withoutAmendments.summary.delayPenalty,
                results.withAmendments.summary.delayPenalty,
                formatCurrency
            ),
        },
        {
            id: 6,
            kpi: 'Total Penalty',
            ...getOutcomeMeasureFigures(
                results.withoutAmendments.summary.totalPenalty,
                results.withAmendments.summary.totalPenalty,
                formatCurrency
            ),
        },
    ]

    return (
        <React.Fragment>
            <ResultSectionTitle>
                Outcome Measures for Assessed Services
            </ResultSectionTitle>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell align={'center'}>KPI</TableCell>
                        <TableCell align={'center'}>
                            Without Amendments
                        </TableCell>
                        <TableCell align={'center'}>With Amendments</TableCell>
                        <TableCell align={'center'}>Change</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell align={'left'}>{row.kpi}</TableCell>
                            <TableCell align={'center'}>
                                {row.withoutAmendments}
                            </TableCell>
                            <TableCell align={'center'}>
                                {row.withAmendments}
                            </TableCell>
                            <TableCell align={'center'}>
                                {row.difference}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </React.Fragment>
    )
}

export default OutcomeMeasuresGrid

import React from 'react'
import { BreadcrumbsProps } from '../../../../components/PageHeader/PageHeader'
import { useParams } from 'react-router-dom'
import CreateIncidentForm from './components/CreateIncidentForm'
import Page from '../../../../components/Page/Page'

export function AddSimulationIncident() {
    let { simulationId } = useParams()
    const liveMode = simulationId === undefined
    const validSimulationId = liveMode ? 1 : Number(simulationId)

    const nonLiveModeBreadcrumbs: BreadcrumbsProps = {
        previous: [
            {
                text: 'Simulations',
                to: '/replay',
            },
            {
                text: 'Edit Simulation',
                to: '../basic',
            },
            {
                text: 'Edit Incidents',
                to: '../incidents',
            },
        ],
        current: {
            text: 'Create Incident',
        },
    }

    const liveModeBreadcrumbs: BreadcrumbsProps = {
        previous: [
            {
                text: 'Manage Incidents',
                to: '../incidents',
            },
        ],
        current: {
            text: 'Create Incident',
        },
    }

    return (
        <Page
            maxWidth="lg"
            headerProps={{
                title: 'Create Incident',
                breadcrumbConfig: liveMode
                    ? liveModeBreadcrumbs
                    : nonLiveModeBreadcrumbs,
            }}
        >
            <CreateIncidentForm simulationId={validSimulationId} />
        </Page>
    )
}

export default AddSimulationIncident

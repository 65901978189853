import { Button } from '@mui/material'
import React from 'react'

type SingleFileUploadProps = {
    acceptedFileTypes: string
    afterUpload: (file: File) => void
}
export const SingleFileUpload = ({
    acceptedFileTypes,
    afterUpload,
}: SingleFileUploadProps) => {
    return (
        <Button variant="contained" component="label">
            Upload
            <input
                hidden
                type="file"
                accept={acceptedFileTypes}
                onChange={(event) => {
                    const uploadedFiles = event.target.files
                    if (uploadedFiles === null)
                        // TODO handle null upload file
                        console.error('uploaded files is null')
                    else if (uploadedFiles.length === 0)
                        // TODO handle empty upload file
                        console.error('uploaded files is empty')
                    else {
                        const file = uploadedFiles[0]
                        afterUpload(file)
                    }
                }}
            />
        </Button>
    )
}

import React from 'react'
import { MenuItem } from '@mui/material'
import { SelectField, SelectFieldProps } from './base/SelectField'
import networks from '../../../data/networks'

type NetworkFieldProps = Pick<SelectFieldProps, 'id' | 'value' | 'onChange'>

const NetworkField = ({ id, onChange, value }: NetworkFieldProps) => {
    const listItems = networks.map((network) => {
        return (
            <MenuItem key={network.id} value={network.id}>
                {network.name}
            </MenuItem>
        )
    })

    return (
        <SelectField id={id} value={value} onChange={onChange} label="Network">
            {listItems}
        </SelectField>
    )
}

export default NetworkField

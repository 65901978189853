import React from 'react'
import { CommonFormLayout } from '../../../../components/fields/CommonForm'
import { Button } from '@mui/material'

const RailLakeDataOperations = () => {
    return (
        <CommonFormLayout title={'Rail Lake Operations'}>
            <Button variant={'outlined'}>Configure Email Alerts</Button>
            <Button variant={'outlined'}>Download Data</Button>
            <Button variant={'outlined'}>View Dataset in Power BI</Button>
        </CommonFormLayout>
    )
}

export default RailLakeDataOperations

import { LocationVisit, useGetVisits } from '../../../services/dst-api'

export type UseTrainScheduleResults = {
    isLoading: boolean
    isError: boolean
    visits: LocationVisit[]
}

const useTrainSchedule = (trainId: number): UseTrainScheduleResults => {
    const query = useGetVisits(trainId)

    const isLoading = query.isLoading
    const isError = query.isError

    return {
        isLoading,
        isError,
        visits: query.data || [],
    }
}

export default useTrainSchedule

import BaseField, { BaseFieldProps } from './BaseField'
import React, { ReactNode } from 'react'
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { TextField } from '@mui/material'
import dayjs from 'dayjs'

export interface TimePickerFieldProps extends BaseFieldProps {
    label: string
    value: any
    onChange: (value: string | undefined) => void
    error?: boolean
    helperText?: ReactNode
}

const TimePickerField = ({
    id,
    label,
    onChange,
    value,
    error,
    helperText,
}: TimePickerFieldProps) => {
    const validatedValue = value?.length === 8 ? value.substring(0, 5) : value

    return (
        <BaseField>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                    ampm={false}
                    label={label}
                    value={dayjs(validatedValue, 'HH:mm')}
                    onChange={(value) => onChange(value?.format('HH:mm'))}
                    renderInput={(params) => (
                        <TextField
                            id={id}
                            name={id}
                            fullWidth
                            error={error}
                            helperText={helperText}
                            {...params}
                        />
                    )}
                />
            </LocalizationProvider>
        </BaseField>
    )
}

export default TimePickerField

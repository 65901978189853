import useTrainGraphSections from './useTrainGraphSections'
import { useContext } from 'react'
import { TrainGraphSettings } from '../components/TrainGraphSettings/TrainGraphSettingsForm'
import { Simulation } from '../../../services/dst-api'
import { parseDateTime } from '../../../utils/timeUtils'
import { Dayjs } from 'dayjs'
import { TrainGraphSection } from '../data/trainGraphSections'
import { GraphableJourney } from './useTrainGraphData'
import { LineColourStrategy } from '../components/TrainGraphSettings/TrainGraphSettingsFields/LineColourStrategyField'
import { SimulationContext } from '../../Simulation/context/SimulationProvider'
import useSimulationSettingsContext from './useSimulationSettingsContext'
import useSettingsContext from './useSettingsContext'

export type TrainChartColourScheme = {
    background: string
    grid: string
    label: {
        background: string
        color: string
    }
    primary: {
        line: {
            color: string
        }
    }
    comparator: {
        line: {
            color: string
        }
    }
    ticks: {
        text: string
    }
}

type ColourStrategyCalculator = {
    getColour: (journey: GraphableJourney) => string
}

type ColourStrategies = {
    [key in LineColourStrategy['id']]: ColourStrategyCalculator
}

export type AllTrainGraphSettings = {
    settings: TrainGraphSettings
    updateSettings: (value: Partial<TrainGraphSettings>) => void
    period: { update: (value: number) => void; value: number }
    time: {
        decrement(): void
        increment(): void
        startTime: Dayjs
        endTime: any
        setStartTime: (timeString: string) => void
        setStartDateTime: (value: Dayjs) => void
    }
    colourScheme: TrainChartColourScheme
    sections: {
        enabledSections: TrainGraphSection[]
        allSections: TrainGraphSection[]
        enabledSectionNames: string[]
        setEnabledSections: (sectionNames: string[]) => void
        isEnabled: (sectionName: string) => boolean
    }
    getChartHeight: (section: TrainGraphSection) => number
    getJourneyColour: ColourStrategyCalculator['getColour']
}

const useTrainGraphSettings = (): AllTrainGraphSettings => {
    const { settings, setSettings, period, setPeriod, colourScheme } =
        useSettingsContext()
    const sections = useTrainGraphSections()
    const {
        data: simulationSettings,
        dispatcher: simulationSettingsDispatcher,
    } = useSimulationSettingsContext()
    const simulation = useContext(SimulationContext) as Simulation

    const { setTime } = simulationSettingsDispatcher
    const { time } = simulationSettings

    const updateTime = (timeString: string) => {
        const dayjsTime = parseDateTime(simulation.date, timeString)
        setTime(dayjsTime)
    }

    const updateDateTime = (dateTime: Dayjs) => {
        setTime(dateTime)
    }

    const getChartHeight = (section: TrainGraphSection) => {
        const displayXAxis = sections.enabledSections.indexOf(section) === 0
        const minY = section.locations[0].distance
        const maxY =
            section.locations[section.locations.length - 1].distance +
            (displayXAxis ? 1500 : 0)
        return (maxY - minY) / settings.heightRatio
    }

    /* eslint-disable no-bitwise */
    const colourStrategies: ColourStrategies = {
        TOC: {
            getColour: (journey: GraphableJourney) => {
                function hashCode(str: string) {
                    let hash = 0
                    for (let i = 0; i < str.length; i++) {
                        hash = str.charCodeAt(i) + ((hash << 5) - hash)
                    }
                    return hash
                }

                function pickColor(str: string) {
                    return `hsl(${hashCode(str) % 360}, 100%, 80%)`
                }

                return pickColor(journey.toc)
            },
        },
        CLASS_TYPE: {
            getColour: (journey: GraphableJourney) => {
                function hashCode(str: string) {
                    let hash = 0
                    for (let i = 0; i < str.length; i++) {
                        hash = str.charCodeAt(i) + ((hash << 5) - hash)
                    }
                    return hash
                }

                function pickColor(str: string) {
                    return `hsl(${hashCode(str) % 360}, 100%, 80%)`
                }

                const trainclass = parseInt(journey.headcode.substring(0, 1))
                switch (trainclass) {
                    case 1:
                    case 2:
                    case 9:
                        return pickColor('PASSENGER')
                    case 0:
                    case 4:
                    case 6:
                    case 7:
                    case 8:
                        return pickColor('FREIGHT')
                    default:
                        return pickColor('EMPTY_CARRIAGE_STOCK')
                }
            },
        },
        NONE: {
            getColour: () => {
                return colourScheme.primary.line.color
            },
        },
    }
    /* eslint-enable no-bitwise */
    const getDefaultTimeStep = () => {
        switch (period) {
            case 15:
                return 5
            case 30:
                return 20
            default:
                return 30
        }
    }

    return {
        colourScheme,
        settings,
        updateSettings: (value) => {
            setSettings({
                ...settings,
                ...value,
            })
        },
        sections,
        time: {
            startTime: time,
            endTime: time.add(period, 'minutes'),
            setStartDateTime: updateDateTime,
            setStartTime: updateTime,
            increment() {
                updateDateTime(time.add(getDefaultTimeStep(), 'minutes'))
            },
            decrement() {
                updateDateTime(time.subtract(getDefaultTimeStep(), 'minutes'))
            },
        },
        period: {
            value: period,
            update: setPeriod,
        },
        getChartHeight,
        getJourneyColour:
            colourStrategies[settings.lineColourStrategy].getColour,
    }
}

export default useTrainGraphSettings

import React from 'react'
import { SelectField, SelectFieldProps } from './base/SelectField'
import { useGetSectionLines } from '../../../services/dst-api'
import { MenuItem } from '@mui/material'

type SectionLineFieldProps = {
    fromLocation: string
    toLocation: string
} & Omit<SelectFieldProps, 'children' | 'label'>

const SectionLineField = ({
    id,
    onChange,
    value,
    fromLocation,
    toLocation,
    ...rest
}: SectionLineFieldProps) => {
    const { data: lines, isLoading } = useGetSectionLines({
        fromLocation: fromLocation,
        toLocation: toLocation,
    })

    const listItems =
        lines?.map((line) => {
            return (
                <MenuItem key={line} value={line}>
                    {line}
                </MenuItem>
            )
        }) ?? []

    return (
        <SelectField
            id={id}
            value={value}
            onChange={onChange}
            label={'Line'}
            disabled={isLoading}
            {...rest}
        >
            {listItems}
        </SelectField>
    )
}

export default SectionLineField

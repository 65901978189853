import React from 'react'
import { List, ListItem } from '@mui/material'
import { styled } from '@mui/material/styles'
import { NavButton } from './NavButton'
import { Page } from '../Sidebar'

const SidebarNavListItem = styled(ListItem)(() => ({
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
}))

const LinkIcon = styled('div')(({ theme }) => ({
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
}))

type SidebarNavItemProps = {
    page: Page
}

const SidebarNavItem = ({ page }: SidebarNavItemProps) => {
    return (
        <SidebarNavListItem disableGutters>
            <NavButton href={page.href} disabled={page.disabled === true}>
                <LinkIcon>{page.icon}</LinkIcon>
                {page.title}
            </NavButton>
        </SidebarNavListItem>
    )
}

type SidebarNavProps = {
    pages: Page[]
}

export const SidebarNav = ({ pages }: SidebarNavProps) => {
    return (
        <List>
            {pages.map((page) => (
                <SidebarNavItem key={page.title} page={page} />
            ))}
        </List>
    )
}

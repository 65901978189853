import React from 'react'
import { styled } from '@mui/material/styles'
import {
    LocationVisit,
    LocationVisitAction,
} from '../../../../../services/dst-api'
import { JourneyOperationColourScheme } from './JourneyOperationCell'
import RemoveIcon from '@mui/icons-material/Remove'
import { getLocationName } from '../../../../../data/locationDescriptions'
import { Tooltip } from '@mui/material'

export type ActionChipProps = {
    visit: LocationVisit
    onToggleStop: (visitSequence: number) => void
    colourScheme: JourneyOperationColourScheme
}

type ChipContainerProps = {
    modified: boolean
    modifiable: boolean
    colourScheme: JourneyOperationColourScheme
    displayBorder: boolean
}

const ChipContainer = styled('div', {
    shouldForwardProp: (prop) =>
        prop !== 'modified' && prop !== 'modifiable' && prop !== 'colourScheme',
})<ChipContainerProps>(
    ({ theme, modified, modifiable, colourScheme, displayBorder }) => ({
        paddingTop: theme.spacing(0.2),
        paddingBottom: theme.spacing(0.2),
        paddingLeft: '0',
        border: displayBorder ? '1px solid' : 'none',
        borderRadius: theme.spacing(0.5),
        display: 'inline-block',
        lineHeight: 1,
        width: '4em',
        textAlign: 'center',
        pointerEvents: modifiable ? 'all' : 'none',
        color: modified
            ? colourScheme.subtraction
            : modifiable
            ? colourScheme.enabled
            : colourScheme.disabled,
        opacity: !modifiable ? 0.3 : 1,
        ':hover': {
            color: colourScheme.hover,
        },
        cursor: 'pointer',
    })
)

const ActionChip = ({ visit, onToggleStop, colourScheme }: ActionChipProps) => {
    const modified = visit.state.fts || visit.state.additionalStop

    const cancelled = visit.state.cancelled
    const modifiable = !(cancelled || visit.state.runFast)

    function handleToggleStop() {
        onToggleStop(visit.sequence)
    }

    const chipLabels: Record<LocationVisitAction, string> = {
        PASS: 'Pass',
        STOP: 'Calling',
        DESTINATION: 'Dest',
        ORIGIN: 'Origin',
    }

    function getLabel() {
        if (visit.state.fts) return 'FTS'
        if (visit.action === LocationVisitAction.PASS)
            return <RemoveIcon onClick={handleToggleStop} />
        return chipLabels[visit.action]
    }

    function getTooltip() {
        const visitLocation = getLocationName(visit.location.tiploc)
        if (visit.state.fts) return 'Remove FTS from ' + visitLocation
        if (visit.action === LocationVisitAction.PASS)
            return 'Add a call at ' + visitLocation
        return 'Add FTS at ' + visitLocation
    }

    return (
        <Tooltip title={getTooltip()}>
            <ChipContainer
                modified={modified}
                modifiable={modifiable}
                colourScheme={colourScheme}
                displayBorder={false}
                onClick={handleToggleStop}
            >
                {getLabel()}
            </ChipContainer>
        </Tooltip>
    )
}

export default ActionChip

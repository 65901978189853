import React from 'react'
import { Avatar, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

const ProfileContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
}))

const ProfileNameText = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(1),
}))

export interface User {
    name: string
    avatar: string
    bio: string
}

type ProfileProps = {
    user: User
}

export const Profile = ({ user }: ProfileProps) => {
    return (
        <ProfileContainer>
            <Avatar
                alt="Person"
                sx={{
                    width: 60,
                    height: 60,
                }}
                src={user.avatar}
            />
            <ProfileNameText variant="h4">{user.name}</ProfileNameText>
            <Typography variant="body2">{user.bio}</Typography>
        </ProfileContainer>
    )
}

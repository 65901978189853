import {
    getGetLiveTrainsQueryKey,
    getGetVisitsQueryKey,
    TrainModification,
    TrainModificationType,
    useCancelFrom,
    useCancelTo,
    useReinstateTrain,
    useRunFastFrom,
    useRunFastTo,
    useToggleStop,
} from '../../../services/dst-api'
import {
    pushErrorNotification,
    pushSuccessNotification,
} from '../../../utils/notification'
import { UseMutationResult, useQueryClient } from '@tanstack/react-query'

type ModificationOperation = () => void

export type ModificationOperations = Record<
    TrainModificationType,
    ModificationOperation | null
>

export type UseModificationsResults = {
    remove: (modification: TrainModification) => void
    isSupported: (modificationType: TrainModificationType) => boolean
}

const useTrainModifications = (trainId: number): UseModificationsResults => {
    const queryClient = useQueryClient()
    const invalidateVisits = () =>
        queryClient.invalidateQueries(getGetVisitsQueryKey(trainId))
    const invalidateTrains = () =>
        queryClient.invalidateQueries(getGetLiveTrainsQueryKey())

    const mutation = {
        onSuccess: () => {
            pushSuccessNotification('Modification removed successfully')
            invalidateVisits()
            invalidateTrains()
        },
        onError: () => {
            pushErrorNotification(`Modification removal failed`)
        },
    }

    const cancelFrom = useCancelFrom()
    const cancelTo = useCancelTo()
    const reinstate = useReinstateTrain()
    const runFastFrom = useRunFastFrom()
    const runFastTo = useRunFastTo()
    const toggleStop = useToggleStop()

    const submit = (
        hook: UseMutationResult<
            void,
            unknown,
            { trainId: number; visitIndex: number; data: any }
        >,
        visitSequence: number | undefined
    ) =>
        hook.mutate(
            {
                trainId: trainId,
                visitIndex: visitSequence ?? -1,
                data: {},
            },
            mutation
        )

    const lastIndexInJourney = 999 - 1 //TODO get visit count for removing modifications
    const operations: ModificationOperations = {
        CALVIN: () => submit(cancelTo, 0),
        CAPE: null, //submit(cancelFrom, lastIndexInJourney), //TODO cape removal
        PINE: null, //TODO reinstate removal
        FTS: null, //submit(toggleStop, -1), //TODO fts removal
        ADD_STOP: null, //submit(toggleStop, -1), //TODO add stop removal
        RUN_FAST_FROM: null, //submit(runFastFrom, lastIndexInJourney), //TODO run fast from removal
        RUN_FAST_TO: () => submit(runFastTo, 0),
    }

    return {
        remove: (modification: TrainModification) => {
            operations[modification.type]?.()
        },
        isSupported: (modificationType) => operations[modificationType] != null,
    }
}

export default useTrainModifications

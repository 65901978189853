import React from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import AcceptAmendments from '../AcceptAmendments/AcceptAmendments'
import ResultSectionTitle from '../ResultSectionTitle/ResultSectionTitle'
import { SuggestedServiceAmendmentDescription } from '../../../../../services/dst-api'

type ServiceAmendmentDescriptionsGridProps = {
    amendmentDescriptions: SuggestedServiceAmendmentDescription[]
}

const ServiceAmendmentDescriptionsGrid = ({
    amendmentDescriptions,
}: ServiceAmendmentDescriptionsGridProps) => {
    return (
        <React.Fragment>
            <ResultSectionTitle>Service Amendments</ResultSectionTitle>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell align={'center'}>ID</TableCell>
                        <TableCell align={'center'}>Amendment</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {amendmentDescriptions.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell align={'center'}>{row.id}</TableCell>
                            <TableCell align={'left'}>
                                {row.description}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <AcceptAmendments />
        </React.Fragment>
    )
}

export default ServiceAmendmentDescriptionsGrid

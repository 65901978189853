import React from 'react'
import { useParams } from 'react-router-dom'
import { Grid } from '@mui/material'
import InformationBox from '../../../../components/InformationBox/InformationBox'
import { BreadcrumbsProps } from '../../../../components/PageHeader/PageHeader'
import Page from '../../../../components/Page/Page'

const ManageSimulationStock = () => {
    let { simulationId } = useParams()
    const liveMode = simulationId === undefined

    const nonLiveModeBreadcrumbs: BreadcrumbsProps = {
        previous: [
            {
                text: 'Simulations',
                to: '/replay',
            },
            {
                text: 'Edit Simulation',
                to: '../basic',
            },
        ],
        current: {
            text: 'Stock Changes',
        },
    }

    return (
        <Page
            maxWidth="lg"
            headerProps={{
                title: 'Manage Stock',
                breadcrumbConfig: liveMode ? undefined : nonLiveModeBreadcrumbs,
            }}
        >
            <Grid item xs={12}>
                <InformationBox
                    title={'No Stock Changes'}
                    message={'No stock changes exist for this simulation.'}
                />
            </Grid>
        </Page>
    )
}

export default ManageSimulationStock

import Page from '../../components/Page/Page'
import React, { useState } from 'react'
import { SingleFileUpload } from './components/SingleFileUpload'
import UploadedSimulations from './components/UploadedSimulations'

const UploadSimulations = () => {
    const [file, setFile] = useState<File>()

    const processUpload = (file: File) => {
        setFile(file)
    }

    return (
        <Page
            maxWidth="lg"
            headerProps={{
                title: 'Import Simulations',
                breadcrumbConfig: {
                    previous: [
                        {
                            text: 'Simulations',
                            to: '/replay',
                        },
                    ],
                    current: {
                        text: 'Import Simulations',
                    },
                },
            }}
        >
            <SingleFileUpload
                acceptedFileTypes={
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                }
                afterUpload={processUpload}
            />
            {file !== undefined && <UploadedSimulations file={file} />}
        </Page>
    )
}
export default UploadSimulations

import { Button, Stack } from '@mui/material'
import React from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { HoverMode } from '../TrainGraph/TrainGraph'
import JourneyTypeField, {
    JourneyType,
} from './TrainGraphSettingsFields/JourneyTypeField'
import ShowJourneyLabelsField from './TrainGraphSettingsFields/ShowJourneyLabelsField'
import ShowMinorLocationsField from './TrainGraphSettingsFields/ShowMinorLocationsField'
import LineColourStrategyField, {
    LineColourStrategy,
} from './TrainGraphSettingsFields/LineColourStrategyField'
import { SliderField } from '../../../../components/fields/fields/base/SliderField'

type TrainGraphSettingsProps = {
    settings: TrainGraphSettings
    save: (settings: TrainGraphSettings) => void
}

export type TrainGraphSettings = {
    hoverMode: HoverMode
    primaryJourneyType: JourneyType['id']
    comparatorJourneyType: JourneyType['id']
    showJourneyLabels: boolean
    showMinorLocations: boolean
    lineColourStrategy: LineColourStrategy['id']
    heightRatio: number
}

const validationSchema = yup.object({
    hoverMode: yup.string(),
    primaryJourneyType: yup.string(),
    comparatorJourneyType: yup.string().nullable(),
    showJourneyLabels: yup.boolean(),
    showMinorLocations: yup.boolean(),
    lineColourStrategy: yup.string(),
    heightRatio: yup.number(),
})

const TrainGraphSettingsForm = ({
    settings,
    save,
}: TrainGraphSettingsProps) => {
    const formik = useFormik({
        initialValues: settings,
        validationSchema: validationSchema,
        onSubmit: (values: TrainGraphSettings) => {
            save(values)
        },
    })

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <Stack gap={2} minWidth={500}>
                    <JourneyTypeField
                        label={'Primary Journey Type'}
                        value={formik.values.primaryJourneyType}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        id={'primaryJourneyType'}
                        error={
                            formik.touched.primaryJourneyType &&
                            Boolean(formik.errors.primaryJourneyType)
                        }
                        helperText={
                            formik.touched.primaryJourneyType &&
                            formik.errors.primaryJourneyType
                        }
                    />
                    <JourneyTypeField
                        label={'Comparator Journey Type'}
                        value={formik.values.comparatorJourneyType}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        id={'comparatorJourneyType'}
                        error={
                            formik.touched.comparatorJourneyType &&
                            Boolean(formik.errors.comparatorJourneyType)
                        }
                        helperText={
                            formik.touched.comparatorJourneyType &&
                            formik.errors.comparatorJourneyType
                        }
                    />
                    <ShowJourneyLabelsField
                        value={formik.values.showJourneyLabels}
                        onChange={formik.handleChange}
                        id={'showJourneyLabels'}
                    />
                    <ShowMinorLocationsField
                        value={formik.values.showMinorLocations}
                        onChange={formik.handleChange}
                        id={'showMinorLocations'}
                    />
                    <LineColourStrategyField
                        label={'Line Colour Strategy'}
                        value={formik.values.lineColourStrategy}
                        onChange={formik.handleChange}
                        id={'lineColourStrategy'}
                    />
                    <SliderField
                        id={'heightRatio'}
                        value={formik.values.heightRatio}
                        min={10}
                        max={200}
                        onChange={formik.handleChange}
                        titledFieldProps={{
                            title: 'Chart Height',
                            subtitle:
                                'The chart height can be configured based on the section mileage. Default is 1 pixel per 100 meters.',
                        }}
                        sliderProps={{
                            step: 10,
                            valueLabelDisplay: 'auto',
                            marks: [
                                {
                                    value: 20,
                                    label: '1:20',
                                },
                                {
                                    value: 40,
                                    label: '1:40',
                                },
                                {
                                    value: 70,
                                    label: '1:70',
                                },
                                {
                                    value: 100,
                                    label: '1:100',
                                },
                                {
                                    value: 150,
                                    label: '1:150',
                                },
                                {
                                    value: 200,
                                    label: '1:200',
                                },
                            ],
                        }}
                    />
                    <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        type="submit"
                    >
                        Save Settings
                    </Button>
                </Stack>
            </form>
        </div>
    )
}

export default TrainGraphSettingsForm

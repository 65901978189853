import { TrainsListProps } from '../../TrainsList'
import {
    Button,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material'
import React from 'react'
import useRouteTrains, {
    RouteLocationVisits,
} from '../../../../hooks/useRouteTrains'
import LoadingBox from '../../../../../../components/InformationBox/LoadingBox'
import ErrorBox from '../../../../../../components/InformationBox/ErrorBox'
import VisitCell from '../../../../../../components/BaseTable/VisitCell'
import { Route } from '../../../../../../data/routes'
import { Train } from '../../../../../../services/dst-api'

type TrainLineupGridViewProps = {
    route: Route
    trains: Train[]
    visitsByLocation: RouteLocationVisits[]
    onOpenEditor?: (train: Train) => void
} & TrainsListProps

const TrainLineupGridView = ({
    trains,
    route,
    visitsByLocation,
    onOpenEditor,
}: TrainLineupGridViewProps) => {
    const editable = onOpenEditor !== undefined

    const headRows = [
        <TableCell key={'location-heading'}>Location</TableCell>,
        ...trains.map((train) => (
            <TableCell key={train.id} align={'center'}>
                <Stack alignItems={'center'}>
                    {train.headcode}
                    {editable && (
                        <Button
                            size={'small'}
                            onClick={() => onOpenEditor(train)}
                        >
                            Edit
                        </Button>
                    )}
                </Stack>
            </TableCell>
        )),
    ]

    return (
        <TableContainer>
            <Table size="small" stickyHeader cellPadding={20} cellSpacing={20}>
                <TableHead>
                    <TableRow>{headRows}</TableRow>
                </TableHead>
                <TableBody>
                    {route.locations.map((location, routeIndex) => {
                        const { times } = visitsByLocation[routeIndex]
                        const trainCells = trains.map((train, index) => (
                            <VisitCell
                                key={train.id + '-' + routeIndex}
                                visit={times[index]}
                            />
                        ))
                        return (
                            <TableRow key={location.tiploc}>
                                <TableCell>{location.tiploc}</TableCell>
                                {trainCells}
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

type TrainLineupGridProps = {
    route: Route
    onOpenEditor?: (train: Train) => void
} & TrainsListProps

const TrainLineupGrid = ({
    trains,
    route,
    onOpenEditor,
}: TrainLineupGridProps) => {
    const { loading, error, visitsByLocation } = useRouteTrains({
        trains,
        route,
    })

    if (loading) {
        return <LoadingBox />
    }

    if (error) {
        return <ErrorBox message={'Schedules could not be retrieved.'} />
    }

    return (
        <TrainLineupGridView
            trains={trains}
            route={route}
            visitsByLocation={visitsByLocation}
            onOpenEditor={onOpenEditor}
        />
    )
}

export default TrainLineupGrid

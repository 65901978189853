import { createTheme } from '@mui/material/styles'
import typography from './typography'
import palette from './palette'
import { grey } from '@mui/material/colors'

// Create a theme instance.
const theme = createTheme({
    palette,
    typography,
    components: {
        MuiButton: {
            styleOverrides: {
                contained: {
                    boxShadow:
                        '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
                    // backgroundColor: '#FFFFFF'
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: palette.icon,
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.03)',
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                elevation1: {
                    boxShadow:
                        '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    ...typography.body1,
                    borderBottom: `1px solid ${palette.divider}`,
                },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    backgroundColor: grey[50],
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&$selected': {
                        backgroundColor: palette.background.default,
                    },
                    '&$hover': {
                        '&:hover': {
                            backgroundColor: palette.background.default,
                        },
                    },
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                gutterBottom: {
                    marginBottom: 8,
                },
            },
        },
    },
    zIndex: {
        appBar: 1200,
        drawer: 1100,
    },
})

export default theme

import React from 'react'
import ToggleButtonGroupField, {
    ToggleButtonGroupFieldProps,
} from './base/ToggleButtonGroupField'

type SimulationDurationFieldProps = Pick<
    ToggleButtonGroupFieldProps,
    'id' | 'value' | 'onChange'
>

const SimulationDurationField = ({
    id,
    value,
    onChange,
}: SimulationDurationFieldProps) => {
    const durations: ToggleButtonGroupFieldProps['options'] = [
        {
            id: 2,
            name: '2h',
        },
        {
            id: 3,
            name: '3h',
        },
        {
            id: 4,
            name: '4h',
        },
        {
            id: 6,
            name: '6h',
        },
        {
            id: 12,
            name: '12h',
        },
        {
            id: -1,
            name: 'End of day',
        },
    ]

    return (
        <ToggleButtonGroupField
            value={Number(value)}
            options={durations}
            id={id}
            titledFieldProps={{
                title: 'Simulation Duration',
                subtitle: 'Simulate for the specified duration in the future.',
            }}
            onChange={onChange}
        />
    )
}

export default SimulationDurationField

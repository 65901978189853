import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useConfirm } from 'material-ui-confirm'
import { Grid } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import {
    pushErrorNotification,
    pushSuccessNotification,
} from '../../../../utils/notification'
import LoadingBox from '../../../../components/InformationBox/LoadingBox'
import ErrorBox from '../../../../components/InformationBox/ErrorBox'
import InformationBox from '../../../../components/InformationBox/InformationBox'
import { BreadcrumbsProps } from '../../../../components/PageHeader/PageHeader'
import Page from '../../../../components/Page/Page'
import {
    getGetLiveTrainsQueryKey,
    useCancelTrain,
} from '../../../../services/dst-api'
import TrainsList from '../TrainsList/TrainsList'
import { TrainListDisplayMode } from '../../../../types/TrainListDisplayMode'
import ListIcon from '@mui/icons-material/List'
import CardIcon from '@mui/icons-material/DashboardCustomize'
import LineupIcon from '@mui/icons-material/AlignVerticalTop'
import { ListModeSearchBar } from '../../../../components/ListModeSearchBar/ListModeSearchBar'
import {
    GenericSort,
    SortDirection,
} from '../../../../components/ListModeSearchBar/GenericSortBox/GenericSortBox'
import useTrains from '../../hooks/useTrains'
import { TrainsListSortField } from '../../../../types/TrainsListSortField'

const EditSimulationTrainsContent = () => {
    const [displayMode, setDisplayMode] = useState(TrainListDisplayMode.LIST)
    const [sort, setSort] = useState<GenericSort<TrainsListSortField>>({
        field: TrainsListSortField.ORIGIN_DEPARTURE,
        direction: SortDirection.DESC,
    })
    const confirm = useConfirm()

    //get simulation id from url param
    let { simulationId } = useParams()
    const liveMode = simulationId === undefined
    const validSimulationId = liveMode ? 1 : Number(simulationId)

    // retrieve trains from backend
    const { trains, isLoading, isError } = useTrains(sort, validSimulationId)

    const { mutate } = useCancelTrain()
    const queryClient = useQueryClient()

    if (isLoading) {
        return <LoadingBox />
    }

    if (isError) {
        return <ErrorBox message={'Trains could not be retrieved.'} />
    }

    function handleModeChange(newMode: TrainListDisplayMode) {
        setDisplayMode(newMode)
    }

    const handleTrainDeleteClick = (id: number) => {
        confirm({
            description:
                'Deleting the train is permanent and cannot be undone.',
        })
            .then(() => {
                mutate(
                    {
                        trainId: id,
                        data: {},
                    },
                    {
                        onSuccess: () => {
                            pushSuccessNotification(
                                `Train ${id} deleted successfully.`
                            )
                            queryClient.invalidateQueries(
                                getGetLiveTrainsQueryKey()
                            )
                        },
                        onError: () => {
                            pushErrorNotification(
                                `Failed to delete Train ${id}.`
                            )
                        },
                    }
                )
            })
            .catch(() => {
                console.log('Cancelled train delete')
            })
    }

    if (trains.length === 0) {
        return (
            <InformationBox
                title={'No Trains'}
                message={'No trains exist for this simulation.'}
            />
        )
    }

    return (
        <>
            <ListModeSearchBar
                itemCount={trains.length}
                modeProps={{
                    mode: displayMode,
                    onModeChange: handleModeChange,
                    contentsByMode: {
                        // [TrainListDisplayMode.GRID]: <GridViewIcon />,
                        [TrainListDisplayMode.LIST]: <ListIcon />,
                        [TrainListDisplayMode.CARD]: <CardIcon />,
                        [TrainListDisplayMode.LINEUP]: <LineupIcon />,
                    },
                    descriptionsByMode: {
                        // [TrainListDisplayMode.GRID]: 'Grid',
                        [TrainListDisplayMode.LIST]: 'List',
                        [TrainListDisplayMode.CARD]: 'Card',
                        [TrainListDisplayMode.LINEUP]: 'Lineup',
                    },
                }}
                entityDescription={'trains'}
                sortProps={{
                    value: sort,
                    updateSort: setSort,
                    labels: {
                        [TrainsListSortField.ORIGIN_DEPARTURE]:
                            'Origin Departure',
                        [TrainsListSortField.HEADCODE]: 'Headcode',
                        [TrainsListSortField.ATOC]: 'ATOC',
                    },
                }}
            />

            <TrainsList
                mode={displayMode}
                trains={trains}
                onTrainDelete={handleTrainDeleteClick}
            />
        </>
    )
}

const ManageSimulationTrains = () => {
    let { simulationId } = useParams()
    const liveMode = simulationId === undefined

    const nonLiveModeBreadcrumbs: BreadcrumbsProps = {
        previous: [
            {
                text: 'Simulations',
                to: '/replay',
            },
            {
                text: 'Edit Simulation',
                to: '../basic',
            },
        ],
        current: {
            text: 'Service Changes',
        },
    }

    return (
        <Page
            maxWidth="lg"
            headerProps={{
                title: 'Service Changes',
                actionProps: liveMode
                    ? {
                          type: 'LinkAction',
                          icon: 'add',
                          text: 'Add Train',
                          to: '../train/new',
                      }
                    : undefined,
                breadcrumbConfig: liveMode ? undefined : nonLiveModeBreadcrumbs,
            }}
        >
            <Grid item xs={12}>
                <EditSimulationTrainsContent />
            </Grid>
        </Page>
    )
}

export default ManageSimulationTrains

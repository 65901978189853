type LocationDatasource = {
    id: string
    name: string
    availableData: LocationDataType[]
}

export enum LocationDataType {
    TIPLOC,
    STANOX,
    STANME,
    CRS,
    NLC,
    TOPS_LONG_DESCRIPTION,
    ROUTE,
    HAS_HAD_INCIDENT_TODAY,
    TOTAL_DELAY_MINUTES_THIS_MONTH,
    BLAH,
}

const locationDataTypeDescriptions: Record<LocationDataType, string> = {
    [LocationDataType.TIPLOC]: 'Tiploc',
    [LocationDataType.STANOX]: 'Stanox',
    [LocationDataType.STANME]: 'Stanme',
    [LocationDataType.CRS]: 'Crs',
    [LocationDataType.NLC]: 'Nlc',
    [LocationDataType.TOPS_LONG_DESCRIPTION]: 'Tops Long Description',
    [LocationDataType.ROUTE]: 'Route',
    [LocationDataType.HAS_HAD_INCIDENT_TODAY]: 'Has Had Incident Today',
    [LocationDataType.TOTAL_DELAY_MINUTES_THIS_MONTH]:
        'Total Delay Minutes This Month',
    [LocationDataType.BLAH]: 'Blah',
}

const railLakeLocationDatasources: LocationDatasource[] = [
    {
        id: 'NR',
        name: 'Network Rail',
        availableData: [
            LocationDataType.TIPLOC,
            LocationDataType.STANOX,
            LocationDataType.CRS,
        ],
    },
    {
        id: 'Impact',
        name: 'Impact',
        availableData: [
            LocationDataType.TIPLOC,
            LocationDataType.STANOX,
            LocationDataType.STANME,
            LocationDataType.CRS,
            LocationDataType.NLC,
            LocationDataType.TOPS_LONG_DESCRIPTION,
            LocationDataType.ROUTE,
            LocationDataType.HAS_HAD_INCIDENT_TODAY,
            LocationDataType.TOTAL_DELAY_MINUTES_THIS_MONTH,
            LocationDataType.BLAH,
        ],
    },
    {
        id: 'Compass',
        name: 'Compass',
        availableData: [LocationDataType.TIPLOC, LocationDataType.CRS],
    },
]

const railLakeLocations = {
    getDatasources: (dataType: LocationDataType) =>
        railLakeLocationDatasources.filter((datasource) =>
            datasource.availableData.includes(dataType)
        ),
    getDatasourceById: (id: LocationDatasource['id']) =>
        railLakeLocationDatasources.filter(
            (datasource) => datasource.id === id
        )[0],
    getDataTypeDescription: (dataType: LocationDataType) =>
        locationDataTypeDescriptions[dataType],
}

export default railLakeLocations

import React from 'react'
import { MenuItem } from '@mui/material'
import {
    SelectField,
    SelectFieldProps,
} from '../../../../../components/fields/fields/base/SelectField'
import railLakeLocations from '../../../data/locationDatasources'

type AvailableDataTypesFieldProps = {
    datasourceId: string
    label: string
} & Omit<SelectFieldProps, 'children' | 'label'>

const AvailableDataTypesField = ({
    id,
    datasourceId,
    onChange,
    value,
    label,
    ...rest
}: AvailableDataTypesFieldProps) => {
    const datasource = railLakeLocations.getDatasourceById(datasourceId)

    const listItems = datasource.availableData.map((dataType) => {
        return (
            <MenuItem key={dataType} value={dataType}>
                {railLakeLocations.getDataTypeDescription(dataType)}
            </MenuItem>
        )
    })

    return (
        <SelectField
            id={id}
            value={value}
            onChange={onChange}
            label={label}
            {...rest}
        >
            {listItems}
        </SelectField>
    )
}

export default AvailableDataTypesField

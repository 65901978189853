import React from 'react'
import { MenuItem } from '@mui/material'
import {
    SelectField,
    SelectFieldProps,
} from '../../../../../components/fields/fields/base/SelectField'
import railLakeLocations, {
    LocationDataType,
} from '../../../data/locationDatasources'

type LocationDatasourcesFieldProps = {
    dataType: LocationDataType
} & Omit<SelectFieldProps, 'id' | 'children' | 'label'>

const LocationDatasourcesField = ({
    dataType,
    onChange,
    value,
    ...rest
}: LocationDatasourcesFieldProps) => {
    const tiplocDataSources = railLakeLocations.getDatasources(dataType)

    const listItems = tiplocDataSources.map((datasource) => {
        return (
            <MenuItem key={datasource.id} value={datasource.id}>
                {datasource.name}
            </MenuItem>
        )
    })

    return (
        <SelectField
            id={'locationDatasourceField'}
            value={value}
            onChange={onChange}
            label="Rail Lake Data Source"
            {...rest}
        >
            {listItems}
        </SelectField>
    )
}

export default LocationDatasourcesField

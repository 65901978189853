import { Typography } from '@mui/material'
import React from 'react'

const ResultSectionTitle = ({ children }: { children: string }) => {
    return (
        <Typography variant="h5" color="primary" gutterBottom>
            {children}
        </Typography>
    )
}

export default ResultSectionTitle

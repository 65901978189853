type IncidentType = {
    id: string
    name: string
}

const incidentTypes: IncidentType[] = [
    {
        id: 'LINESPEED',
        name: 'Line Speed',
    },
    {
        id: 'BLOCK',
        name: 'Block',
    },
    {
        id: 'TRAIN',
        name: 'Train',
    },
]

export default incidentTypes

import { styled } from '@mui/material/styles'
import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import React from 'react'

const LabelTableCell = styled(TableCell)(() => ({
    textAlign: 'right',
    width: '1px',
    whiteSpace: 'nowrap',
}))

const ValueTableCell = styled(TableCell)(() => ({
    align: 'left',
}))

export type Property = {
    label: string
    value: string | number
}

type PropertiesGridProps = {
    properties: Property[]
}
const PropertiesGrid = ({ properties }: PropertiesGridProps) => {
    return (
        <Table size="small">
            <TableBody>
                {properties.map((property) => {
                    return (
                        <PropertyItem
                            key={property.label}
                            label={property.label}
                            value={property.value}
                        />
                    )
                })}
            </TableBody>
        </Table>
    )
}

const PropertyItem = ({
    label,
    value,
}: {
    label: string
    value: string | number
}) => {
    return (
        <TableRow>
            <LabelTableCell>{label}:</LabelTableCell>
            <ValueTableCell>{value}</ValueTableCell>
        </TableRow>
    )
}

export default PropertiesGrid

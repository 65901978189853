import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'
import theme from '../../../../../theme/theme'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import React from 'react'
import { Train } from '../../../../../services/dst-api'

const TrainIdentifierContainer = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    height: '4em',
    width: '4em',
    borderRadius: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
}))

const ImportantText = styled(Typography)(() => ({
    ...theme.typography.h5,
    color: 'inherit',
}))

const HeadcodeText = styled(ImportantText)(() => ({
    textAlign: 'center',
}))

const DirectionIcon = styled(ArrowUpwardIcon)(() => ({
    color: 'inherit',
    width: '100%',
}))

type TrainIdentifierProps = {
    train: Train
}

//TODO The direction icon should change depending on the train direction of travel
const TrainIdentifier = ({ train }: TrainIdentifierProps) => {
    return (
        <TrainIdentifierContainer>
            <DirectionIcon />
            <HeadcodeText>{train.headcode}</HeadcodeText>
        </TrainIdentifierContainer>
    )
}

export default TrainIdentifier

import React from 'react'
import { WithAuthenticationRequiredOptions } from '@auth0/auth0-react/dist/with-authentication-required'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import LoadingBox from '../InformationBox/LoadingBox'

export default function withAuthRequired<P extends object>(
    Component: React.ComponentType<P>,
    options?: WithAuthenticationRequiredOptions
): React.FC<P> {
    return withAuthenticationRequired(Component, {
        onRedirecting: () => (
            <div className="page-layout">
                <LoadingBox />
            </div>
        ),
        ...options,
    })
}

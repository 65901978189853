import { Box, CircularProgress } from '@mui/material'

const LoadingBox = () => {
    return (
        <Box p={2} sx={{ textAlign: 'center' }}>
            <CircularProgress />
        </Box>
    )
}

export default LoadingBox

import React from 'react'
import {
    pushErrorNotification,
    pushSuccessNotification,
} from '../../../../../utils/notification'
import {
    CreateSimulationRequest,
    EditSimulationRequest,
    Simulation,
    useCreateSimulation,
    useEditSimulation,
} from '../../../../../services/dst-api'
import dayjs from 'dayjs'
import networks from '../../../../../data/networks'
import { useNavigate } from 'react-router-dom'
import ManageSimulationFormView from './ManageSimulationFormView'
import ErrorBox from '../../../../../components/InformationBox/ErrorBox'

export type ManageSimulationRequest =
    | CreateSimulationRequest
    | EditSimulationRequest

const ManageSimulationForm = ({ simulation }: { simulation?: Simulation }) => {
    const navigate = useNavigate()

    const { mutate: createSimulationMutation, isLoading: isCreateSaving } =
        useCreateSimulation({
            mutation: {
                onSuccess: (simulation) => {
                    pushSuccessNotification('Simulation created successfully')
                    console.log('simulation created', simulation)
                    navigate('/replay')
                },
                onError: (error) => {
                    pushErrorNotification(
                        `Simulation creation failed: ${error}`
                    )
                },
            },
        })

    const { mutate: editSimulationMutation, isLoading: isEditSaving } =
        useEditSimulation({
            mutation: {
                onSuccess: (simulation) => {
                    pushSuccessNotification('Simulation updated successfully')
                    console.log('simulation updated', simulation)
                },
                onError: (error) => {
                    pushErrorNotification(`Simulation update failed: ${error}`)
                },
            },
        })

    const editMode = simulation !== undefined
    if (editMode && !simulation.id) {
        return (
            <ErrorBox
                message={
                    'The simulation being edited does not currently have an identifier assigned.'
                }
            />
        )
    }

    const manageSimulation = (
        request: CreateSimulationRequest | EditSimulationRequest
    ) => {
        if (editMode) {
            editSimulationMutation({
                data: {
                    id: simulation!!.id!!,
                    ...request,
                },
            })
        } else {
            createSimulationMutation({
                data: {
                    ...request,
                },
            })
        }
    }

    const initialValues: ManageSimulationRequest = editMode
        ? {
              ...simulation,
          }
        : {
              title: '',
              network: networks[0].id,
              date: '03/05/2022',
              minorPerturbations: false,
              type: 'REPLAY',
              description: '',
              startTime: dayjs().startOf('day').format('HH:mm'),
              endTime: dayjs().endOf('day').format('HH:mm'),
              iterationCount: 1,
              majorPerturbations: false,
              maximumMajorPerturbation: undefined,
          }
    return (
        <ManageSimulationFormView
            initialValues={initialValues}
            onSubmit={manageSimulation}
            isSaving={isCreateSaving || isEditSaving}
        />
    )
}

export default ManageSimulationForm

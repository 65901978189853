import React from 'react'
import { styled } from '@mui/system'
import theme from '../../theme/theme'

const FooterContainer = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}))

const MtrFooterLogo = styled('img')(() => ({
    height: 30,
}))

const TrenoFooterLogo = styled('img')(
    theme.unstable_sx({
        height: 40,
    })
)

const StyledLayoutFooter = styled('footer')(({ theme }) => ({
    marginTop: 'auto',
    backgroundColor:
        theme.palette.type === 'light'
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
}))

export const Footer = () => {
    return (
        <StyledLayoutFooter>
            <FooterContainer>
                <MtrFooterLogo src={'/images/logos/logo_mtrel.svg'} />
                <TrenoFooterLogo src={'/images/logos/logo_treno.svg'} />
            </FooterContainer>
        </StyledLayoutFooter>
    )
}

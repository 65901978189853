import React from 'react'
import { Header } from '../components/Header'
import { Footer } from '../components/Footer'
import { styled } from '@mui/material/styles'
import { LayoutContent } from '../components/LayoutContent'
import withAuthRequired from '../../components/ProtectedRoute/withAuthRequired'

const MinimalLayoutContainer = styled('div')(() => ({
    paddingTop: 64,
    height: '100%',
}))

type MinimalLayoutProps = {
    children?: React.ReactNode
}

export const InsecureMinimalLayout = ({ children }: MinimalLayoutProps) => {
    return (
        <MinimalLayoutContainer>
            <Header />
            <LayoutContent>
                {children}
                <Footer />
            </LayoutContent>
        </MinimalLayoutContainer>
    )
}

export const MinimalLayout = withAuthRequired(InsecureMinimalLayout)

import React from 'react'
import { SwitchField, SwitchFieldProps } from './base/SwitchField'

type PreviousProposedAmendmentsFieldProps = Pick<
    SwitchFieldProps,
    'id' | 'value' | 'onChange'
>

const PreviousProposedAmendmentsField = ({
    id,
    value,
    onChange,
}: PreviousProposedAmendmentsFieldProps) => {
    return (
        <SwitchField
            id={id}
            titledFieldProps={{
                title: 'Previous Proposed Amendments',
                subtitle:
                    'Disabling this will prevent previous proposed amendments from being considered in this simulation.',
            }}
            onChange={onChange}
            value={value}
        />
    )
}

export default PreviousProposedAmendmentsField

import { LocationVisit } from '../../../services/dst-api'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

export type JourneyOperationMode = 'CANCEL' | 'RUNFAST' | 'TOGGLE_STOP'
export type JourneyOperationDirection = 'FROM' | 'TO'

export type UseJourneyOperationResult = {
    isEnabled: (v: LocationVisit) => boolean
    isBlockedBy: (v: LocationVisit) => boolean
    isSupported: (journey: LocationVisit[], visit: LocationVisit) => boolean
    getDescription: (visit: LocationVisit) => string
    submitWillEnable: (
        journey: LocationVisit[],
        visit: LocationVisit,
        activeVisit: LocationVisit | null
    ) => boolean
    submitWillDisable: (
        journey: LocationVisit[],
        visit: LocationVisit,
        activeVisit: LocationVisit | null
    ) => boolean
    icon: IconDefinition
}

const useJourneyOperation = (
    mode: JourneyOperationMode,
    direction?: JourneyOperationDirection
): UseJourneyOperationResult => {
    const isEnabled = (v: LocationVisit, modifiesEnd: boolean) => {
        const visitState = v.state
        switch (mode) {
            case 'CANCEL':
                return modifiesEnd
                    ? !!visitState?.cancelledFrom
                    : !!visitState?.cancelledTo
            case 'RUNFAST':
                return modifiesEnd
                    ? !!visitState?.runFastFrom
                    : !!visitState?.runFastTo
            case 'TOGGLE_STOP':
                return (
                    !!visitState?.runFastFrom &&
                    !!visitState?.runFastTo &&
                    !!visitState?.cancelledFrom &&
                    !!visitState?.cancelledTo
                )
        }
    }

    const isBlockedBy = (v: LocationVisit) => {
        return isEnabled(v, !modifiesJourneyEnd)
    }

    const modifiesJourneyEnd = direction === 'FROM'

    const isSupported = (journey: LocationVisit[], visit: LocationVisit) => {
        const enabled = isEnabled(visit, modifiesJourneyEnd)

        const firstVisitInJourney = visit.sequence === 0
        if (firstVisitInJourney) {
            return modifiesJourneyEnd ? true : !enabled
        }

        const lastVisitInJourney = journey[journey.length - 1].id === visit.id
        if (lastVisitInJourney) {
            return modifiesJourneyEnd ? !enabled : true
        }

        //TODO check this logic works
        const visitsOrderedForBlocking = modifiesJourneyEnd
            ? [...journey].reverse()
            : journey
        const firstBlockingVisit = visitsOrderedForBlocking.find((v) =>
            isBlockedBy(v)
        )
        if (firstBlockingVisit) {
            if (firstBlockingVisit.sequence === visit.sequence) return true

            const blockingVisitIsAfterThisVisit =
                firstBlockingVisit.sequence > visit.sequence
            return modifiesJourneyEnd
                ? blockingVisitIsAfterThisVisit
                : !blockingVisitIsAfterThisVisit
        }

        return false
    }

    const getDescription = (visit: LocationVisit) => {
        const fromLocation = modifiesJourneyEnd ? visit.location.name : 'Origin'
        const toLocation = modifiesJourneyEnd
            ? 'Destination'
            : visit.location.name
        const typeDesc =
            mode === 'CANCEL' ? 'Cancel service' : 'Run service fast'
        return `${typeDesc} from ${fromLocation} to ${toLocation}`
    }

    const submitWillEnable = (
        journey: LocationVisit[],
        visit: LocationVisit,
        activeVisit: LocationVisit | null
    ) => {
        if (!activeVisit) return false
        if (activeVisit.sequence === visit.sequence) return false
        const afterActiveVisit = activeVisit.sequence < visit.sequence
        return modifiesJourneyEnd ? afterActiveVisit : !afterActiveVisit
    }

    const submitWillDisable = (
        journey: LocationVisit[],
        visit: LocationVisit,
        activeVisit: LocationVisit | null
    ) => {
        const enabled = isEnabled(visit, modifiesJourneyEnd)
        if (!enabled) return false
        if (!activeVisit) return false
        if (activeVisit.sequence === visit.sequence) return false
        const beforeActiveVisit = activeVisit.sequence > visit.sequence
        return modifiesJourneyEnd ? beforeActiveVisit : !beforeActiveVisit
    }

    const icon = modifiesJourneyEnd ? solid('up-long') : solid('down-long')

    return {
        isEnabled: (v: LocationVisit) => isEnabled(v, modifiesJourneyEnd),
        isBlockedBy,
        isSupported,
        getDescription,
        submitWillEnable,
        submitWillDisable,
        icon,
    }
}

export default useJourneyOperation

import React from 'react'
import TimePickerField from '../../../../components/fields/fields/base/TimePickerField'
import {
    Button,
    Checkbox,
    FormControl,
    IconButton,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Stack,
    SvgIcon,
    Tooltip,
} from '@mui/material'
import TrainGraphSettingsDialog from '../TrainGraphSettings/TrainGraphSettingsDialog'
import dayjs from 'dayjs'
import { AllTrainGraphSettings } from '../../hooks/useTrainGraphSettings'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

type TrainGraphToolbarProps = {
    settings: AllTrainGraphSettings
    onFullscreen: () => void
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const TrainGraphToolbar = ({
    settings,
    onFullscreen,
}: TrainGraphToolbarProps) => {
    const [openMoreSettings, setOpenMoreSettings] = React.useState(false)
    const handleClickMoreSettings = () => {
        setOpenMoreSettings(true)
    }

    const handleTimeChange = (value: string | undefined) => {
        if (value === undefined) return
        if (!dayjs(value, 'HH:mm').isValid()) return
        settings.time.setStartTime(value)
    }

    const handlePeriodChange = (event: SelectChangeEvent<number>) => {
        settings.period.update(Number(event.target.value))
    }

    const handleHoverModeChange = (event: SelectChangeEvent<number>) => {
        const mode = Number(event.target.value)
        let hoverMode = settings.settings.hoverMode
        switch (mode) {
            case 1:
                hoverMode = 'dataset'
                break
            case 2:
                hoverMode = 'y'
                break
            case 3:
                hoverMode = 'point'
                break
        }
        settings.updateSettings({
            hoverMode: hoverMode,
        })
    }

    const handleSectionChange = (event: SelectChangeEvent<string[]>) => {
        const {
            target: { value },
        } = event
        settings.sections.setEnabledSections(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        )
    }

    return (
        <React.Fragment>
            <TrainGraphSettingsDialog
                open={openMoreSettings}
                close={() => setOpenMoreSettings(false)}
                save={settings.updateSettings}
                settings={settings.settings}
            />
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Stack direction={'row'} spacing={2}>
                    <Stack direction={'row'} spacing={0}>
                        <Tooltip title="Decrease Time">
                            <IconButton
                                onClick={() => settings.time.decrement()}
                            >
                                <SvgIcon fontSize="small">
                                    <ChevronLeftIcon />
                                </SvgIcon>
                            </IconButton>
                        </Tooltip>
                        <TimePickerField
                            id={'trainGraphStartTimeField'}
                            label={'Time'}
                            value={settings.time.startTime}
                            onChange={handleTimeChange}
                        />
                        <Tooltip title="Increase Time">
                            <IconButton
                                onClick={() => settings.time.increment()}
                            >
                                <SvgIcon fontSize="small">
                                    <ChevronRightIcon />
                                </SvgIcon>
                            </IconButton>
                        </Tooltip>
                    </Stack>
                    <FormControl>
                        <InputLabel>Period</InputLabel>
                        <Select
                            value={settings.period.value}
                            label="Period"
                            onChange={handlePeriodChange}
                        >
                            <MenuItem value={15}>15m</MenuItem>
                            <MenuItem value={30}>30m</MenuItem>
                            <MenuItem value={60}>1h</MenuItem>
                            <MenuItem value={120}>2h</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel>Hover Mode</InputLabel>
                        <Select
                            value={
                                settings.settings.hoverMode === 'dataset'
                                    ? 1
                                    : settings.settings.hoverMode === 'y'
                                    ? 2
                                    : 3
                            }
                            label="Hover Mode"
                            onChange={handleHoverModeChange}
                        >
                            <MenuItem value={1}>Data Set</MenuItem>
                            <MenuItem value={2}>Y</MenuItem>
                            <MenuItem value={3}>Point</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{ width: '200px' }}>
                        <InputLabel>Sections</InputLabel>
                        <Select
                            multiple
                            value={settings.sections.enabledSectionNames}
                            onChange={handleSectionChange}
                            input={<OutlinedInput label="Tag" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                            {settings.sections.allSections.map((section) => (
                                <MenuItem
                                    key={section.name}
                                    value={section.name}
                                >
                                    <Checkbox
                                        checked={settings.sections.isEnabled(
                                            section.name
                                        )}
                                    />
                                    <ListItemText primary={section.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
                <Stack direction={'row'} spacing={2}>
                    <Button onClick={handleClickMoreSettings}>Settings</Button>
                    <Button onClick={onFullscreen}>Full Screen</Button>
                </Stack>
            </Stack>
        </React.Fragment>
    )
}

export default TrainGraphToolbar

import { styled } from '@mui/material/styles'
import React, { ReactNode } from 'react'
import { CircularProgress, Grid, Paper, Typography } from '@mui/material'
import { useGetIncidents } from '../../../../services/dst-api'
import { Link } from 'react-router-dom'

type StatsViewProps = {
    title: String
    value: number | string | undefined
    isLoading: boolean
}

const StatisticContainer = styled(Paper)(({ theme }) => ({
    height: '100px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: theme.spacing(2),
    alignItems: 'center',
    backgroundImage: `linear-gradient(160deg, ${theme.palette.secondary.dark} 0%, ${theme.palette.secondary.main} 100%)`,
    gap: theme.spacing(2),
    color: theme.palette.secondary.contrastText,
}))

const StatisticTitle = styled(Typography)(({ theme }) => ({
    ...theme.typography.h4,
    flexGrow: 1,
    textAlign: 'center',
    color: 'inherit',
}))

const StatisticValueText = styled(Typography)(({ theme }) => ({
    ...theme.typography.h2,
    color: 'inherit',
}))

const StatisticValueContainer = styled(Paper)(({ theme }) => ({
    aspectRatio: '1 / 1',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.secondary.light,
    color: 'inherit',
}))

const StatisticValue = ({ value, isLoading }: Partial<StatsViewProps>) => {
    if (isLoading) {
        return (
            <StatisticValueContainer>
                <CircularProgress />
            </StatisticValueContainer>
        )
    }

    return (
        <StatisticValueContainer>
            <StatisticValueText>{value}</StatisticValueText>
        </StatisticValueContainer>
    )
}

const Statistic = ({ title, value, isLoading }: StatsViewProps) => {
    return (
        <StatisticContainer>
            <StatisticValue value={value} isLoading={isLoading} />
            <StatisticTitle>{title}</StatisticTitle>
        </StatisticContainer>
    )
}

const StatsListViewLayout = ({ children }: { children: ReactNode[] }) => {
    return (
        <Grid container spacing={2} direction={'row'}>
            {children.map((child, index) => {
                return (
                    <Grid item xs={12} sm={6} lg={3} key={index}>
                        {child}
                    </Grid>
                )
            })}
        </Grid>
    )
}

type SimulationStatisticsProps = {
    simulationId: number
}

const SimulationStatistics = ({ simulationId }: SimulationStatisticsProps) => {
    const { data: incidents, isLoading: incidentsLoading } = useGetIncidents({
        simulationId: simulationId,
    })

    // TODO implement service/stock/crew stat retrieval
    // const unimplementedStatisticText = "?"
    const unimplementedStatisticText = '0'

    return (
        <StatsListViewLayout>
            <Link to={'../edit/incidents'}>
                <Statistic
                    title={'Incidents'}
                    value={incidents?.length}
                    isLoading={incidentsLoading}
                />
            </Link>
            <Link to={'../edit/services'}>
                <Statistic
                    title={'Service Changes'}
                    value={unimplementedStatisticText}
                    isLoading={false}
                />
            </Link>
            <Link to={'../edit/stock'}>
                <Statistic
                    title={'Stock Changes'}
                    value={unimplementedStatisticText}
                    isLoading={false}
                />
            </Link>
            <Link to={'../edit/crew'}>
                <Statistic
                    title={'Crew Changes'}
                    value={unimplementedStatisticText}
                    isLoading={false}
                />
            </Link>
        </StatsListViewLayout>
    )
}

export default SimulationStatistics

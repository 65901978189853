import {
    LocationVisit,
    Train,
    useGetVisitsHook,
} from '../../../services/dst-api'
import { useEffect, useState } from 'react'

export type TrainsSchedulesManager = {
    loading: boolean
    error?: Error
    getSchedule: (train: Train) => LocationVisit[] | null
}

export type UseTrainsSchedulesProps = {
    trains: Train[]
}

const useTrainsSchedules = ({
    trains,
}: UseTrainsSchedulesProps): TrainsSchedulesManager => {
    const [status, setStatus] = useState<{
        loading: boolean
        error?: Error
        data?: LocationVisit[][]
    }>({
        loading: false,
    })
    const sds = useGetVisitsHook()

    useEffect(() => {
        setStatus({ loading: true })
        Promise.all(trains.map((train) => sds.call(this, train.id)))
            .then((value) =>
                setStatus({
                    loading: false,
                    data: value,
                })
            )
            .catch((error: Error) => {
                setStatus({ loading: false, error })
            })
    }, [trains])

    const getSchedule = (train: Train) => {
        const schedules = status.data
        if (!schedules) return null

        const index = trains.indexOf(train)
        if (index === -1) {
            console.log('Train not found')
            return null
        }

        const schedule = schedules[index]
        return schedule ? schedule : null
    }

    return {
        loading: status.loading,
        error: status.error,
        getSchedule: getSchedule,
    }
}

export default useTrainsSchedules

import React from 'react'
import LoadingBox from '../../../../components/InformationBox/LoadingBox'
import ErrorBox from '../../../../components/InformationBox/ErrorBox'
import InformationBox from '../../../../components/InformationBox/InformationBox'
import usePreviousLiveSimulation from '../../hooks/usePreviousLiveSimulation'
import { Navigate } from 'react-router-dom'

const PreviousRecommendationsView = () => {
    const { isLoading, isError, simulation } = usePreviousLiveSimulation()

    if (isLoading) {
        return <LoadingBox />
    }

    if (isError) {
        return <ErrorBox message={'Simulations could not be retrieved.'} />
    }

    if (!simulation) {
        return (
            <InformationBox
                title={'No Previous Simulations'}
                message={
                    'There are no results to display yet as no simulations have been created.'
                }
            />
        )
    }

    return (
        <Navigate
            to={`/simulation/${simulation.id!!}/overview?mode=LIVE`}
            replace={true}
        />
    )
}

export default PreviousRecommendationsView

import { Train } from '../../../../../services/dst-api'
import { styled } from '@mui/material/styles'
import { Divider, Paper } from '@mui/material'
import React, { ReactNode, useState } from 'react'
import TrainIdentifier from './TrainIdentifier'
import TimetableOverview from './TimetableOverview'
import { ArrowRight } from '@mui/icons-material'

type TrainListItemProps = {
    train: Train
    children?: ReactNode
    collapsible: boolean
    orientation: 'vertical' | 'horizontal'
}

const ListItemWrapper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
}))

const ListItem = styled('div')<
    Pick<TrainListItemProps, 'orientation'> &
        Pick<TrainListItemProps, 'collapsible'>
>(({ theme, orientation, collapsible }) => ({
    display: 'flex',
    flexDirection: orientation === 'horizontal' ? 'row' : 'column',
    gap: orientation === 'horizontal' ? theme.spacing(2) : theme.spacing(0.5),
    alignItems: 'center',
    cursor: collapsible ? 'pointer' : 'default',
}))

const TrainListItem = ({
    train,
    children,
    collapsible,
    orientation,
}: TrainListItemProps) => {
    const [expanded, setExpanded] = useState(false)

    const toggleExpanded = () => {
        if (collapsible) setExpanded(!expanded)
    }

    const displayModifications = orientation === 'horizontal'

    return (
        <ListItemWrapper>
            <ListItem
                onClick={toggleExpanded}
                orientation={orientation}
                collapsible={collapsible}
            >
                <TrainIdentifier train={train} />
                <Divider orientation="vertical" variant="middle" flexItem />
                <TimetableOverview
                    train={train}
                    orientation={orientation}
                    displayModifications={displayModifications}
                />
                {collapsible && (
                    <>
                        <Divider
                            orientation="vertical"
                            variant="middle"
                            flexItem
                        />
                        <ArrowRight />
                    </>
                )}
            </ListItem>
            {(expanded || !collapsible) && children}
        </ListItemWrapper>
    )
}

export default TrainListItem

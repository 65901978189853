import { Switch } from '@mui/material'
import React from 'react'
import BaseTitledField, { TitledFieldProps } from './BaseTitledField'
import { BaseFieldWithChangeHandlerProps } from './BaseField'

export interface SwitchFieldProps extends BaseFieldWithChangeHandlerProps {
    value: boolean
    titledFieldProps: TitledFieldProps
}

export const SwitchField = ({
    id,
    onChange,
    value,
    titledFieldProps,
}: SwitchFieldProps) => {
    return (
        <BaseTitledField {...titledFieldProps}>
            <Switch
                id={id}
                name={id}
                checked={value}
                color="secondary"
                edge="start"
                onChange={onChange}
                value={value}
            />
        </BaseTitledField>
    )
}

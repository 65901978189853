import React from 'react'
import { Typography } from '@mui/material'
import BaseField from './BaseField'

export interface TitledFieldProps {
    title: string
    subtitle: string
}

type BaseTitledFieldProps = {
    children: React.ReactNode
} & TitledFieldProps

const BaseTitledField = ({
    title,
    subtitle,
    children,
}: BaseTitledFieldProps) => {
    return (
        <BaseField>
            <Typography variant="h6" color="textPrimary">
                {title}
            </Typography>
            <Typography variant="body2" color="textSecondary">
                {subtitle}
            </Typography>
            {children}
        </BaseField>
    )
}

export default BaseTitledField

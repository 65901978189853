import { utils, WorkBook } from 'xlsx'
import { useEffect, useState } from 'react'

let XLSX = require('xlsx')

const useExcelWorkbook = (file: File) => {
    const [status, setStatus] = useState<{
        loading: boolean
        error?: Error
        data?: WorkBook
    }>({
        loading: false,
    })

    function parseFile(file: File) {
        setStatus({ loading: true })
        file.arrayBuffer()
            .then((data: ArrayBuffer) => XLSX.read(data, { cellDates: true }))
            .then((workbook: WorkBook) => {
                setStatus({ loading: false, data: workbook })
            })
            .catch((error: Error) => {
                setStatus({ loading: false, error })
            })
    }

    useEffect(() => {
        parseFile(file)
    }, [file])

    const parseSheet = <T>(sheetName: string): T[] => {
        if (status.data === undefined) return []
        const sheet = status.data.Sheets[sheetName]
        return utils.sheet_to_json<T>(sheet)
    }

    return { ...status, parseSheet }
}

export default useExcelWorkbook

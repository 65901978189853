import {
    SelectField,
    SelectFieldProps,
} from '../../../../../components/fields/fields/base/SelectField'
import { MenuItem } from '@mui/material'
import React from 'react'

type LineColourStrategyFieldProps = Omit<SelectFieldProps, 'children'>

export type LineColourStrategy = {
    id: 'TOC' | 'CLASS_TYPE' | 'NONE'
    name: string
}

const LineColourStrategyField = ({
    id,
    onChange,
    value,
    ...rest
}: LineColourStrategyFieldProps) => {
    const journeyTypes: LineColourStrategy[] = [
        {
            id: 'NONE',
            name: 'Default',
        },
        {
            id: 'TOC',
            name: 'TOC',
        },
        {
            id: 'CLASS_TYPE',
            name: 'Train Type',
        },
    ]

    const listItems = journeyTypes.map((incidentType) => {
        return (
            <MenuItem key={incidentType.id} value={incidentType.id}>
                {incidentType.name}
            </MenuItem>
        )
    })

    return (
        <SelectField id={id} value={value} onChange={onChange} {...rest}>
            {listItems}
        </SelectField>
    )
}

export default LineColourStrategyField

import React from 'react'
import { Container, Grid } from '@mui/material'
import { Integration } from '../../../../../data/integrations'
import InformationBox from '../../../../../components/InformationBox/InformationBox'
import IntegrationCard from './IntegrationCard'

type IntegrationListProps = {
    integrations: Integration[]
    onToggleEnabled: (id: Integration['id']) => void
}

function IntegrationsList({
    integrations,
    onToggleEnabled,
}: IntegrationListProps) {
    if (integrations.length === 0) {
        return (
            <InformationBox
                title={'No Integrations'}
                message={'No integrations available.'}
            />
        )
    }

    return (
        <Container maxWidth="lg">
            <Grid container spacing={4}>
                {integrations.map((integration) => (
                    <Grid item key={integration.id} xs={12} md={4} lg={4}>
                        <IntegrationCard
                            integration={integration}
                            onChange={onToggleEnabled}
                        />
                    </Grid>
                ))}
            </Grid>
        </Container>
    )
}
export default IntegrationsList

import React from 'react'
import { Integration } from '../../../../../data/integrations'
import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import {
    Box,
    Button,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Switch,
    Typography,
} from '@mui/material'
import { Link } from 'react-router-dom'

const IntegrationCardContainer = styled(Card)(() => ({
    width: '100%',
    height: 400,
    display: 'flex',
    flexDirection: 'column',
}))

const IntegrationCardActionArea = styled(CardActionArea)(() => ({
    flexGrow: 1,
    alignItems: 'stretch',
    flexDirection: 'column',
}))

const IntegrationCardMedia = styled(CardMedia)(() => ({
    objectFit: 'fill',
    height: '140px',
}))

const TopBox = styled(Box)(() => ({
    backgroundColor: '#3f51b522',
}))

const FlexGrowSpacer = styled('div')(() => ({
    flexGrow: 1,
}))

const RightAligned = styled('div')(({ theme }) => ({
    float: 'right',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
}))

type IntegrationLCardProps = {
    integration: Integration
    onChange: (id: Integration['id']) => void
}

function IntegrationCard({ integration, onChange }: IntegrationLCardProps) {
    function getIntegrationImage(integration: Integration) {
        return `/images/routes/${integration.id}.svg`
    }

    function handleCardClick() {
        console.log('Integration card clicked')
    }

    return (
        <IntegrationCardContainer>
            <IntegrationCardActionArea onClick={handleCardClick}>
                <TopBox>
                    <RightAligned>
                        <Switch
                            name={`integration-${integration.id}-switch`}
                            checked={integration.enabled}
                            color="secondary"
                            edge="start"
                            onChange={() => onChange(integration.id)}
                            value={integration.enabled}
                        />
                    </RightAligned>
                    <IntegrationCardMedia
                        // component="img"
                        // alt="Integration"
                        image={getIntegrationImage(integration)}
                        title="Integration"
                    />
                </TopBox>
                <CardContent>
                    <Typography gutterBottom variant="h5">
                        {integration.title}
                    </Typography>
                    <Typography>{integration.description}</Typography>
                </CardContent>
            </IntegrationCardActionArea>
            <CardActions>
                {/* Show view results button for sim type 'COMPLETE' */}
                {integration.enabled && (
                    <Link to={`./integration/${integration.id}/overview`}>
                        <Button size="small" color="primary">
                            Settings
                        </Button>
                    </Link>
                )}
            </CardActions>
        </IntegrationCardContainer>
    )
}

export default IntegrationCard

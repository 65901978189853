/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Decision Support Tool API
 * Decision Support Tool Application
 * OpenAPI spec version: v0.0.1
 */
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  LiveSettings,
  GetLiveSettings400Item,
  EditLiveSettings400Item,
  UpdateLiveSettingsRequest,
  Simulation,
  ExecuteLiveSimulation400Item,
  Train
} from '.././model'
import { useCustomAxios } from '../../../utils/use-custom-axios';


/**
 * This method retrieves the settings for live mode
 * @summary Get live mode settings
 */
export const useGetLiveSettingsHook = () => {
        const getLiveSettings = useCustomAxios<LiveSettings>();

        return (
    
 signal?: AbortSignal
) => {
        return getLiveSettings(
          {url: `/live/settings`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetLiveSettingsQueryKey = () => [`/live/settings`];

    
export type GetLiveSettingsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetLiveSettingsHook>>>>
export type GetLiveSettingsQueryError = GetLiveSettings400Item[]

export const useGetLiveSettings = <TData = Awaited<ReturnType<ReturnType<typeof useGetLiveSettingsHook>>>, TError = GetLiveSettings400Item[]>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLiveSettingsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetLiveSettingsQueryKey();

  const getLiveSettings =  useGetLiveSettingsHook();


  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetLiveSettingsHook>>>> = ({ signal }) => getLiveSettings(signal);


  

  const query = useQuery<Awaited<ReturnType<ReturnType<typeof useGetLiveSettingsHook>>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * Edit the settings for live mode
 * @summary Edit live settings
 */
export const useEditLiveSettingsHook = () => {
        const editLiveSettings = useCustomAxios<LiveSettings>();

        return (
    updateLiveSettingsRequest: UpdateLiveSettingsRequest,
 ) => {
        return editLiveSettings(
          {url: `/live/settings`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: updateLiveSettingsRequest
    },
          );
        }
      }
    


    export type EditLiveSettingsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useEditLiveSettingsHook>>>>
    export type EditLiveSettingsMutationBody = UpdateLiveSettingsRequest
    export type EditLiveSettingsMutationError = EditLiveSettings400Item[]

    export const useEditLiveSettings = <TError = EditLiveSettings400Item[],
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useEditLiveSettingsHook>>>, TError,{data: UpdateLiveSettingsRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      const editLiveSettings =  useEditLiveSettingsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useEditLiveSettingsHook>>>, {data: UpdateLiveSettingsRequest}> = (props) => {
          const {data} = props ?? {};

          return  editLiveSettings(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof editLiveSettings>>, TError, {data: UpdateLiveSettingsRequest}, TContext>(mutationFn, mutationOptions);
    }
    /**
 * Create and execute a live mode simulation
 * @summary Start live simulation
 */
export const useExecuteLiveSimulationHook = () => {
        const executeLiveSimulation = useCustomAxios<Simulation>();

        return (
    
 signal?: AbortSignal
) => {
        return executeLiveSimulation(
          {url: `/live/execute`, method: 'get', signal
    },
          );
        }
      }
    

export const getExecuteLiveSimulationQueryKey = () => [`/live/execute`];

    
export type ExecuteLiveSimulationQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useExecuteLiveSimulationHook>>>>
export type ExecuteLiveSimulationQueryError = ExecuteLiveSimulation400Item[]

export const useExecuteLiveSimulation = <TData = Awaited<ReturnType<ReturnType<typeof useExecuteLiveSimulationHook>>>, TError = ExecuteLiveSimulation400Item[]>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useExecuteLiveSimulationHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExecuteLiveSimulationQueryKey();

  const executeLiveSimulation =  useExecuteLiveSimulationHook();


  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useExecuteLiveSimulationHook>>>> = ({ signal }) => executeLiveSimulation(signal);


  

  const query = useQuery<Awaited<ReturnType<ReturnType<typeof useExecuteLiveSimulationHook>>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * Retrieve all trains for a live mode simulation
 * @summary Start live simulation
 */
export const useGetLiveTrainsHook = () => {
        const getLiveTrains = useCustomAxios<Train[]>();

        return (
    
 signal?: AbortSignal
) => {
        return getLiveTrains(
          {url: `/live/trains`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetLiveTrainsQueryKey = () => [`/live/trains`];

    
export type GetLiveTrainsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetLiveTrainsHook>>>>
export type GetLiveTrainsQueryError = unknown

export const useGetLiveTrains = <TData = Awaited<ReturnType<ReturnType<typeof useGetLiveTrainsHook>>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLiveTrainsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetLiveTrainsQueryKey();

  const getLiveTrains =  useGetLiveTrainsHook();


  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetLiveTrainsHook>>>> = ({ signal }) => getLiveTrains(signal);


  

  const query = useQuery<Awaited<ReturnType<ReturnType<typeof useGetLiveTrainsHook>>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


import React from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { PlusCircle as PlusCircleIcon } from 'react-feather'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
    Breadcrumbs as MuiBreadcrumbs,
    Button,
    Link,
    SvgIcon,
    Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'

interface CurrentBreadcrumbItem {
    text: string
}

interface PreviousBreadcrumbItem extends CurrentBreadcrumbItem {
    to: string
}

type BreadcrumbRequiredProp = {
    previous: PreviousBreadcrumbItem[]
    current: CurrentBreadcrumbItem
}
type BreadcrumbOptionalProp = {
    previous?: undefined
    current?: undefined
}
export type BreadcrumbsProps = BreadcrumbRequiredProp | BreadcrumbOptionalProp

const Breadcrumbs = ({ previous, current }: BreadcrumbsProps) => {
    const disabled = !previous || !current
    if (disabled) return <></>

    return (
        <MuiBreadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
        >
            {previous.map((item) => {
                return (
                    <Link
                        key={item.text}
                        variant="body1"
                        color="inherit"
                        to={item.to}
                        component={RouterLink}
                    >
                        {item.text}
                    </Link>
                )
            })}

            <Typography variant="body1" color="textPrimary">
                {current.text}
            </Typography>
        </MuiBreadcrumbs>
    )
}

type HeaderActionProps = LinkAction | ClickAction

interface BaseHeaderAction {
    icon: 'add'
    text: string
}

interface ClickAction extends BaseHeaderAction {
    onClick: () => void
    to?: undefined
    type: 'ClickAction'
}

interface LinkAction extends BaseHeaderAction {
    onClick?: undefined
    to: string
    type: 'LinkAction'
}

const ActionButton = styled(Button)(({ theme }) => ({
    marginBottom: theme.spacing(1),
    // backgroundColor: theme.palette.secondary.main,
    '& + &': {
        marginLeft: theme.spacing(1),
    },
}))

const ActionIcon = styled(SvgIcon)(({ theme }) => ({
    marginRight: theme.spacing(1),
}))

const HeaderAction = ({ type, icon, text, onClick, to }: HeaderActionProps) => {
    const navigate = useNavigate()

    let iconElement = <></>
    switch (icon) {
        case 'add':
            iconElement = <PlusCircleIcon />
            break
        default:
            const exhaustiveCheck: never = icon
            throw new Error(`Unhandled case: ${exhaustiveCheck}`)
    }

    switch (type) {
        case 'ClickAction':
            return (
                <ActionButton variant="outlined" onClick={onClick}>
                    <ActionIcon fontSize="small">{iconElement}</ActionIcon>
                    {text}
                </ActionButton>
            )
        case 'LinkAction':
            return (
                <ActionButton variant="outlined" onClick={() => navigate(to)}>
                    <ActionIcon fontSize="small">{iconElement}</ActionIcon>
                    {text}
                </ActionButton>
            )
        default:
            const exhaustiveCheck: never = type
            throw new Error(`Unhandled case: ${exhaustiveCheck}`)
    }
}

const LayoutGrid = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
}))

export interface PageHeaderProps {
    title: string
    breadcrumbConfig?: BreadcrumbsProps
    actionProps?: HeaderActionProps
}

const HeaderItems = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'column',
}))

const PageHeader = ({
    title,
    breadcrumbConfig = { previous: undefined, current: undefined },
    actionProps,
}: PageHeaderProps) => {
    return (
        <LayoutGrid>
            <HeaderItems>
                <Breadcrumbs {...breadcrumbConfig} />
                <Typography variant="h3" color="textPrimary">
                    {title}
                </Typography>
            </HeaderItems>
            <HeaderItems>
                {actionProps && <HeaderAction {...actionProps} />}
            </HeaderItems>
        </LayoutGrid>
    )
}

export default PageHeader

// LocationMeters Entities

export type RawTrainGraphLocation = {
    tiploc: string
    distance: number
    seq: number
    level: number
}

export type RawTrainGraphSection = {
    name: string
    description: string
    locations: Array<RawTrainGraphLocation>
}

export type TrainGraphLocation = {
    tiploc: string
    distance: number
    level: number
}

export type TrainGraphSection = {
    name: string
    description: string
    locations: Array<TrainGraphLocation>
}

const rawRouteSections: RawTrainGraphSection[] =
    require('./rawTrainGraphSections.json').sections

const trainGraphSections: TrainGraphSection[] = rawRouteSections.map((s) => {
    return {
        ...s,
        locations: s.locations.sort((a, b) => (a.seq < b.seq ? -1 : 1)),
    }
})

export const getTrainGraphSectionByName = (name: string): TrainGraphSection => {
    const section = trainGraphSections.find((s) => s.name === name)
    return section!!
}

import React, { createContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import LoadingBox from '../../../components/InformationBox/LoadingBox'
import ErrorBox from '../../../components/InformationBox/ErrorBox'
import { Simulation, useGetSimulation } from '../../../services/dst-api'

// export type SimulationDispatcher = {
//     setTime: (time: Simulation['time']) => void
// }

// Create two context:
// UserContext: to query the context state
// UserDispatchContext: to mutate the context state
const SimulationContext = createContext<Simulation | null>(null)

// const SimulationDispatchContext = createContext<
//     SimulationDispatcher | undefined
// >(undefined)

// A "provider" is used to encapsulate only the
// components that needs the state in this context
function SimulationProvider({ children }: { children: React.ReactNode }) {
    let { simulationId } = useParams()
    const { data, isLoading, isError } = useGetSimulation({
        simulationId: parseInt(simulationId!!),
    })
    const [simulation, setSimulation] = useState<Simulation | null>(null)

    useEffect(() => {
        setSimulation(data ? data : null)
    }, [data])

    if (isLoading) {
        return <LoadingBox />
    }

    if (isError) {
        return (
            <ErrorBox message={'Simulation results could not be retrieved.'} />
        )
    }

    if (data === undefined) {
        return <ErrorBox message={'Simulation could not be found.'} />
    }

    return (
        <SimulationContext.Provider value={simulation}>
            {children}
        </SimulationContext.Provider>
    )
}

export {
    SimulationProvider,
    SimulationContext,
    // SimulationDispatchContext,
}

import { TrainChartColourScheme } from '../hooks/useTrainGraphSettings'

export const colourSchemes: TrainChartColourScheme[] = [
    {
        background: '#000000',
        grid: '#ffffff22',
        label: {
            background: 'green',
            color: 'white',
        },
        primary: {
            line: {
                color: '#ffffff88',
            },
        },
        comparator: {
            line: {
                color: '#ff000088',
            },
        },
        ticks: {
            text: '#cc0000',
        },
    },
    {
        background: '#000000',
        grid: '#ffffff33',
        label: {
            background: 'green',
            color: 'white',
        },
        primary: {
            line: {
                color: 'forestgreen',
            },
        },
        comparator: {
            line: {
                color: '#ff000088',
            },
        },
        ticks: {
            text: '#bbbbbb',
        },
    },
]

/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Decision Support Tool API
 * Decision Support Tool Application
 * OpenAPI spec version: v0.0.1
 */
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  LocationVisit,
  CancelTrainBody,
  ReinstateTrainBody,
  CancelFromBody,
  CancelToBody,
  RunFastFromBody,
  RunFastToBody,
  ToggleStopBody
} from '.././model'
import { useCustomAxios } from '../../../utils/use-custom-axios';


/**
 * Retrieve the location journey for a given train
 * @summary Get Train Visits
 */
export const useGetVisitsHook = () => {
        const getVisits = useCustomAxios<LocationVisit[]>();

        return (
    trainId: number,
 signal?: AbortSignal
) => {
        return getVisits(
          {url: `/train/${trainId}/visits`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetVisitsQueryKey = (trainId: number,) => [`/train/${trainId}/visits`];

    
export type GetVisitsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetVisitsHook>>>>
export type GetVisitsQueryError = void

export const useGetVisits = <TData = Awaited<ReturnType<ReturnType<typeof useGetVisitsHook>>>, TError = void>(
 trainId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetVisitsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetVisitsQueryKey(trainId);

  const getVisits =  useGetVisitsHook();


  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetVisitsHook>>>> = ({ signal }) => getVisits(trainId, signal);


  

  const query = useQuery<Awaited<ReturnType<ReturnType<typeof useGetVisitsHook>>>, TError, TData>(queryKey, queryFn, {enabled: !!(trainId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * Fully cancel this train
 * @summary Cancel Train
 */
export const useCancelTrainHook = () => {
        const cancelTrain = useCustomAxios<void>();

        return (
    trainId: number,
    cancelTrainBody: CancelTrainBody,
 ) => {
        return cancelTrain(
          {url: `/train/${trainId}/cancel`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: cancelTrainBody
    },
          );
        }
      }
    


    export type CancelTrainMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCancelTrainHook>>>>
    export type CancelTrainMutationBody = CancelTrainBody
    export type CancelTrainMutationError = unknown

    export const useCancelTrain = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCancelTrainHook>>>, TError,{trainId: number;data: CancelTrainBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      const cancelTrain =  useCancelTrainHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCancelTrainHook>>>, {trainId: number;data: CancelTrainBody}> = (props) => {
          const {trainId,data} = props ?? {};

          return  cancelTrain(trainId,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof cancelTrain>>, TError, {trainId: number;data: CancelTrainBody}, TContext>(mutationFn, mutationOptions);
    }
    /**
 * Reinstate this train
 * @summary Reinstate Train
 */
export const useReinstateTrainHook = () => {
        const reinstateTrain = useCustomAxios<void>();

        return (
    trainId: number,
    reinstateTrainBody: ReinstateTrainBody,
 ) => {
        return reinstateTrain(
          {url: `/train/${trainId}/reinstate`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: reinstateTrainBody
    },
          );
        }
      }
    


    export type ReinstateTrainMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReinstateTrainHook>>>>
    export type ReinstateTrainMutationBody = ReinstateTrainBody
    export type ReinstateTrainMutationError = unknown

    export const useReinstateTrain = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReinstateTrainHook>>>, TError,{trainId: number;data: ReinstateTrainBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      const reinstateTrain =  useReinstateTrainHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useReinstateTrainHook>>>, {trainId: number;data: ReinstateTrainBody}> = (props) => {
          const {trainId,data} = props ?? {};

          return  reinstateTrain(trainId,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof reinstateTrain>>, TError, {trainId: number;data: ReinstateTrainBody}, TContext>(mutationFn, mutationOptions);
    }
    /**
 * Cancel all visits from (and including) the specified visit
 * @summary Cancel Train Visits To End Of Journey
 */
export const useCancelFromHook = () => {
        const cancelFrom = useCustomAxios<void>();

        return (
    trainId: number,
    visitIndex: number,
    cancelFromBody: CancelFromBody,
 ) => {
        return cancelFrom(
          {url: `/train/${trainId}/visits/${visitIndex}/cancelFrom`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: cancelFromBody
    },
          );
        }
      }
    


    export type CancelFromMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCancelFromHook>>>>
    export type CancelFromMutationBody = CancelFromBody
    export type CancelFromMutationError = unknown

    export const useCancelFrom = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCancelFromHook>>>, TError,{trainId: number;visitIndex: number;data: CancelFromBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      const cancelFrom =  useCancelFromHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCancelFromHook>>>, {trainId: number;visitIndex: number;data: CancelFromBody}> = (props) => {
          const {trainId,visitIndex,data} = props ?? {};

          return  cancelFrom(trainId,visitIndex,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof cancelFrom>>, TError, {trainId: number;visitIndex: number;data: CancelFromBody}, TContext>(mutationFn, mutationOptions);
    }
    /**
 * Cancel all visits up to (and including) the specified visit
 * @summary Cancel Train Visits From Start Of Journey
 */
export const useCancelToHook = () => {
        const cancelTo = useCustomAxios<void>();

        return (
    trainId: number,
    visitIndex: number,
    cancelToBody: CancelToBody,
 ) => {
        return cancelTo(
          {url: `/train/${trainId}/visits/${visitIndex}/cancelTo`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: cancelToBody
    },
          );
        }
      }
    


    export type CancelToMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCancelToHook>>>>
    export type CancelToMutationBody = CancelToBody
    export type CancelToMutationError = unknown

    export const useCancelTo = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCancelToHook>>>, TError,{trainId: number;visitIndex: number;data: CancelToBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      const cancelTo =  useCancelToHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCancelToHook>>>, {trainId: number;visitIndex: number;data: CancelToBody}> = (props) => {
          const {trainId,visitIndex,data} = props ?? {};

          return  cancelTo(trainId,visitIndex,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof cancelTo>>, TError, {trainId: number;visitIndex: number;data: CancelToBody}, TContext>(mutationFn, mutationOptions);
    }
    /**
 * Change stopping status to passing  for all visits from (and including) the specified visit
 * @summary Run Train Fast To End Of Journey
 */
export const useRunFastFromHook = () => {
        const runFastFrom = useCustomAxios<void>();

        return (
    trainId: number,
    visitIndex: number,
    runFastFromBody: RunFastFromBody,
 ) => {
        return runFastFrom(
          {url: `/train/${trainId}/visits/${visitIndex}/runFastFrom`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: runFastFromBody
    },
          );
        }
      }
    


    export type RunFastFromMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useRunFastFromHook>>>>
    export type RunFastFromMutationBody = RunFastFromBody
    export type RunFastFromMutationError = unknown

    export const useRunFastFrom = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRunFastFromHook>>>, TError,{trainId: number;visitIndex: number;data: RunFastFromBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      const runFastFrom =  useRunFastFromHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useRunFastFromHook>>>, {trainId: number;visitIndex: number;data: RunFastFromBody}> = (props) => {
          const {trainId,visitIndex,data} = props ?? {};

          return  runFastFrom(trainId,visitIndex,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof runFastFrom>>, TError, {trainId: number;visitIndex: number;data: RunFastFromBody}, TContext>(mutationFn, mutationOptions);
    }
    /**
 * Change stopping status to passing  for all visits up to (and including) the specified visit
 * @summary Run Train Fast From Start Of Journey
 */
export const useRunFastToHook = () => {
        const runFastTo = useCustomAxios<void>();

        return (
    trainId: number,
    visitIndex: number,
    runFastToBody: RunFastToBody,
 ) => {
        return runFastTo(
          {url: `/train/${trainId}/visits/${visitIndex}/runFastTo`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: runFastToBody
    },
          );
        }
      }
    


    export type RunFastToMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useRunFastToHook>>>>
    export type RunFastToMutationBody = RunFastToBody
    export type RunFastToMutationError = unknown

    export const useRunFastTo = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRunFastToHook>>>, TError,{trainId: number;visitIndex: number;data: RunFastToBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      const runFastTo =  useRunFastToHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useRunFastToHook>>>, {trainId: number;visitIndex: number;data: RunFastToBody}> = (props) => {
          const {trainId,visitIndex,data} = props ?? {};

          return  runFastTo(trainId,visitIndex,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof runFastTo>>, TError, {trainId: number;visitIndex: number;data: RunFastToBody}, TContext>(mutationFn, mutationOptions);
    }
    /**
 * Change stopping status from stop to passing (or vice versa)
 * @summary Run Train Fast From Start Of Journey
 */
export const useToggleStopHook = () => {
        const toggleStop = useCustomAxios<void>();

        return (
    trainId: number,
    visitIndex: number,
    toggleStopBody: ToggleStopBody,
 ) => {
        return toggleStop(
          {url: `/train/${trainId}/visits/${visitIndex}/toggleStop`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: toggleStopBody
    },
          );
        }
      }
    


    export type ToggleStopMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useToggleStopHook>>>>
    export type ToggleStopMutationBody = ToggleStopBody
    export type ToggleStopMutationError = unknown

    export const useToggleStop = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useToggleStopHook>>>, TError,{trainId: number;visitIndex: number;data: ToggleStopBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      const toggleStop =  useToggleStopHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useToggleStopHook>>>, {trainId: number;visitIndex: number;data: ToggleStopBody}> = (props) => {
          const {trainId,visitIndex,data} = props ?? {};

          return  toggleStop(trainId,visitIndex,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof toggleStop>>, TError, {trainId: number;visitIndex: number;data: ToggleStopBody}, TContext>(mutationFn, mutationOptions);
    }
    
import {
    TrainModification,
    TrainModificationType,
} from '../../../../services/dst-api'
import { Chip, ChipProps, Tooltip } from '@mui/material'
import React from 'react'
import { getLocationName } from '../../../../data/locationDescriptions'

export type TrainModificationChipProps = {
    modification: TrainModification
    onDelete?: (modification: TrainModification) => void
}
const TrainModificationChip = ({
    modification,
    onDelete,
}: TrainModificationChipProps) => {
    const location = getLocationName(modification.location?.tiploc)
    const descriptions: { [key in TrainModificationType]: string } = {
        PINE: 'Service has been cancelled',
        CAPE: `Service has been cancelled from ${location} to destination`,
        CALVIN: `Service has been cancelled from origin to ${location}`,
        RUN_FAST_FROM: `Service has been run fast from ${location} to destination`,
        RUN_FAST_TO: `Service has been run fast from origin to ${location}`,
        FTS: `Service has been run fast at ${location}`,
        ADD_STOP: `Service has additional stop at ${location}`,
    }

    const typeDescriptions: Record<TrainModificationType, string> = {
        PINE: 'Full Cancel',
        CAPE: 'Term. Short',
        CALVIN: 'Start Short',
        RUN_FAST_FROM: 'Run Fast From',
        RUN_FAST_TO: 'Run Fast To',
        FTS: 'FTS',
        ADD_STOP: 'Add. Call',
    }

    const colours: Record<TrainModificationType, ChipProps['color']> = {
        PINE: 'error',
        CAPE: 'error',
        CALVIN: 'error',
        RUN_FAST_FROM: 'warning',
        RUN_FAST_TO: 'warning',
        FTS: 'warning',
        ADD_STOP: 'success',
    }

    const tooltipTitle = descriptions[modification.type]
    const colour = colours[modification.type]

    return (
        <Tooltip title={tooltipTitle}>
            <Chip
                label={typeDescriptions[modification.type]}
                color={colour}
                onDelete={onDelete ? () => onDelete(modification) : undefined}
            />
        </Tooltip>
    )
}

export default TrainModificationChip

import React, { useState } from 'react'
import { useMediaQuery } from '@mui/material'
import useTheme from '@mui/material/styles/useTheme'
import { Header } from '../components/Header'
import { Sidebar } from './components/Sidebar/Sidebar'
import { Footer } from '../components/Footer'
import { LayoutContent } from '../components/LayoutContent'
import { styled } from '@mui/material/styles'
import { Outlet } from 'react-router-dom'
import withAuthRequired from '../../components/ProtectedRoute/withAuthRequired'

type NavigationLayoutContainerProps = {
    desktop: boolean
}
const NavigationLayoutContainer = styled('div')<NavigationLayoutContainerProps>(
    ({ desktop, theme }) => ({
        paddingTop: 56,
        height: '100%',
        [theme.breakpoints.up('sm')]: {
            paddingTop: 64,
        },
        paddingLeft: desktop ? 240 : 0,
    })
)

type NavigationLayoutProps = {
    type:
        | 'view_simulation'
        | 'edit_simulation'
        | 'live_edit_simulation'
        | 'admin' //TODO enum? also used in Sidebar
        | 'docs'
}

const NavigationLayout = ({ type }: NavigationLayoutProps) => {
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true,
    })

    const [openSidebar, setOpenSidebar] = useState(false)

    const handleSidebarOpen = () => {
        setOpenSidebar(true)
    }

    const handleSidebarClose = () => {
        setOpenSidebar(false)
    }

    const shouldOpenSidebar = isDesktop ? true : openSidebar

    return (
        <NavigationLayoutContainer desktop={isDesktop}>
            <Header
                onSidebarOpen={handleSidebarOpen}
                navigationEnabled={true}
            />
            <Sidebar
                onClose={handleSidebarClose}
                open={shouldOpenSidebar}
                variant={isDesktop ? 'persistent' : 'temporary'}
                type={type}
            />
            <LayoutContent>
                <Outlet />
                <Footer />
            </LayoutContent>
        </NavigationLayoutContainer>
    )
}

export default withAuthRequired(NavigationLayout)

import { Simulation } from '../../../../services/dst-api'
import { Stack, Typography } from '@mui/material'
import SimulationOverviewGrid from '../../../SimulationResults/components/components/SimulationOverviewGrid/SimulationOverviewGrid'

function SimulationQuickInfoTooltip({
    simulation,
}: {
    simulation: Simulation
}) {
    return (
        <Stack rowGap={0}>
            <SimulationOverviewGrid
                simulation={simulation}
                title={'Simulation Details'}
            />
            {simulation.description && (
                <Stack gap={2} mt={1}>
                    <hr />
                    <Typography>{simulation.description}</Typography>
                </Stack>
            )}
        </Stack>
    )
}

export default SimulationQuickInfoTooltip

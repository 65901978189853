import React from 'react'
import { styled } from '@mui/material/styles'
import SimulationStatistics from '../SimulationStatistics/SimulationStatistics'
import LiveSettingsForm from '../LiveSettingsForm/LiveSettingsForm'

const LiveHomeContainer = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    gap: theme.spacing(2),
}))

export const LiveHomeView = () => {
    const liveSimulationId = 1

    return (
        <LiveHomeContainer>
            <SimulationStatistics simulationId={liveSimulationId} />
            <LiveSettingsForm />
        </LiveHomeContainer>
    )
}

import { PaletteColor, styled } from '@mui/material/styles'
import { Paper, Typography } from '@mui/material'
import React, { ReactNode } from 'react'

const BaseInformationBoxContainer = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    width: '50%',
    margin: 'auto auto',
    padding: theme.spacing(6),
    marginTop: theme.spacing(6),
}))

export interface BaseInformationBoxProps {
    message?: string
    children?: ReactNode
    title?: string
    titleColor?: PaletteColor
    subtitle?: string
}

const BaseInformationBox = ({
    message,
    title,
    titleColor,
    subtitle,
    children,
}: BaseInformationBoxProps) => {
    return (
        <BaseInformationBoxContainer>
            {title && (
                <Typography
                    variant={'h4'}
                    sx={{ color: () => titleColor?.dark }}
                >
                    {title}
                </Typography>
            )}
            {subtitle && <Typography variant={'h5'}>{subtitle}</Typography>}
            {message && <Typography>{message}</Typography>}
            {children}
        </BaseInformationBoxContainer>
    )
}

export default BaseInformationBox

import React from 'react'
import ManageIncidentForm from './ManageIncidentForm'
import {
    pushErrorNotification,
    pushSuccessNotification,
} from '../../../../../utils/notification'
import { useCreateIncident } from '../../../../../services/dst-api'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'

type CreateIncidentFormProps = {
    simulationId: number
}

const CreateIncidentForm = ({ simulationId }: CreateIncidentFormProps) => {
    const navigate = useNavigate()

    const createIncidentMutation = useCreateIncident({
        mutation: {
            onSuccess: () => {
                pushSuccessNotification('Incident created successfully.')
                navigate(`../incidents`)
            },
            onError: () => {
                pushErrorNotification(`Error creating incident.`)
            },
        },
    })

    return (
        <ManageIncidentForm
            initialValues={{
                incidentId: '',
                fromLocation: '',
                toLocation: '',
                line: '',
                type: '',
                startTime: dayjs().startOf('day').format('HH:mm'),
                endTime: dayjs()
                    .endOf('day')
                    .subtract(1, 'minute')
                    .format('HH:mm'),
                description: '',
                title: '',
            }}
            onSubmit={(formData) => {
                createIncidentMutation.mutate({
                    data: {
                        ...formData,
                        simulationId: simulationId,
                    },
                })
            }}
            isSaving={createIncidentMutation.isLoading}
        />
    )
}

export default CreateIncidentForm

import React from 'react'

import {
    AddSimulationIncident,
    CreateSimulationBasic,
    EditSimulationBasic,
    EditSimulationCrew as EditSimulationCrewView,
    EditSimulationIncident,
    EditSimulationIncidents as EditSimulationIncidentsView,
    EditSimulationServices as EditSimulationServicesView,
    EditSimulationStock as EditSimulationStockView,
    EditSimulationTimetable as EditSimulationTimetableView,
    EditSimulationTimetableTrainView,
    SimulationIterations as SimulationIterationsView,
    Simulations as SimulationsView,
    UnimplementedPage as SimulationFinancialPenaltiesView,
    UnimplementedPage as SimulationDelayAndPunctualityView,
    UnimplementedPage as AdministerIncidentTypesView,
    UnimplementedPage as AdminsterUsersView,
} from '../views'
import { MinimalLayout } from '../layouts/Minimal/MinimalLayout'
import { Navigate, Route, Routes } from 'react-router-dom'
import { RecommendActionsView } from '../features/LiveMode/components/RecommendActionsView/RecommendActionsView'
import { LiveHomeView } from '../features/LiveMode/components/LiveHomeView/LiveHomeView'
import { ModeSelectionView } from '../features/Simulation/components/ModeSelectionView/ModeSelectionView'
import AdministerLocations from '../features_experimental/admin/components/AdministerLocations/AdministerLocations'
import AdministerIntegrations from '../features_experimental/admin/components/AdministerIntegrations/AdministerIntegrations'
import PreviousRecommendationsView from '../features/LiveMode/components/PreviousRecommendationsView/PreviousRecommendationsView'
import SimulationResultsPage from '../features/SimulationResults/components/SimulationResultsPage'
import NavigationLayout from '../layouts/Navigation/NavigationLayout'
import { useAuth0 } from '@auth0/auth0-react'
import * as FullStory from '@fullstory/browser'
import { fullStoryEnabled } from '../index'
import SimulationIncidentsPage from '../features/Incidents/components/ReadonlySimulationIncidentsPage/ReadonlySimulationIncidentsPage'
import UploadSimulations from '../features_experimental/UploadSimulations/UploadSimulations'
import SimulationResultsAnalysisPage from '../features/SimulationResults/components/SimulationResultsAnalysis/SimulationResultsAnalysisPage'
import SimulationResultsTrainGraphPage from '../features/ReplayMode/components/SimulationResultsTrainGraph/SimulationResultsTrainGraphPage'
import BackendDocumentation from '../features/ApiDocumentation/components/BackendDocumentation'
import ReadonlySimulationTrains from '../features/ServiceChanges/components/ReadonlySimulationTrains/ReadonlySimulationTrains'
import { SimulationTrainGraphSettingsProvider } from '../features/TrainGraph/context/SimulationTrainGraphSettingsProvider'
import { SimulationProvider } from '../features/Simulation/context/SimulationProvider'
import { GlobalTrainGraphSettingsProvider } from '../features/TrainGraph/context/TrainGraphSettingsProvider'

export const PrivateRoutes = () => {
    const { user } = useAuth0()

    if (fullStoryEnabled && user !== undefined) {
        FullStory.identify(user.email!!, {
            email: user.email,
            // Add your own custom user variables here, details at
            // https://help.fullstory.com/hc/en-us/articles/360020623294-FS-setUserVars-API-Capturing-custom-user-properties
            // reviewsWritten_int: 14,
        })
    }

    return (
        <Routes>
            {/* Docs */}
            <Route path="/docs" element={<NavigationLayout type="docs" />}>
                <Route path="" element={<Navigate to={'./backend'} />} />
                <Route path="backend" element={<BackendDocumentation />} />
            </Route>

            {/* Admin */}
            <Route path="/admin" element={<NavigationLayout type="admin" />}>
                <Route path="incident">
                    <Route
                        path="types"
                        element={<AdministerIncidentTypesView />}
                    />
                </Route>
                <Route path="users" element={<AdminsterUsersView />} />
                <Route path="locations" element={<AdministerLocations />} />
                <Route
                    path="integrations"
                    element={<AdministerIntegrations />}
                />
            </Route>

            {/* Live Mode */}
            <Route
                path="/live"
                element={<NavigationLayout type="live_edit_simulation" />}
            >
                <Route path="edit">
                    <Route
                        path="timetable"
                        element={<EditSimulationTimetableView />}
                    />
                    <Route
                        path="incidents"
                        element={<EditSimulationIncidentsView />}
                    />
                    <Route
                        path="services"
                        element={<EditSimulationServicesView />}
                    />
                    <Route path="crew" element={<EditSimulationCrewView />} />
                    <Route path="stock" element={<EditSimulationStockView />} />
                    <Route
                        path="incident/new"
                        element={<AddSimulationIncident />}
                    />
                    <Route
                        path="incident/:incidentId"
                        element={<EditSimulationIncident />}
                    />
                    <Route
                        path="timetable/:trainId"
                        element={<EditSimulationTimetableTrainView />}
                    />
                </Route>
                <Route path="overview" element={<LiveHomeView />} />
                <Route
                    path="recommend-actions"
                    element={<RecommendActionsView />}
                />
                <Route
                    path="previous-recommendations"
                    element={<PreviousRecommendationsView />}
                />
            </Route>

            {/* Replay Mode */}
            <Route path="/replay/simulation/">
                <Route path=":simulationId">
                    {/* Edit Simulation */}
                    <Route
                        path="edit"
                        element={<NavigationLayout type="edit_simulation" />}
                    >
                        <Route path="basic" element={<EditSimulationBasic />} />
                        <Route
                            path="timetable"
                            element={<EditSimulationTimetableView />}
                        />
                        <Route
                            path="incidents"
                            element={<EditSimulationIncidentsView />}
                        />
                        <Route
                            path="services"
                            element={<EditSimulationServicesView />}
                        />
                        <Route
                            path="crew"
                            element={<EditSimulationCrewView />}
                        />
                        <Route
                            path="stock"
                            element={<EditSimulationStockView />}
                        />
                        <Route
                            path="incident/new"
                            element={<AddSimulationIncident />}
                        />
                        <Route
                            path="incident/:incidentId"
                            element={<EditSimulationIncident />}
                        />
                        <Route
                            path="timetable/:trainId"
                            element={<EditSimulationTimetableTrainView />}
                        />
                    </Route>
                </Route>

                {/* Create Simulation */}
                <Route
                    path="new"
                    element={
                        <MinimalLayout>
                            <CreateSimulationBasic />
                        </MinimalLayout>
                    }
                />
            </Route>

            {/* View Simulation */}
            <Route
                path="/simulation/:simulationId"
                element={
                    <SimulationProvider>
                        <GlobalTrainGraphSettingsProvider>
                            <SimulationTrainGraphSettingsProvider>
                                <NavigationLayout type="view_simulation" />
                            </SimulationTrainGraphSettingsProvider>
                        </GlobalTrainGraphSettingsProvider>
                    </SimulationProvider>
                }
            >
                <Route path="overview" element={<SimulationResultsPage />} />
                <Route
                    path="analysis"
                    element={<SimulationResultsAnalysisPage />}
                />
                <Route
                    path="trainGraph"
                    element={<SimulationResultsTrainGraphPage />}
                />
                <Route
                    path="iterations"
                    element={<SimulationIterationsView />}
                />
                <Route path="incidents" element={<SimulationIncidentsPage />} />
                <Route
                    path="timetableChanges"
                    element={<ReadonlySimulationTrains />}
                />
                <Route
                    path="financialPenalties"
                    element={<SimulationFinancialPenaltiesView />}
                />
                <Route
                    path="delayAndPunctuality"
                    element={<SimulationDelayAndPunctualityView />}
                />
            </Route>

            {/* Login */}
            <Route
                path="/"
                element={<Navigate to="/mode-selection" replace />}
            />
            {/*<Route*/}
            {/*    path="/login"*/}
            {/*    element={*/}
            {/*        <MinimalLayout>*/}
            {/*            <LoginView />*/}
            {/*        </MinimalLayout>*/}
            {/*    }*/}
            {/*/>*/}

            {/* Simulation Mode Selection */}
            <Route
                path="/mode-selection"
                element={
                    <MinimalLayout>
                        <ModeSelectionView />
                    </MinimalLayout>
                }
            />

            {/* All Simulations */}
            <Route
                path="/replay"
                element={
                    <MinimalLayout>
                        <SimulationsView />
                    </MinimalLayout>
                }
            />
            <Route
                path="/replay/import"
                element={
                    <MinimalLayout>
                        <UploadSimulations />
                    </MinimalLayout>
                }
            />

            {/* 404 */}
            {/*<Route*/}
            {/*    path="/not-found"*/}
            {/*    element={*/}
            {/*        <MinimalLayout>*/}
            {/*            <NotFoundView />*/}
            {/*        </MinimalLayout>*/}
            {/*    }*/}
            {/*/>*/}

            {/*<Route path="*" element={<Navigate to="/not-found" replace />} />*/}
        </Routes>
    )
}

import { Simulation } from '../../../../../../services/dst-api'
import React, { JSX } from 'react'
import SimulationsGrid from './components/SimulationsGrid'
import SimulationsList from './components/SimulationsList'
import { SimulationsDisplayMode } from '../../../../../../types/SimulationsDisplayMode'

export type BaseSimulationsDisplayProps = {
    simulations: Simulation[]
    onSimulationDelete: (simulationId: number) => void
    onSimulationExecute: (simulationId: number) => void
    onSimulationCancel: (simulationId: number) => void
    onSimulationResume: (simulationId: number) => void
    onSimulationRerun: (simulationId: number) => void
}

type SimulationsDisplayProps = {
    mode: SimulationsDisplayMode
} & BaseSimulationsDisplayProps

function SimulationsDisplay({ mode, ...rest }: SimulationsDisplayProps) {
    const byDisplayType: Record<
        SimulationsDisplayMode,
        (props: BaseSimulationsDisplayProps) => JSX.Element
    > = {
        [SimulationsDisplayMode.GRID]: (props) => {
            return <SimulationsGrid {...props} />
        },
        [SimulationsDisplayMode.LIST]: (props) => {
            return <SimulationsList {...props} />
        },
    }

    return byDisplayType[mode](rest)
}

export default SimulationsDisplay

/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Decision Support Tool API
 * Decision Support Tool Application
 * OpenAPI spec version: v0.0.1
 */
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  Simulation,
  GetSimulations400Item,
  GetSimulation400Item,
  GetSimulationParams,
  CreateSimulation400Item,
  CreateSimulationRequest,
  DeleteSimulation400Item,
  DeleteSimulationParams,
  EditSimulation400Item,
  EditSimulationRequest,
  SimulationResults,
  GetSimulationResults400Item,
  SimulatedJourney,
  BadRequestResponseResponse,
  EmptyRequestBodyBody,
  Train
} from '.././model'
import { useCustomAxios } from '../../../utils/use-custom-axios';


/**
 * This method retrieves all simulations
 * @summary Get all simulations
 */
export const useGetSimulationsHook = () => {
        const getSimulations = useCustomAxios<Simulation[]>();

        return (
    
 signal?: AbortSignal
) => {
        return getSimulations(
          {url: `/simulations`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetSimulationsQueryKey = () => [`/simulations`];

    
export type GetSimulationsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetSimulationsHook>>>>
export type GetSimulationsQueryError = GetSimulations400Item[]

export const useGetSimulations = <TData = Awaited<ReturnType<ReturnType<typeof useGetSimulationsHook>>>, TError = GetSimulations400Item[]>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSimulationsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSimulationsQueryKey();

  const getSimulations =  useGetSimulationsHook();


  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetSimulationsHook>>>> = ({ signal }) => getSimulations(signal);


  

  const query = useQuery<Awaited<ReturnType<ReturnType<typeof useGetSimulationsHook>>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * This method retrieves a specified simulation by id
 * @summary Get simulation
 */
export const useGetSimulationHook = () => {
        const getSimulation = useCustomAxios<Simulation>();

        return (
    params: GetSimulationParams,
 signal?: AbortSignal
) => {
        return getSimulation(
          {url: `/simulation`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetSimulationQueryKey = (params: GetSimulationParams,) => [`/simulation`, ...(params ? [params]: [])];

    
export type GetSimulationQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetSimulationHook>>>>
export type GetSimulationQueryError = GetSimulation400Item[]

export const useGetSimulation = <TData = Awaited<ReturnType<ReturnType<typeof useGetSimulationHook>>>, TError = GetSimulation400Item[]>(
 params: GetSimulationParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSimulationHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSimulationQueryKey(params);

  const getSimulation =  useGetSimulationHook();


  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetSimulationHook>>>> = ({ signal }) => getSimulation(params, signal);


  

  const query = useQuery<Awaited<ReturnType<ReturnType<typeof useGetSimulationHook>>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * Create a new simulation
 * @summary Create simulation
 */
export const useCreateSimulationHook = () => {
        const createSimulation = useCustomAxios<Simulation>();

        return (
    createSimulationRequest: CreateSimulationRequest,
 ) => {
        return createSimulation(
          {url: `/simulation`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createSimulationRequest
    },
          );
        }
      }
    


    export type CreateSimulationMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateSimulationHook>>>>
    export type CreateSimulationMutationBody = CreateSimulationRequest
    export type CreateSimulationMutationError = CreateSimulation400Item[]

    export const useCreateSimulation = <TError = CreateSimulation400Item[],
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateSimulationHook>>>, TError,{data: CreateSimulationRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      const createSimulation =  useCreateSimulationHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateSimulationHook>>>, {data: CreateSimulationRequest}> = (props) => {
          const {data} = props ?? {};

          return  createSimulation(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof createSimulation>>, TError, {data: CreateSimulationRequest}, TContext>(mutationFn, mutationOptions);
    }
    /**
 * Delete an existing simulation
 * @summary Delete a simulation
 */
export const useDeleteSimulationHook = () => {
        const deleteSimulation = useCustomAxios<void>();

        return (
    params: DeleteSimulationParams,
 ) => {
        return deleteSimulation(
          {url: `/simulation`, method: 'delete',
        params
    },
          );
        }
      }
    


    export type DeleteSimulationMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteSimulationHook>>>>
    
    export type DeleteSimulationMutationError = DeleteSimulation400Item[]

    export const useDeleteSimulation = <TError = DeleteSimulation400Item[],
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteSimulationHook>>>, TError,{params: DeleteSimulationParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      const deleteSimulation =  useDeleteSimulationHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteSimulationHook>>>, {params: DeleteSimulationParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteSimulation(params,)
        }

        

      return useMutation<Awaited<ReturnType<typeof deleteSimulation>>, TError, {params: DeleteSimulationParams}, TContext>(mutationFn, mutationOptions);
    }
    /**
 * Edit an existing simulation
 * @summary Edit simulation
 */
export const useEditSimulationHook = () => {
        const editSimulation = useCustomAxios<Simulation>();

        return (
    editSimulationRequest: EditSimulationRequest,
 ) => {
        return editSimulation(
          {url: `/simulation`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: editSimulationRequest
    },
          );
        }
      }
    


    export type EditSimulationMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useEditSimulationHook>>>>
    export type EditSimulationMutationBody = EditSimulationRequest
    export type EditSimulationMutationError = EditSimulation400Item[]

    export const useEditSimulation = <TError = EditSimulation400Item[],
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useEditSimulationHook>>>, TError,{data: EditSimulationRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      const editSimulation =  useEditSimulationHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useEditSimulationHook>>>, {data: EditSimulationRequest}> = (props) => {
          const {data} = props ?? {};

          return  editSimulation(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof editSimulation>>, TError, {data: EditSimulationRequest}, TContext>(mutationFn, mutationOptions);
    }
    /**
 * Retrieve the results of the simulation.
 * @summary Your GET endpoint
 */
export const useGetSimulationResultsHook = () => {
        const getSimulationResults = useCustomAxios<SimulationResults>();

        return (
    simulationId: number,
 signal?: AbortSignal
) => {
        return getSimulationResults(
          {url: `/simulation/${simulationId}/results`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetSimulationResultsQueryKey = (simulationId: number,) => [`/simulation/${simulationId}/results`];

    
export type GetSimulationResultsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetSimulationResultsHook>>>>
export type GetSimulationResultsQueryError = GetSimulationResults400Item[]

export const useGetSimulationResults = <TData = Awaited<ReturnType<ReturnType<typeof useGetSimulationResultsHook>>>, TError = GetSimulationResults400Item[]>(
 simulationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSimulationResultsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSimulationResultsQueryKey(simulationId);

  const getSimulationResults =  useGetSimulationResultsHook();


  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetSimulationResultsHook>>>> = ({ signal }) => getSimulationResults(simulationId, signal);


  

  const query = useQuery<Awaited<ReturnType<ReturnType<typeof useGetSimulationResultsHook>>>, TError, TData>(queryKey, queryFn, {enabled: !!(simulationId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Your GET endpoint
 */
export const useGetSimulatedJourneysHook = () => {
        const getSimulatedJourneys = useCustomAxios<SimulatedJourney[]>();

        return (
    simulationId: number,
 signal?: AbortSignal
) => {
        return getSimulatedJourneys(
          {url: `/simulation/${simulationId}/results/journeys`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetSimulatedJourneysQueryKey = (simulationId: number,) => [`/simulation/${simulationId}/results/journeys`];

    
export type GetSimulatedJourneysQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetSimulatedJourneysHook>>>>
export type GetSimulatedJourneysQueryError = unknown

export const useGetSimulatedJourneys = <TData = Awaited<ReturnType<ReturnType<typeof useGetSimulatedJourneysHook>>>, TError = unknown>(
 simulationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSimulatedJourneysHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSimulatedJourneysQueryKey(simulationId);

  const getSimulatedJourneys =  useGetSimulatedJourneysHook();


  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetSimulatedJourneysHook>>>> = ({ signal }) => getSimulatedJourneys(simulationId, signal);


  

  const query = useQuery<Awaited<ReturnType<ReturnType<typeof useGetSimulatedJourneysHook>>>, TError, TData>(queryKey, queryFn, {enabled: !!(simulationId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * This method executes a specified simulation by id
 */
export const useExecuteSimulationHook = () => {
        const executeSimulation = useCustomAxios<Simulation>();

        return (
    simulationId: number,
    emptyRequestBodyBody: EmptyRequestBodyBody,
 ) => {
        return executeSimulation(
          {url: `/simulation/${simulationId}/execute`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: emptyRequestBodyBody
    },
          );
        }
      }
    


    export type ExecuteSimulationMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useExecuteSimulationHook>>>>
    export type ExecuteSimulationMutationBody = EmptyRequestBodyBody
    export type ExecuteSimulationMutationError = BadRequestResponseResponse

    export const useExecuteSimulation = <TError = BadRequestResponseResponse,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useExecuteSimulationHook>>>, TError,{simulationId: number;data: EmptyRequestBodyBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      const executeSimulation =  useExecuteSimulationHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useExecuteSimulationHook>>>, {simulationId: number;data: EmptyRequestBodyBody}> = (props) => {
          const {simulationId,data} = props ?? {};

          return  executeSimulation(simulationId,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof executeSimulation>>, TError, {simulationId: number;data: EmptyRequestBodyBody}, TContext>(mutationFn, mutationOptions);
    }
    /**
 * This method cancels a specified queued simulation by id
 */
export const useCancelSimulationHook = () => {
        const cancelSimulation = useCustomAxios<Simulation>();

        return (
    simulationId: number,
    emptyRequestBodyBody: EmptyRequestBodyBody,
 ) => {
        return cancelSimulation(
          {url: `/simulation/${simulationId}/cancel`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: emptyRequestBodyBody
    },
          );
        }
      }
    


    export type CancelSimulationMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCancelSimulationHook>>>>
    export type CancelSimulationMutationBody = EmptyRequestBodyBody
    export type CancelSimulationMutationError = BadRequestResponseResponse

    export const useCancelSimulation = <TError = BadRequestResponseResponse,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCancelSimulationHook>>>, TError,{simulationId: number;data: EmptyRequestBodyBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      const cancelSimulation =  useCancelSimulationHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCancelSimulationHook>>>, {simulationId: number;data: EmptyRequestBodyBody}> = (props) => {
          const {simulationId,data} = props ?? {};

          return  cancelSimulation(simulationId,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof cancelSimulation>>, TError, {simulationId: number;data: EmptyRequestBodyBody}, TContext>(mutationFn, mutationOptions);
    }
    /**
 * This method resumes a specified simulation by id
 */
export const useResumeSimulationHook = () => {
        const resumeSimulation = useCustomAxios<Simulation>();

        return (
    simulationId: number,
    emptyRequestBodyBody: EmptyRequestBodyBody,
 ) => {
        return resumeSimulation(
          {url: `/simulation/${simulationId}/resume`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: emptyRequestBodyBody
    },
          );
        }
      }
    


    export type ResumeSimulationMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useResumeSimulationHook>>>>
    export type ResumeSimulationMutationBody = EmptyRequestBodyBody
    export type ResumeSimulationMutationError = BadRequestResponseResponse

    export const useResumeSimulation = <TError = BadRequestResponseResponse,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useResumeSimulationHook>>>, TError,{simulationId: number;data: EmptyRequestBodyBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      const resumeSimulation =  useResumeSimulationHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useResumeSimulationHook>>>, {simulationId: number;data: EmptyRequestBodyBody}> = (props) => {
          const {simulationId,data} = props ?? {};

          return  resumeSimulation(simulationId,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof resumeSimulation>>, TError, {simulationId: number;data: EmptyRequestBodyBody}, TContext>(mutationFn, mutationOptions);
    }
    /**
 * Retrieve the trains for a simulation
 * @summary Your GET endpoint
 */
export const useGetSimulationTrainsHook = () => {
        const getSimulationTrains = useCustomAxios<Train[]>();

        return (
    simulationId: number,
 signal?: AbortSignal
) => {
        return getSimulationTrains(
          {url: `/simulation/${simulationId}/trains`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetSimulationTrainsQueryKey = (simulationId: number,) => [`/simulation/${simulationId}/trains`];

    
export type GetSimulationTrainsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetSimulationTrainsHook>>>>
export type GetSimulationTrainsQueryError = unknown

export const useGetSimulationTrains = <TData = Awaited<ReturnType<ReturnType<typeof useGetSimulationTrainsHook>>>, TError = unknown>(
 simulationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSimulationTrainsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSimulationTrainsQueryKey(simulationId);

  const getSimulationTrains =  useGetSimulationTrainsHook();


  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetSimulationTrainsHook>>>> = ({ signal }) => getSimulationTrains(simulationId, signal);


  

  const query = useQuery<Awaited<ReturnType<ReturnType<typeof useGetSimulationTrainsHook>>>, TError, TData>(queryKey, queryFn, {enabled: !!(simulationId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import * as FullStory from '@fullstory/browser'

export const fullStoryEnabled =
    window.location.hostname === 'prod.decision-support-tool.co.uk'

if (fullStoryEnabled) FullStory.init({ orgId: 'o-1N5Z31-na1' })

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

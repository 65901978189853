import React from 'react'
import { Button, Link } from '@mui/material'
import { styled } from '@mui/material/styles'

const AcceptAmendmentsContainer = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(3),
    '& > *': {
        paddingRight: theme.spacing(1),
    },
}))

const AcceptAmendments = () => {
    return (
        <AcceptAmendmentsContainer>
            <Link color="primary" href="#" onClick={(e) => e.preventDefault()}>
                <Button size="small" color="primary" variant={'contained'}>
                    Accept Amendments
                </Button>
            </Link>
        </AcceptAmendmentsContainer>
    )
}

export default AcceptAmendments

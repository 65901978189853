import React from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { Location } from '../../../../services/dst-api'

type LocationsListProps = {
    locations: Location[]
}

const LocationsList = ({ locations }: LocationsListProps) => {
    if (locations.length === 0) return <p>No locations are configured.</p>

    return (
        <React.Fragment>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Tiploc</TableCell>
                        <TableCell>Friendly Name</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {locations.map((row) => {
                        return (
                            <TableRow key={row.id}>
                                <TableCell>{row.id}</TableCell>
                                <TableCell>{row.tiploc}</TableCell>
                                <TableCell>{row.name}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </React.Fragment>
    )
}

export default LocationsList

import React from 'react'
import { Grid, Paper } from '@mui/material'
import LoadingBox from '../../../../components/InformationBox/LoadingBox'
import ErrorBox from '../../../../components/InformationBox/ErrorBox'
import { useGetIncidents } from '../../../../services/dst-api'
import { IncidentsList } from '../IncidentsList/IncidentsList'
import { styled } from '@mui/material/styles'
import InformationBox from '../../../../components/InformationBox/InformationBox'

const ResultSection = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
}))

type SimulationIncidentsViewProps = {
    simulationId: number
}

const SimulationIncidentsView = ({
    simulationId,
}: SimulationIncidentsViewProps) => {
    const {
        isLoading,
        isError,
        data: incidents,
    } = useGetIncidents({ simulationId: simulationId })

    if (isLoading) {
        return <LoadingBox />
    }

    if (isError) {
        return <ErrorBox message={'Incidents could not be retrieved.'} />
    }

    if (incidents === undefined) {
        return <ErrorBox message={'Incidents could not be found.'} />
    }

    if (incidents.length === 0) {
        return (
            <InformationBox
                title={'No Incidents'}
                message={'No incidents have been created for this simulation.'}
            />
        )
    }

    return (
        <Grid container spacing={3}>
            {/* Outcome Measures */}
            <Grid item xs={12}>
                <ResultSection>
                    <IncidentsList incidents={incidents} actions={[]} />
                </ResultSection>
            </Grid>
        </Grid>
    )
}

export default SimulationIncidentsView
